import { firebase_app } from "../../components/config/firebase";
import moment from 'moment';

const db = firebase_app.firestore();


class LeaveService {

    async getLeave(uid) {
        if (!uid) return null;
        const snapshot = await db.collection("leave").where("createdBy", "==", uid).get()
        return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
    }

    async getLeaveByStatus(uid, status) {
        if (!uid) return null;
        const snapshot = await db.collection("leave")
            .where('createdBy', '==', uid)
            .where('status', '==', status)
            .get()
        return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
    }

    async getLeaveType() {
        const snapshot = await db.collection("leave_type").get()
        return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
    }

    async getAcceptedLeaveEmpl(employeeID, startDate, endDate) {
        const snapshot = await db.collection("leave")
            .where('author', '==', employeeID)
            .where('status', '==', 'accepted')
            .where('startDate', '<=', endDate)
            .get()
        const result_query = snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
        const results = result_query.filter(result => result.data.endDate.toDate() >= startDate);
        return results;
    }

    async getAcceptedLeave(uid, startDate, endDate) {
        const snapshot = await db.collection("leave")
            .where('createdBy', '==', uid)
            .where('status', '==', 'accepted')
            .where('startDate', '<=', endDate)
            .get()
        const result_query = snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
        const results = result_query.filter(result => result.data.endDate.toDate() >= startDate);
        return results;
    }

    create(leave) {
        return db.collection("leave").add(leave);
    }

    update(id, value) {
        return db.collection("leave").doc(id).update(value);
    }

    delete(id) {
        return db.collection("leave").doc(id).delete();
    }

    sortLeaves(leaves) {
        return leaves.sort(function (a, b) {
            return a.data.date.toDate().getTime() - b.data.date.toDate().getTime()
        });
    }

    calcBusinessDays(startDate, endDate, dayWork) {
        const day = moment(startDate);
        let businessDays = 0;
        while (day.isSameOrBefore(endDate, 'day')) {
            if (dayWork == 6) {
                if (day.day() !== 6) {
                    businessDays++;
                }
            }
            else if (dayWork == 5) {
                if (day.day() !== 0 && day.day() !== 6) {
                    businessDays++;
                }
            }
            else businessDays++;
            day.add(1, 'd');
        }
        return businessDays;
    }

    async getLeaveNum(id) {
        const startYear = new Date(new Date().getFullYear(), 0, 1);
        const endYear = new Date(new Date().getFullYear(), 12, 31);
        const leaves = await this.getAcceptedLeaveEmpl(id, startYear, endYear);
        let num = 0;
        for (let leave of leaves) {
            num += leave.data.duration;
        }
        return num;
    }

}

export default new LeaveService();