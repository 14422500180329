import { firebase_app } from "../../components/config/firebase";

import moment from 'moment';
import "moment/locale/fr";

const db = firebase_app.firestore();

class PlanningService {

    async getPlanning(uid) {
        if (!uid) return null;
        const snapshot = await db.collection("planning").where('createdBy', '==', uid).get()
        return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
    }

    async getPlanningByPeriod(uid, start, end) {
        if (!uid) return null;
        const snapshot = await db.collection("planning")
            .where('createdBy', '==', uid)
            .where('planningDate', '>=', start)
            .where('planningDate', '<=', end)
            .get()
        return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
    }

    async getPlanningByWeek(uid, week) {
        return this.getPlanningByPeriod(uid, week[0], week[6]);
    }

    async getPlanningDayByEmployee(uid, day, employeeID) {
        if (!uid) return null;
        const snapshot = await db.collection("planning")
            .where('createdBy', '==', uid)
            .where('planningDate', '>=', day)
            .where('planningDate', '<', moment(day).add(1, 'days').toDate())
            .where('employeeID', '==', employeeID)
            .get()
        return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
    }

    async getpPanningByID(id) {
        const snapshot = await db.collection("planning").doc(id).get()
        return snapshot.data();
    }

    create(planning) {
        return db.collection('planning').add(planning)
    }

    update(id, value) {
        return db.collection('planning').doc(id).update(value);
    }

    delete(id) {
        return db.collection('planning').doc(id).delete();
    }

    async deleteList(ids) {
        const batch = db.batch();
        ids.map((id) => {
            const docRef = db.collection("planning").doc(id);
            batch.delete(docRef);
        })
        return batch.commit();
    }


    getCurrentWeek() {
        var startOfWeek = moment().startOf('isoWeek');
        var endOfWeek = moment().endOf('isoWeek');

        var days = [];
        var day = startOfWeek;

        while (day <= endOfWeek) {
            days.push(day.toDate());
            day = day.clone().add(1, 'd');
        }

        return days;
    }

    getLastWeek(weekSelected) {
        var weeks = [];
        if (weekSelected) {
            for (let i = 1; i < 8; i++) {
                var startOfWeek = moment(weekSelected[1]).subtract(i, 'weeks').startOf('isoWeek');
                var endOfWeek = moment(weekSelected[1]).subtract(i, 'weeks').endOf('isoWeek');

                var days = [];
                var day = startOfWeek;

                while (day <= endOfWeek) {
                    days.push(day.toDate());
                    day = day.clone().add(1, 'd');
                }
                weeks.push(days);
            }
        }
        return weeks;
    }

    previousWeek(weekSelected) {
        var startOfWeek = moment(weekSelected[1]).subtract(1, 'weeks').startOf('isoWeek');
        var endOfWeek = moment(weekSelected[1]).subtract(1, 'weeks').endOf('isoWeek');

        var week = [];
        var day = startOfWeek;

        while (day <= endOfWeek) {
            week.push(day.toDate());
            day = day.clone().add(1, 'd');
        }
        return week;
    }

    nextWeek(weekSelected) {
        var startOfWeek = moment(weekSelected[1]).add(1, 'weeks').startOf('isoWeek');
        var endOfWeek = moment(weekSelected[1]).add(1, 'weeks').endOf('isoWeek');

        var week = [];
        var day = startOfWeek;

        while (day <= endOfWeek) {
            week.push(day.toDate());
            day = day.clone().add(1, 'd');
        }
        return week;
    }

    shuffle(array) {
        var currentIndex = array.length, randomIndex;
        while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    sortPlanningByDate(plannings) {
        return plannings.sort(function (a, b) {
            return a.planningDate.toDate().getTime() - b.planningDate.toDate().getTime();
        });
    }


}

export default new PlanningService();