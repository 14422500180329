import { firebase_app } from "../../components/config/firebase";


const db = firebase_app.firestore();

class PointingService {

  async getAllPointingByDate(uid, startDate, endDate) {
    const snapshot = await db.collection("pointing")
      .where('managerID', '==', uid)
      .where('createdAt', '>=', startDate)
      .where('createdAt', '<=', endDate)
      .orderBy('createdAt', "desc")
      .get()
    return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } })
  }

  async getPointingByDate(uid, startDate, endDate, lastVisible, limitData) {
    const snapshot = lastVisible ?
      await db.collection("pointing")
        .where('managerID', '==', uid)
        .where('createdAt', '>=', startDate)
        .where('createdAt', '<=', endDate)
        .orderBy('createdAt', "desc")
        .startAfter(lastVisible)
        .limit(limitData)
        .get() :
      await db.collection("pointing")
        .where('managerID', '==', uid)
        .where('createdAt', '>=', startDate)
        .where('createdAt', '<=', endDate)
        .orderBy('createdAt', "desc")
        .limit(limitData)
        .get()
    return { data: snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } }), lastVisible: snapshot?.docs[snapshot?.docs.length - 1] };
  }

  async getPointingByDateEmpl(uid, startDate, endDate, employeeIDList) {
    const snapshot = await db.collection("pointing")
      .where('managerID', '==', uid)
      .where('createdAt', '>=', startDate)
      .where('createdAt', '<=', endDate)
      .where('employeeID', 'in', employeeIDList)
      .get()
    return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
  }

  async deleteDuplicationPid(pointingList) {
    pointingList?.map(pointing => {
      const duplicationList = pointingList.filter(pointingdupl => pointingdupl.data.pid == pointing.data.pid && pointingdupl.data.in == pointing.data.in);
      if (duplicationList.length > 1) {
        var i = 0;
        duplicationList.map(pointingDup => {
          i++;
          if (i < duplicationList.length) this.delete(pointingDup.id)
        })
      }
    })
  }

  convertStringToDate(date) {
    let parts_of_date = date.split("-");
    return new Date(+parts_of_date[2], parts_of_date[1] - 1, +parts_of_date[0]);
  }

  sortByDate(pointingList) {
    return pointingList?.sort((a, b) => this.convertStringToDate(b.data.day) - this.convertStringToDate(a.data.day))
  }

  searchBayName(pointingList, employees, name) {
    console.log(name);
    console.log(pointingList);
    var pointingResult = [];
    pointingList?.map((pointing) => {
      const data = pointing.data;
      const employeeName = this.getEmployeeName(employees, data.employeeID);
      if (employeeName?.toLowerCase().includes(name.toLowerCase()))
        pointingResult.push(pointing)
    })
    return pointingResult;
  }

  groupPointingByEmployee(pointingList) {
    let group = pointingList?.reduce((r, a) => {
      r[a.data.employeeID] = [...r[a.data.employeeID] || [], a];
      return r;
    }, {});
    return Object.keys(group).map(key => { return { id: key, data: group[key] } });
  }


  totalWorkDuration(data) {
    var duration = 0;
    data?.map((pointing) => {
      duration += this.workDuration(pointing.data['in'], pointing.data['out'])
    })
    return Math.round(duration / 60);
  }

  workDuration(startTime, end) {
    const endTime = end ?? startTime;
    return (Number(endTime.split(':')[0]) - Number(startTime.split(':')[0])) * 60 + (Number(endTime.split(':')[1]) - Number(startTime.split(':')[1]));
  }

  formatDate(date) {
    const day = 10 <= Number(date.split('-')[0]) ? date.split('-')[0] : "0" + date.split('-')[0];
    const mounth = 10 <= Number(date.split('-')[1]) ? date.split('-')[1] : "0" + date.split('-')[1];
    return day + '/' + mounth + '/' + date.split('-')[2];
  }

  formatDateTime(date) {
    return date ? this.formatDate(date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()) : '-';
  }

  calculMean(value, lengthValue) {
    return Math.round(value / lengthValue);
  }

  getEmployeeName(employees, id) {
    return employees?.filter(employee => employee.id == id)[0]?.data.name;
  }

  getEmployeeNum(employees, id) {
    return employees?.filter(employee => employee.id == id)[0]?.data.num;
  }

  delete(id) {
    return db.collection("pointing").doc(id).delete();
  }

  EmployeeListOption(employees) {
    var employeeOptions = [{ value: '', label: '' }];
    employees?.map((employee) => {
      employeeOptions.push({
        value: employee.id,
        label: employee.data.name + ' - ' + employee.data.job
      })
    })
    return employeeOptions
  }

  getWorkingDays(startDate, endDate) {
    var result = 0;
    var currentDate = startDate;
    while (currentDate <= endDate) {
      var weekDay = currentDate.getDay();
      if (weekDay != 0 && weekDay != 6)
        result++;

      currentDate.setDate(currentDate.getDate() + 1);
    }
    return result;
  }

  sortByIn(pointings, asc = true) {
    if (asc) return pointings.sort((a, b) => a.data.in.localeCompare(b.data.in));
    return pointings.sort((a, b) => b.data.in.localeCompare(a.data.in));
  }

  sortByOut(pointings, asc = true) {
    if (asc) return pointings.sort((a, b) => (a.data.out ?? '0').localeCompare(b.data.out ?? '0'));
    return pointings.sort((a, b) => (b.data.out ?? '0').localeCompare(a.data.out ?? '0'));
  }

}

export default new PointingService();