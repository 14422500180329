import { Button, Modal } from "react-bootstrap";
import React, { Component } from 'react';


const ConfirmDelete = (props) => {


    return (
        <Modal show={props.show}>
            <Modal.Header>
                <Modal.Title>Suppression</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Etes-vous sur de vouloir supprimer {props.name} ?</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.setShow(false)} >Annuler</Button>
                <Button variant="danger" onClick={() => props.deleteElement(props.elementId)}>Oui, je suis sûr</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmDelete;