import 'rc-time-picker/assets/index.css';

import React, { useEffect, useState } from "react";
// react-bootstrap components
import {
    Button,
    Card,
    Form,
    Table,
    Container,
    Modal,
    Row,
    Col,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { firebase_app } from "../components/config/firebase";
import configService from "../components/service/config.service";
import NotificationAlert from "react-notification-alert";
import { notify } from "../components/service/notification.service";
import DatePicker from "react-datepicker";
import moment from 'moment';
import Select from 'react-select';


import { AiFillPlusCircle, AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { BsFillPersonFill, BsFillTrashFill } from "react-icons/bs";
import planningFunctionService from "../components/service/planning-function.service";
import employeeService from "../components/service/employee.service";
import pointingService from "../components/service/pointing.service";
import leaveService from "../components/service/leave.service";
import { Helmet } from "react-helmet";


const auth = firebase_app.auth();

function Leave() {

    const [Leaves, setLeaves] = useState(null);

    const [id, setId] = useState(null);
    const [uid, setUid] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [haveData, setHaveData] = useState(false);
    const [leaveTypeSelected, setLeaveTypeSelected] = useState(null);
    const [leaveTypes, setLeaveTypes] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [duration, setDuration] = useState(0);
    const [dayWork, setDayWork] = useState(5);
    const [requestMessage, setRequestMessage] = useState('');
    const [rejectMessage, setRejectMessage] = useState('');
    const [date, setDate] = useState(null);
    const [employeeSelected, setEmployeeSelected] = useState(null);
    const [employees, setEmployees] = useState(null);
    const [congeStatus, setCongeStatus] = useState({ value: 'waiting', label: 'En attente' });


    const navigate = useNavigate();

    const getLeaves = async () => {
        setHaveData(true);
        firebase_app.auth().onAuthStateChanged(async function (user) {
            if (user) {
                const userUid = user.uid;
                setUid(userUid);
                await configService.checkMonthIsActive(navigate, userUid);
                const leaveList = await leaveService.getLeaveByStatus(userUid, 'waiting');
                const leaveTypeList = await leaveService.getLeaveType();
                const userData = await configService.getUserByID(userUid);
                leaveList ? setLeaves(leaveService.sortLeaves(leaveList)) : setLeaves([]);
                leaveTypeList ? setLeaveTypes(leaveTypeList) : setLeaveTypes([]);
                userData ? setDayWork(userData?.num_day_week) : setUserData(5);
                const employeeList = await employeeService.getEmployee(userUid);
                employeeList ? setEmployees(employeeList) : setEmployees([]);
            } else {
                console.log('Not connected');
                navigate('/login');
            }
        });
    }

    const updateLeaves = async (status) => {
        const leaveList = await leaveService.getLeaveByStatus(uid, status);
        leaveList ? setLeaves(leaveService.sortLeaves(leaveList)) : setLeaves([]);
    }


    const createOrUpdateLeave = async () => {
        const newLeave = {
            "createdBy": uid,
            "startDate": startDate,
            "endDate": endDate,
            "requestMessage": requestMessage ?? '',
            "rejectMessage": rejectMessage ?? '',
            "date": date,
            "duration": leaveService.calcBusinessDays(startDate, endDate, dayWork),
            "author": employeeSelected.value.id,
            "leaveType": leaveTypeSelected.value.id,
            "status": 'waiting'
        }

        if (id) {
            leaveService.update(id, newLeave).then((res) => {
                notify('Modifier avec succés', "tc", 2);
                updateLeaves(congeStatus.value);
            }).catch((error) => {
                console.log(error.message);
            });
        }
        else {
            leaveService.create(newLeave).then((res) => {
                notify('Créer avec succés', "tc", 2);
                updateLeaves(congeStatus.value);
            }).catch((error) => {
                console.log(error.message);
            });
        }
    }

    const selectToUpdate = (idSelected) => {
        const selectedLeave = Leaves?.filter(leave => leave.id == idSelected)[0];
        const data = selectedLeave?.data;
        const currentEmployee = employees?.filter(employee => employee.id == data.author)[0] ?? null;
        const currentLeaveType = leaveTypes?.filter(leaveType => leaveType.id == data.leaveType)[0] ?? null;
        setId(idSelected);
        setStartDate(data.startDate.toDate());
        setEndDate(data.endDate.toDate());
        setDate(data.date.toDate());
        setRequestMessage(data.requestMessage);
        setRejectMessage(data.rejectMessage);

        if (currentEmployee) {
            setEmployeeSelected({ 'value': currentEmployee, 'label': currentEmployee?.data?.name + ' - ' + currentEmployee?.data?.job });
        }

        if (currentLeaveType) {
            setLeaveTypeSelected({ 'value': currentLeaveType, 'label': currentLeaveType?.data?.name });
        }

        setShowModal(true);
    }

    const createLeave = () => {
        setId(null);
        setStartDate(new Date());
        setEndDate(new Date());
        setRequestMessage('');
        setRejectMessage('');
        setDate(new Date());
        setEmployeeSelected(null);
        setShowModal(true);
    }

    const getBackgroundColor = (leaveId) => {
        if (id == leaveId) return '#FFF'
        return 'transparent'
    }

    const deleteLeave = (id) => {
        leaveService.delete(id).then(() => {
            notify('Supprimer avec succés', "tc", 2);
            updateLeaves(congeStatus.value);
        }).catch((error) => {
            console.log(error.message);
        });
    }

    const acceptOrRejectLeave = (id, status) => {
        const newLeave = { "status": status }
        leaveService.update(id, newLeave).then(() => {
            notify('Réussi avec succés', "tc", 2);
            updateLeaves(congeStatus.value);
        }).catch((error) => {
            console.log(error.message);
        });
    }


    useEffect(() => {
        if (haveData == false) getLeaves();
    })

    const LeaveTypeOption = (leaveTypes) => {
        var leaveTypeOptions = [];
        leaveTypes?.map((leaveType) => {
            leaveTypeOptions.push({
                value: leaveType,
                label: leaveType.data.name
            })
        })
        return leaveTypeOptions;
    }

    const displayDate = (day) => {
        return (<>
            <div className="daynum">{day.getDate() > 9 ? day.getDate() : '0' + day.getDate()}
            </div> {(day.getMonth() + 1) + '/' + day.getFullYear().toString().substr(-2)}
        </>)
    }

    const congeStatusOption = () => {
        return [
            {
                value: 'waiting',
                label: 'En attente',
            },
            {
                value: 'accepted',
                label: 'Accepté'
            },
            {
                value: 'refused',
                label: 'Refusé'
            }
        ]
    }

    const okTime = () => {
        return (<Button className='time-ok-button' onClick={() => console.log('OK')}> OK
        </Button>)
    }
    const displayLeaveList = () => {
        return Leaves?.map((leave) => {
            const data = leave.data;
            const employeeName = pointingService.getEmployeeName(employees, data?.author);
            const leaveTypeName = leaveTypes?.filter(leaveType => leaveType.id == data?.leaveType)[0]?.data.name;
            return (
                <tr key={leave.id} style={{ backgroundColor: getBackgroundColor(leave.id) }}>
                    <td className='pointer' onClick={() => selectToUpdate(leave.id)}>
                        <div key={'planning.id'} className='employee-task'>
                            <span className='task-icon'>
                                <BsFillPersonFill color="#9A2462" />
                            </span>
                            <span className='employee-task-name'>
                                {employeeName}
                            </span>
                        </div>
                    </td>
                    <td className='leave-name'>{leaveTypeName}</td>
                    <td> {data.duration} jours </td>
                    <td> {displayDate(data?.startDate.toDate())}</td>
                    <td>{displayDate(data?.endDate.toDate())}</td>
                    {data?.status == 'waiting' ?
                        (<>
                            <td>
                                <AiOutlineCloseCircle color="#f8a86a" className="pointer accept-reject" onClick={() => acceptOrRejectLeave(leave.id, 'refused')} />
                            </td>
                            <td>
                                <AiOutlineCheckCircle color="#007fa6" className="pointer accept-reject" onClick={() => acceptOrRejectLeave(leave.id, 'accepted')} />
                            </td>
                        </>) : (
                            <td>
                                <BsFillTrashFill color="red" className="pointer" onClick={() => deleteLeave(leave.id)} />
                            </td>
                        )}
                </tr>
            )
        })
    }

    return (
        <>
            <Helmet>
                <title>Tyndee - Congé</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="card-plain table-plain-bg">
                            <Card.Header>
                                <Button className='action-button-add' onClick={createLeave}>Nouveau congé <AiFillPlusCircle /></Button>
                                <Card.Title as="h3">Liste des congés</Card.Title>
                                <Col md="2">
                                    <Select
                                        value={congeStatus}
                                        onChange={(val) => {
                                            setCongeStatus(val);
                                            updateLeaves(val.value);
                                        }}
                                        options={congeStatusOption()}
                                    /></Col>
                            </Card.Header>
                            <Modal
                                className="modal-primary"
                                size="lg"
                                show={showModal}
                                onHide={() => setShowModal(false)}>
                                <Modal.Header className="justify-content-center">
                                    <Card.Title as="h3">Congé</Card.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Col md="12">
                                            <Form>
                                                <Row>
                                                    <Row>
                                                        <Col md="6">
                                                            <Form.Group>
                                                                <label>Début</label>
                                                                <DatePicker dateFormat="dd/MM/yyyy" selected={startDate} onChange={(date) => {
                                                                    setStartDate(date);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="6">
                                                            <Form.Group>
                                                                <label>Fin</label>
                                                                <DatePicker dateFormat="dd-MM-yyyy" selected={endDate} onChange={(date) => {
                                                                    setEndDate(date);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="6">
                                                            <Form.Group>
                                                                <label style={{ display: 'block' }}>Durée</label>
                                                                <Form.Control
                                                                    value={leaveService.calcBusinessDays(startDate, endDate, dayWork)}
                                                                    disabled={true}
                                                                    type="number"
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="6">
                                                            <Form.Group>
                                                                <label style={{ display: 'block' }}>Auteur <span className="required">*</span></label>
                                                                <Select
                                                                    placeholder="Employé"
                                                                    defaultValue={employeeSelected}
                                                                    onChange={setEmployeeSelected}
                                                                    options={planningFunctionService.EmployeeListOption(employees)}
                                                                />
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md="6">
                                                            <Form.Group>
                                                                <label style={{ display: 'block' }}>Type de congé <span className="required">*</span></label>
                                                                <Select
                                                                    placeholder="Type de congé"
                                                                    defaultValue={leaveTypeSelected}
                                                                    onChange={setLeaveTypeSelected}
                                                                    options={LeaveTypeOption(leaveTypes)}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <Form.Group>
                                                                <label style={{ display: 'block' }}>Message de demande </label>
                                                                <Form.Control
                                                                    value={requestMessage}
                                                                    type="text"
                                                                    onChange={event => { setRequestMessage(event.target.value) }}
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md="12">
                                                            <Form.Group>
                                                                <label style={{ display: 'block' }}>Message de rejet</label>
                                                                <Form.Control
                                                                    value={rejectMessage}
                                                                    type="text"
                                                                    onChange={event => { setRejectMessage(event.target.value) }}
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="6">
                                                            <Form.Group>
                                                                <label>Date de la demande</label>
                                                                <DatePicker dateFormat="dd/MM/yyyy" selected={date} onChange={(date) => {
                                                                    setDate(date);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <div className="modal-footer">
                                    <Button
                                        className="btn-simple"
                                        type="button"
                                        variant="link"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Retour
                                    </Button>
                                    <Button
                                        className="btn-simple"
                                        type="button"
                                        style={{ color: '#ffff', backgroundColor: '#1f3e76' }}
                                        onClick={() => {
                                            if (employeeSelected && startDate && endDate && leaveTypeSelected) {
                                                createOrUpdateLeave();
                                                setShowModal(false);
                                            }
                                        }}>
                                        Enregistrer
                                    </Button>
                                </div>
                            </Modal>

                            <Card.Body className="table-full-width table-responsive px-0 element-list-display" style={{ tableLayout: 'fixed' }}>
                                <Table className="table-hover" >
                                    <thead>
                                        <tr>

                                            <th className="border-0">Auteur</th>
                                            <th className="border-0">Type</th>
                                            <th className="border-0">Durée </th>
                                            <th className="border-0">Début</th>
                                            <th className="border-0">Fin</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayLeaveList()}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Leave;
