import { firebase_app } from "../../components/config/firebase";


const db = firebase_app.firestore();


class TaskService {

  async getTask(uid) {
    if(!uid) return null;
    const snapshot = await db.collection("task").where("createdBy", "==", uid).get()
    return snapshot.docs?.map(doc => {return {'id':doc.id, 'data':doc.data()}});
  }

  create(task) {
    return db.collection("task").add(task);
  }

  update(id, value) {
    return db.collection("task").doc(id).update(value);
  }

  delete(id) {
    return db.collection("task").doc(id).delete();
  }

  sortTasks(tasks) {
    return tasks.sort(function (a, b) {
        return b.data.date.toDate().getTime() - a.data.date.toDate().getTime()
    });
}

}

export default new TaskService();