import 'rc-time-picker/assets/index.css';

import React, { useEffect, useState } from "react";
// react-bootstrap components
import {
    Button,
    Card,
    Form,
    Table,
    Container,
    Modal,
    Row,
    Col,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";import { firebase_app } from "../components/config/firebase";
import configService from "../components/service/config.service";
import NotificationAlert from "react-notification-alert";
import { notify } from "../components/service/notification.service";
import DatePicker from "react-datepicker";
import moment from 'moment';
import "moment/locale/fr";
import Select from 'react-select';
import TimePicker from 'rc-time-picker';

import 'react-calendar/dist/Calendar.css';

import { AiFillPlusCircle, AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { BsFillCircleFill, BsFillPersonFill, BsFillTrashFill, BsGeoAltFill } from "react-icons/bs";
import planningFunctionService from "../components/service/planning-function.service";
import employeeService from "../components/service/employee.service";
import pointingService from "../components/service/pointing.service";
import appointmentService from "../components/service/appointment.service";
import { Helmet } from "react-helmet";
import blocationService from '../components/service/blocation.service';
import PhoneInput from 'react-phone-input-2';
import Calendar from 'react-calendar';
import { FcSearch } from 'react-icons/fc';
import ConfirmDelete from '../components/ConfimDelete';


const auth = firebase_app.auth();

function Appointment() {

    const [appointments, setAppointments] = useState(null);

    const [id, setId] = useState(null);
    const [uid, setUid] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [haveData, setHaveData] = useState(false);
    const [appointmentTypeSelected, setAppointmentTypeSelected] = useState(null);
    const [appointmentTypes, setAppointmentTypes] = useState(null);
    const [blocations, setBlocations] = useState(null);
    const [blocationSelected, setBlocationSelected] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(moment().format("HH:mm"));
    const [endDate, setEndDate] = useState(new Date());
    const [endTime, setEndTime] = useState(moment().format("HH:mm"))
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState('');
    const [employeeSelected, setEmployeeSelected] = useState([]);
    const [employees, setEmployees] = useState(null);
    const [congeStatus, setCongeStatus] = useState({ value: 'waiting', label: 'En attente' });
    const [contactName, setContactName] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [calendarValue, setCalendarValue] = useState(new Date());
    const [dateFilter, setDateFilter] = useState(new Date());
    const [appointmentByContact, setAppointmentByContact] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);


    const navigate = useNavigate();

    const getAppointments = async () => {
        setHaveData(true);
        firebase_app.auth().onAuthStateChanged(async function (user) {
            if (user) {
                const userUid = user.uid;
                setUid(userUid);
                await configService.checkMonthIsActive(navigate, userUid);
                const appointmentList = await appointmentService.getAppointmentByDate(userUid, dateFilter);
                const appointmentTypeList = await appointmentService.getAppointmentType();
                const blocationList = await blocationService.getBlocation(userUid);
                const userData = await configService.getUserByID(userUid);
                setAppointments(appointmentList ?? []);
                setAppointmentByContact(appointmentList ?? []);
                setAppointmentTypes(appointmentTypeList ?? []);
                setBlocations(blocationList ?? []);
                const defaultType = appointmentTypeList?.filter(type => type.data.name == 'Rendez-vous')[0];
                if (defaultType) setAppointmentTypeSelected({ 'value': defaultType, 'label': defaultType.data.name });
                const employeeList = await employeeService.getEmployee(userUid);
                setEmployees(employeeList ?? []);
            } else {
                console.log('Not connected');
                navigate('/login');
            }
        });
    }

    const updateAppointments = async (date) => {
        const appointmentList = await appointmentService.getAppointmentByDate(uid, date);
        setAppointments(appointmentList ?? []);
        setAppointmentByContact(appointmentList ?? []);
    }


    const createOrUpdateAppointment = async () => {
        const newAppointment = {
            "title": appointmentTypeSelected.label + ' ' + contactName,
            "createdBy": uid,
            "startDate": startDate,
            "startTime": startTime,
            "endDate": endDate,
            "endTime": endTime,
            "type": appointmentTypeSelected.value.id,
            "description": description,
            "blocation": blocationSelected?.map(blocation => blocation.value.id) ?? [],
            "contactName": contactName,
            "contactPhone": contactPhone,
            "contactEmail": contactEmail,
            "employees": employeeSelected?.map(emp => emp.value.id) ?? [],
        }

        if (id) {
            appointmentService.update(id, newAppointment).then((res) => {
                notify('Modifier avec succés', "tc", 2);
                updateAppointments(dateFilter);
            }).catch((error) => {
                console.log(error.message);
            });
        }
        else {
            appointmentService.create(newAppointment).then((res) => {
                notify('Créer avec succés', "tc", 2);
                updateAppointments(dateFilter);
            }).catch((error) => {
                console.log(error.message);
            });
        }
    }

    const selectToUpdate = (idSelected) => {
        const selectedAppointment = appointments?.filter(appointment => appointment.id == idSelected)[0];
        const data = selectedAppointment?.data;
        const currentEmployee = employees?.filter(employee => employee.id == data.author)[0] ?? null;
        const currentAppointmentType = appointmentTypes?.filter(appointmentType => appointmentType.id == data.type)[0] ?? null;
        setId(idSelected);
        setTitle(data.title);
        setStartDate(data.startDate.toDate());
        setEndDate(data.endDate.toDate());
        setStartTime(data.startTime);
        setEndTime(data.endTime);
        setDescription(data.description);
        setContactName(data.contactName);
        setContactEmail(data.contactEmail);
        setContactPhone(data.contactPhone);
        if (currentAppointmentType) {
            setAppointmentTypeSelected({ 'value': currentAppointmentType, 'label': currentAppointmentType.data.name });
        }
        setBlocationSelected(blocations?.filter(location => data.blocation.includes(location.id))?.map(blocSelected => {
            return { 'value': blocSelected, 'label': blocSelected.data.name }
        }));
        setEmployeeSelected(employees?.filter(employee => data.employees.includes(employee.id))?.map(emplSelected => {
            return { 'value': emplSelected, 'label': emplSelected.data.name }
        }));
        setShowModal(true);
    }


    const createAppointment = () => {
        setId(null);
        setTitle(null)
        setStartDate(new Date());
        setEndDate(new Date());
        setEmployeeSelected(null);
        setBlocationSelected(null);
        setShowModal(true);
    }

    const getBackgroundColor = (appointmentId) => {
        if (id == appointmentId) return '#FFF'
        return 'transparent'
    }

    const deleteAppointment = (id) => {
        appointmentService.delete(id).then(() => {
            notify('Supprimer avec succés', "tc", 2);
            setShowDeleteConfirm(false);
            updateAppointments(dateFilter);
        }).catch((error) => {
            console.log(error.message);
        });
    }

    const acceptOrRejectAppointment = (id, status) => {
        const newAppointment = { "status": status }
        appointmentService.update(id, newAppointment).then(() => {
            notify('Réussi avec succés', "tc", 2);
            updateAppointments();
        }).catch((error) => {
            console.log(error.message);
        });
    }


    useEffect(() => {
        if (haveData == false) getAppointments();
    })

    const appointmentTypeOption = (appointmentTypes) => {
        var appointmentTypeOptions = [];
        appointmentTypes?.map((appointmentType) => {
            appointmentTypeOptions.push({
                value: appointmentType,
                label: appointmentType.data.name
            })
        })
        return appointmentTypeOptions;
    }

    const blocationOption = (blocations) => {
        var blocationOptions = [];
        blocations?.map((blocation) => {
            blocationOptions.push({
                value: blocation,
                label: blocation.data.name
            })
        })
        return blocationOptions;
    }

    const searchByContactName = (text) => {
        var result = [];
        appointments.map(appointment => {
            if (appointment?.data.contactName.toLowerCase().includes(text.toLowerCase()))
                result.push(appointment);
        })
        setAppointmentByContact(result);
    }

    const changeDateFilter = (date) => {
        setDateFilter(date);
        updateAppointments(date);
    }

    const displayElementList = (elements) => {
        return elements?.map((element) => {
            return (
                <div key={element.id} className='employee-task'>
                    <span className='task-icon'>
                        < BsGeoAltFill color="#1f3e76" />
                    </span>
                    <span className='employee-task-name'>
                        {element.data.name}
                    </span>
                </div>
            )
        })
    }

    const okTime = (panel, time) => {
        return (<Button className='time-ok-button' onClick={() => {
            time == 'start' ? setStartTime(panel.state.value.format("HH:mm")) : setEndTime(panel.state.value.format("HH:mm"));
            panel.close();
        }}> OK
        </Button>)
    }

    const displayAppointmentList = () => {
        return appointmentByContact?.map((appointment) => {
            const data = appointment.data;
            const employeeName = pointingService.getEmployeeName(employees, data?.author);
            const appointmentTypeName = appointmentTypes?.filter(appType => appType.id == data?.type)[0]?.data.name;

            const emplacements = blocations?.filter(location => data.blocation.includes(location.id));

            return (
                <tr key={appointment.id} style={{ backgroundColor: getBackgroundColor(appointment.id) }}>
                    <td className='pointer' onClick={() => selectToUpdate(appointment.id)}>
                        {data.title}
                    </td>
                    <td className='appointment-name'>{appointmentTypeName}</td>
                    <td>{displayElementList(emplacements)}</td>
                    <td>{data.contactName}</td>
                    <td><span className='employee-task'>
                        {data.startTime}
                    </span></td>
                    <td><span className='employee-task'>
                        {data.endTime}
                    </span></td>

                </tr>
            )
        })
    }

    return (
        <>
            <Helmet>
                <title>Tyndee - Congé</title>
            </Helmet>
            <Container fluid>
                <ConfirmDelete show={showDeleteConfirm} name="ce rendez-vous" setShow={setShowDeleteConfirm} deleteElement={deleteAppointment} elementId={id} />
                <Row>
                    <Col md="12">
                        <Card className="card-plain table-plain-bg">
                            <Card.Header>
                                <Card.Title as="h3">Rendez-vous</Card.Title>
                                <hr></hr>
                                <Button className='action-button-add' onClick={() => navigate('/admin/locations')}> Emplacements </Button>
                                <Button className='action-button-add' onClick={createAppointment}>Nouveau rv <AiFillPlusCircle /></Button>
                                <Col md={6} style={{ display: 'flex' }}>
                                    <Form.Control placeholder="Rechercher un contact" type="text" onChange={event => searchByContactName(event.target.value)} ></Form.Control>
                                    <Col style={{ marginRight: '3px' }}>
                                        <button type="button" className="btn" style={{ border: 0 }}>
                                            <FcSearch />
                                        </button>
                                    </Col>
                                </Col>

                            </Card.Header>
                            <Modal
                                className="modal-primary"
                                size="lg"
                                show={showModal}
                                onHide={() => setShowModal(false)}>
                                <Modal.Body>
                                    <Row>
                                        <Col md="12">
                                            <Form>
                                                <Row>
                                                    <h4>{title ?? appointmentTypeSelected?.label + ' ' + contactName}</h4>
                                                    <hr></hr>
                                                    <Row className="mbottom-15">
                                                        <Col md="6" className="mbottom-15">
                                                            <Form.Group>
                                                                <Row>
                                                                    <Col lg="4" md="6" xs="5" className="g-datepicker">
                                                                        <DatePicker dateFormat="dd-MM-yyyy" selected={startDate} onChange={(date) => {
                                                                            setStartDate(date);
                                                                        }} />
                                                                    </Col>
                                                                    <Col xs="3" className="g-datepicker g-timepicker">
                                                                        <TimePicker defaultValue={appointmentService.timeToMomentTime(startTime)} showSecond={false} minuteStep={15} addon={(panel) => okTime(panel, 'start')} />
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="6">
                                                            <Form.Group>
                                                                <Row>
                                                                    <Col lg="4" md="6" xs="5" className="g-datepicker">
                                                                        <DatePicker dateFormat="dd-MM-yyyy" selected={endDate} onChange={(date) => {
                                                                            setEndDate(date);
                                                                        }} />
                                                                    </Col>
                                                                    <Col xs="3" className="g-datepicker g-timepicker">
                                                                        <TimePicker defaultValue={appointmentService.timeToMomentTime(endTime)} showSecond={false} minuteStep={15} addon={(panel) => okTime(panel, 'end')} />
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mbottom-15">
                                                        <Col md="6">
                                                            <Form.Group>
                                                                <Select
                                                                    placeholder="Type"
                                                                    defaultValue={appointmentTypeSelected}
                                                                    onChange={setAppointmentTypeSelected}
                                                                    options={appointmentTypeOption(appointmentTypes)}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mbottom-15">
                                                        <Col md="11">
                                                            <Form.Group>
                                                                <Form.Control
                                                                    value={description}
                                                                    type="text"
                                                                    as="textarea"
                                                                    placeholder="Ajouter une description"
                                                                    style={{ height: '65px' }}
                                                                    onChange={event => { setDescription(event.target.value) }}
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    {appointmentTypeSelected?.value.data?.name == 'Réservation' &&
                                                        (<Row className="mbottom-15">
                                                            <Col md="11">
                                                                <Form.Group>
                                                                    <Select
                                                                        placeholder="Emplacement"
                                                                        defaultValue={blocationSelected}
                                                                        onChange={setBlocationSelected}
                                                                        options={blocationOption(blocations)}
                                                                        isMulti={true}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>)}
                                                    <h4>
                                                        Contact
                                                    </h4>
                                                    <Row className="mbottom-15">
                                                        <Col className="pr-1" md="6">
                                                            <Form.Group>
                                                                <Form.Control
                                                                    defaultValue={contactName}
                                                                    autoComplete="name"
                                                                    alt="name"
                                                                    placeholder="Nom du contact *"
                                                                    type="text"
                                                                    onChange={event => { setContactName(event.target.value); }}
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="pl-1" md="5">
                                                            <Form.Group>
                                                                <PhoneInput
                                                                    country={'sn'}
                                                                    autoFormat={false}
                                                                    inputStyle={{
                                                                        paddingTop: '19px',
                                                                        paddingBottom: '19px',
                                                                        width: '100%'
                                                                    }}
                                                                    value={contactPhone}
                                                                    autoComplete="tel"
                                                                    onChange={(value) => { setContactPhone('+' + value) }}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mbottom-15">
                                                        <Col className="pr-1" md="6">
                                                            <Form.Group>
                                                                <Form.Control
                                                                    defaultValue={contactEmail}
                                                                    autoComplete="name"
                                                                    alt="name"
                                                                    placeholder="contact@gmail.com"
                                                                    type="text"
                                                                    onChange={event => { setContactEmail(event.target.value) }}
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <h4>
                                                        Intervenants
                                                    </h4>
                                                    <Row className="mbottom-15">
                                                        <Col md="11">
                                                            <Form.Group>
                                                                <Select
                                                                    placeholder="Ajouter"
                                                                    defaultValue={employeeSelected}
                                                                    onChange={setEmployeeSelected}
                                                                    options={planningFunctionService.EmployeeListOption(employees)}
                                                                    isMulti={true}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <div className="modal-footer">
                                    <Button
                                        className="btn-simple"
                                        type="button"
                                        variant="link"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Retour
                                    </Button>
                                    <Button
                                        className="btn-simple"
                                        type="button"
                                        style={{ color: '#ffff', backgroundColor: '#1f3e76' }}
                                        onClick={() => {
                                            if (contactName) {
                                                createOrUpdateAppointment();
                                                setShowModal(false);
                                            }
                                        }}>
                                        Enregistrer
                                    </Button>
                                </div>
                            </Modal>
                            <Row>
                                <Col lg="10" md="10" xs="12">
                                    <Card.Body className="table-full-width table-responsive px-0 element-list-display" style={{ marginRight: 0 }}>
                                        <Row>
                                            <Col>
                                                <Calendar onChange={(date) => changeDateFilter(date)} value={dateFilter} locale='fr-FR' />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Col>
                                <Col lg="12" md="12">
                                    <Card.Body className="table-full-width table-responsive px-0 element-list-display" style={{ tableLayout: 'fixed', marginLeft: 0 }}>
                                        <Table className="table-hover" >
                                            <thead>
                                                <tr>
                                                    <th className="border-0">Titre</th>
                                                    <th className="border-0">Type</th>
                                                    <th className="border-0">Emplacement</th>
                                                    <th className="border-0">Contact</th>
                                                    <th className="border-0">Début</th>
                                                    <th className="border-0">Fin</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displayAppointmentList()}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default Appointment;
