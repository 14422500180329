import React from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';


export const notify = (message, place, type) => {
    switch (type) {
        case 1:
            NotificationManager.info(message, 'Info');
            break;
        case 2:
            NotificationManager.success(message, 'Success');
            break;
        case 4:
            NotificationManager.warning(message);
            break;
        case 3:
            NotificationManager.error(message, 'Désolé');
            break;
    }
};
