import React, { useEffect, useState } from "react";
import ChartistGraph from "react-chartist";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { firebase_app } from "../components/config/firebase";
import { useNavigate } from "react-router-dom";
import PointingService from "../components/service/pointing.service";
import EmployeeService from "../components/service/employee.service";
import configService from "../components/service/config.service";
import { AiOutlineTeam, AiTwotoneEnvironment } from "react-icons/ai";
import { BiSun } from "react-icons/bi";
import { Helmet } from 'react-helmet';
import { BsCalendar2Check, BsCardChecklist } from "react-icons/bs";
import { GOOGLE_MAP_API_KEY } from "../components/MyGoogleMap";
import GoogleMapReact from 'google-map-react';
import Marker from "../components/Marker";

import moment from 'moment';
import "moment/locale/fr";
import siteService from "../components/service/site.service";
import leaveService from "../components/service/leave.service";
import appointmentService from "../components/service/appointment.service";
import QRCode from 'qrcode.react';


function Dashboard() {

  const [userInfo, setUserInfo] = useState(null);
  const [employeeNumber, setEmployeeNumber] = useState(1);
  const [timeWork, setTimeWork] = useState(0);
  const [timeAbsence, setTimeAbsence] = useState(0);
  const [leaveNumber, setLeaveNumber] = useState(0);
  const [appointmentNumber, setAppointmentNumber] = useState(0);
  const [sites, setSites] = useState([]);
  const [timeSup, setTimeSup] = useState(0);
  const [haveData, setHaveData] = useState(false);
  const navigate = useNavigate();

  const downloadQR = () => {
    const canvas = document.getElementById("qrcode_pointing");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrcode_pointing.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const getData = () => {
    setHaveData(true);
    firebase_app.auth().onAuthStateChanged(async function (user) {
      if (user) {
        setUserInfo(user);
        const uid = user.uid;
        await configService.checkMonthIsActive(navigate, uid);
        const date = moment().endOf('day').toDate()
        const firstDay = moment().startOf('day').toDate();
        const pointingList = await PointingService.getAllPointingByDate(uid, firstDay, date)
        const employeeList = await EmployeeService.getEmployee(uid);
        const siteList = await siteService.getSite(uid);
        const leaveList = await leaveService.getLeaveByStatus(uid, 'waiting');
        const appoinmentList = await appointmentService.getAppointmentByDate(uid, new Date());
        const workDuration = PointingService.totalWorkDuration(pointingList);
        const diffTime = employeeList?.length * 8 - workDuration;
        diffTime > 0 ? setTimeAbsence(diffTime) : setTimeSup(-1 * diffTime);
        setTimeWork(workDuration);
        setEmployeeNumber(employeeList?.length);
        setLeaveNumber(leaveList?.length);
        setAppointmentNumber(appoinmentList?.length);
        setSites(siteList ?? []);

      } else {
        console.log('Not connected');
        navigate('/login');
      }
    });
  }

  const displaySiteMarker = () => {
    return sites?.map(site => {
      return (
        <Marker key={site.id}
          text={site.data.name}
          lat={site.data.latitude}
          lng={site.data.longitude}
        />)
    })
  }

  useEffect(() => {
    if (haveData == false) getData();
  });


  return (
    <>
      <Helmet>
        <title>Tyndee - Dashboard</title>
      </Helmet>
      <Container fluid className="mtop-25">
        <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row onClick={() => navigate('/admin/employees')}>
                  <Col xs="5">
                    <div className="icon-big text-center">
                      <AiOutlineTeam />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Employés</p>
                      <Card.Title as="h4" style={{ fontWeight: '500' }} className="pointer">{employeeNumber}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  {moment().format('LL')}
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row onClick={() => navigate('/admin/pointings')}>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <AiTwotoneEnvironment color="blue" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Présences</p>
                      <Card.Title style={{ fontWeight: '500' }} as="h4" className="pointer">{timeWork}H</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  {moment().format('LL')}
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <BiSun color="red" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Congés</p>
                      <Card.Title as="h4" style={{ fontWeight: '500' }} className="pointer">{leaveNumber}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  {moment().format('LL')}
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <BsCalendar2Check color="#1E6A56" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Rendez-vous</p>
                      <Card.Title as="h4" style={{ fontWeight: '500' }} className="pointer">{appointmentNumber}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  {moment().format('LL')}
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>

          <Col md="8">
            <Card style={{ display: 'block' }}>
              <Card.Header>
                <Card.Title as="h4" className="fw-400">Sites</Card.Title>
                <p className="card-category">{sites[0]?.data.name} , {sites[1]?.data.name} ...</p>
              </Card.Header>
              <Card.Body>
                <div style={{ height: '48vh', width: '100%' }}>
                  <GoogleMapReact
                    center={[sites[0]?.data.latitude ?? 14.7459343, sites[0]?.data.longitude ?? -17.4655227]}
                    zoom={13}
                    draggable={true}
                    onChildClick={() => console.log('child click')}
                    bootstrapURLKeys={{
                      key: GOOGLE_MAP_API_KEY,
                      libraries: ['places', 'geometry', 'localContext'],
                    }}>
                    {displaySiteMarker()}
                  </GoogleMapReact>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="4">
            <Card style={{ flexWrap: 'wrap', textAlign: 'center' }}>
              <Card.Header style={{ textAlign: 'left' }}>
                <Card.Title as="h4" className="fw-400">QRCode de pointage</Card.Title>
                <p className="card-category">Imprimer ce qrcode pour les pointages</p>
              </Card.Header>
              <Card.Body onClick={downloadQR}>
                <div className="ct-chart ct-perfect-fourth" id="chartHours">
                  <QRCode
                    id="qrcode_pointing"
                    value={userInfo ? userInfo.uid : ''}
                    size={270}
                    level={"H"}
                    includeMargin={true}
                  />
                </div>
              </Card.Body>
              <Card.Footer>
                <h4 className="card-title pointer" onClick={downloadQR} style={{ textAlign: 'left' }}>
                  <i style={{
                    marginRight: 10,
                  }}
                    className="nc-icon nc-cloud-download-93 text-primary"></i>
                  Télécharger le qrcode
                </h4>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-history"></i>
                  Updated now
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;