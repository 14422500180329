
import React, { Component } from "react";
import { useLocation, NavLink } from "react-router-dom";

import { Button, Nav } from "react-bootstrap";
import { firebase_app } from "../config/firebase";
import { BsPower } from "react-icons/bs";
import { AiOutlinePoweroff } from "react-icons/ai";


const mobileSidebarToggle = (e) => {
  e.preventDefault();
  document.documentElement.classList.toggle("nav-open");
  var node = document.createElement("div");
  node.id = "bodyClick";
  node.onclick = function () {
    this.parentElement.removeChild(this);
    document.documentElement.classList.toggle("nav-open");
  };
  document.body.appendChild(node);
};


function Sidebar({ color, image, routes }) {
  const location = useLocation();
  const activeRoute = (routeName) => {
    console.log('routeName', routeName)
    console.log('routeName.includes(location.pathname)', routeName.includes(location.pathname))
    return location.pathname.includes(routeName) ? "active" : "";
  };


  return (
    <>
      <Button
        variant="dark"
        className="d-lg-none d-flex justify-content-center align-items-center top-float-lef-btn"
        onClick={mobileSidebarToggle}>
        <i className="fas fa-bars"></i>
      </Button>

      <div className="sidebar">
        <div
          className="sidebar-background"
        />
        <div className="sidebar-wrapper">
          <div className="logo d-flex align-items-center justify-content-start">
            <a
              href="/admin/dashboard"
              className="simple-text logo-mini mx-1"
            >
              <div className="logo-img">
                <img
                  src={require("../../assets/img/tyndeelogo.png")}
                  alt="Tyndee: Pointage et Planning Horaire"
                />
              </div>
            </a>
            <a style={{ color: "#e8ebf1", fontSize: 24 }} className="mr-2 navbar-brand" href="admin/dashboard">
            </a>
          </div>
          <Nav>
            {routes?.map((prop, key) => {
              if (!prop.redirect)
                return (
                  <li
                    className={
                      prop.upgrade
                        ? "active active-pro"
                        : activeRoute(prop.layout + prop.path)
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      style={{ textAlign: 'center', margin: 0, padding: 0 }}
                    >
                      <div style={{ fontSize: 25 }}> {prop.icon} </div>
                      <p style={{ fontSize: 10, fontWeight: 500 }}>{prop.name}</p>
                    </NavLink>
                  </li>

                );
              return null;
            })}
            <li>
              <Nav.Link
                className="nav-link"
                style={{ textAlign: 'center', margin: 0, padding: 0, position: 'absolute', bottom: 15 }}
                onClick={(e) => {
                  firebase_app.auth().signOut().then(function () {
                    navigate('/login');
                  }).catch(function (error) {
                    console.log('Erreur lors de la deconnexion');
                  });
                }}>
                <div style={{ fontSize: 25 }}> <AiOutlinePoweroff /></div>
                <span style={{ fontSize: 10 }} className="no-icon">DECONN.</span>
              </Nav.Link>
            </li>
          </Nav>

        </div>
      </div>
    </>
  );
}

export default Sidebar;
