import React, { useState, useEffect } from "react";
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Modal,
    Form,
    Table,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { firebase_app } from "../components/config/firebase";
import MyGoogleMap from "../components/MyGoogleMap";
import { AiFillLeftCircle } from "react-icons/ai";
import configService from "../components/service/config.service";
import siteService from "../components/service/site.service";
import { notify } from "../components/service/notification.service";
import NotificationAlert from "react-notification-alert";
import employeeService from "../components/service/employee.service";
import { MdMyLocation } from "react-icons/md"
import { Helmet } from "react-helmet";

function SiteDetail() {
    const { id } = useParams();
    const [name, setName] = useState(null);
    const [address, setAddress] = useState(null);
    const [location, setLocation] = useState(null);
    const [haveData, setHaveData] = useState(false);
    const [uid, setUid] = useState(null);
    const [employees, setEmployees] = useState(null);
    const [show, setShow] = useState(false);
    const [searchAdress, setSearchAdress] = useState(false);

    const navigate = useNavigate();

    const getInfos = async () => {
        setHaveData(true);
        firebase_app.auth().onAuthStateChanged(async function (user) {
            if (user) {
                setUid(user.uid);
                const employeeList = await employeeService.getEmployee(user.uid);
                employeeList ? setEmployees(employeeList) : setEmployees([]);
                
                if (id) {
                    const currentSite = await siteService.getSiteByID(id);
                    setName(currentSite?.name);
                    setAddress(currentSite?.address);
                    currentSite?.latitude ? setLocation({ 'lat': currentSite?.latitude, 'lng': currentSite?.longitude }) : setLocation(null);
                }
            }
        })
    }

    useEffect(() => {
        if (haveData == false) getInfos();
    })

    const createOrUpdateSite = () => {
        const newsite =
        {
            "createdBy": uid,
            'name': name,
            "address": address,
            "latitude": location.lat,
            "longitude": location.lng
        };
        if (id == null) {
            siteService.create(newsite).then((res) => {
                navigate('/admin/sites');
            }).catch((error) => {
                console.log(error.message);
            });
        } else {
            siteService.update(id, newsite).then((res) => {
                navigate('/admin/sites');
            }).catch((error) => {
                console.log(error.message);
            });
        }
    }

    const deleteSite = async (id) => {
        removeEmployee(id);
        setShow(false);
        siteService.delete(id).then(() => {
            notify('Site supprimer avec succés', "tc", 2);
            navigate("/admin/sites");
        })
    }

    const removeEmployee = (id) => {
        const employeeList = employees?.filter(employee => employee.data.siteID == id);
        employeeList?.map((employee) => {
            var data = employee.data;
            data.siteID = null;
            employeeService.update(employee.id, data).then((res) => {
                updatesiteList();
            }).catch((error) => {
                console.log(error.message);
            });
        })
    }

    return (
        <>
            <Helmet>
                <title>Tyndee - Sites - Lieux de travail</title>
            </Helmet>
            <Container fluid>
                <Modal show={show}>
                    <Modal.Header>
                        <Modal.Title>Suppression</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Etes-vous sur de vouloir supprimer ce site ?</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShow(false)} >Annuler</Button>
                        <Button variant="danger" onClick={() => deleteSite(id)}>Oui, je suis sûr</Button>
                    </Modal.Footer>
                </Modal>
                <Button className="action-button-save" variant="info" onClick={() => navigate(-1)}> <AiFillLeftCircle /> Retour</Button>
                <Row>
                    <Col md="9">
                        <Card>
                            <Card.Header>
                                <Card.Title as="h3">Lieux de travail ou site</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col className="pr-1" md="5">
                                            <Form.Group>

                                                <label>Nom <span className="required">*</span></label>
                                                <Form.Control
                                                    defaultValue={name}
                                                    placeholder="Nom du Site"
                                                    type="text"
                                                    onChange={event => { setName(event.target.value) }}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col className="pl-1" md="5">
                                            <Form.Group>
                                                <label>Adresse <span className="required">*</span></label>
                                                <Form.Control
                                                    defaultValue={address}
                                                    placeholder="Adresse du Site"
                                                    type="text"
                                                    onChange={event => { setAddress(event.target.value) }}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col className="pr-1" md="10">
                                            <Form.Group>
                                                <label>Localisation</label>
                                                <Button className='btn-simple'
                                                    onClick={() => { searchAdress ? setSearchAdress(false) : setSearchAdress(true) }}>
                                                    {searchAdress ? 'Chercher une ville ' : 'Chercher un lieux '}
                                                    <MdMyLocation />
                                                </Button>
                                                {id ? (location && (<MyGoogleMap searchAdress={searchAdress} location={location} setLocation={setLocation} />)) :
                                                    (<MyGoogleMap location={null} setLocation={setLocation} searchAdress={searchAdress} />)
                                                }
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button className="action-button-save" variant="info" onClick={() => createOrUpdateSite()}>Enregistrer</Button>
                                    {id ? (<Button className="action-button-cancel" onClick={() => setShow(true)}>Supprimer </Button>) : ''}

                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>)
}

export default SiteDetail;