import React, { useEffect, useState } from 'react';
import '../assets/scss/login.scss';
import { completeUserProfile, firebase_app } from "../components/config/firebase";

import ReactLoading from 'react-loading';
import { useNavigate } from "react-router-dom";
import configService from '../components/service/config.service';
import axios from "axios";
import employeeService from '../components/service/employee.service';
import { notify } from '../components/service/notification.service';
import NotificationAlert from "react-notification-alert";
import { BsCheckAll } from "react-icons/bs";
import {
    Button, Col, Form
} from "react-bootstrap";

import moment from 'moment';
import "moment/locale/fr";
import { Helmet } from 'react-helmet';
import PhoneInput from 'react-phone-input-2';

const Configuration = () => {

    const [userDetail, setUserDetail] = useState(null);
    const [uid, setUid] = useState(null);
    const [phone, setPhone] = useState("");
    const [sname, setSName] = useState("");
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [saddress, setSaddress] = useState("");
    const [activity, setActivity] = useState("");
    const [numDayWeek, setNumDayWeek] = useState(5);
    const [numTimeDay, setNumTimeDay] = useState(0);
    const [employeeNumber, setEmployeeNumber] = useState(0);
    const [editConf, setEditConf] = useState(true);
    const [createdDate, setCreatedDate] = useState(null);
    const [currentEmployeeNumber, setCurrentEmployeeNumber] = useState(0);
    const [monthIsActive, setMonthIsActive] = useState(false);
    const [haveData, setHaveData] = useState(false);
    const [formula, setFormula] = useState('premium');
    const [userFormula, setUserFormula] = useState('premium');
    const [currentActivatedMonth, setCurrentActivatedMonth] = useState(null);
    const [monthNumber, setMonthNumber] = useState(1);


    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const getUserDetail = () => {
        if (haveData == false) {
            setHaveData(true);
            firebase_app.auth().onAuthStateChanged(async function (user) {
                if (user) {
                    const uid = user.uid;
                    setUid(uid);
                    getUserData(uid);
                } else {
                    console.log('Not connected');
                    navigate('/login');
                }
            });
        }
    }

    const getUserData = async (uid) => {
        const userData = await configService.getUserByID(uid);
        userData ? setUserDetail({ ...userData }) : setUserDetail({});
        userData ? setEditConf(false) : setEditConf(true);
        const employeeList = await employeeService.getEmployee(uid);
        const activatedMonth = await configService.getActivatedMonth(uid);
        setCurrentActivatedMonth(activatedMonth[0]);
        setMonthIsActive(activatedMonth[0] ? true : false);
        setCurrentEmployeeNumber(employeeList?.length);
        setEmployeeNumber(employeeList?.length);
        setCreatedDate(userData?.created_date);
        setPhone(userData?.phone);
        setSName(userData?.society_name);
        setName(userData?.name);
        setSaddress(userData?.society_address);
        setActivity(userData?.activity);
        setUserFormula(userData?.formula ?? userFormula);
        setFormula(userData?.formula ?? formula);
        setNumDayWeek(userData?.num_day_week);
    }

    useEffect(() => {
        getUserDetail();
    });

    const refreshPage = () => {
        window.location.reload();
    }


    const completeRegister = async () => {
        if (phone && name != '' && sname != '' && activity != '') {
            setLoading(true);
            var data = {
                'phone': phone,
                'name': name,
                'society_name': sname,
                'society_address': saddress ?? '',
                'activity': activity,
                'num_day_week': numDayWeek ?? 5,
                'formula': userFormula,
                'created_date': createdDate ?? new Date()
            }
            completeUserProfile(uid, data).then((res) => {
                getUserData(uid);
                setLoading(false);
                setEditConf(false);
                refreshPage();
            }).catch((error) => {
                console.log(error.message);
                setLoading(false);
            });
        }
    }

    const tyndeeCost = () => {
        var unitPrice = formula == 'basic' ? 800 : 1500
        if (monthNumber >= 3)
            unitPrice = formula == 'basic' ? 700 : 1300
        if (monthNumber == 12)
            unitPrice = formula == 'basic' ? 600 : 1100
        if (employeeNumber > 50 && employeeNumber <= 100)
            unitPrice = unitPrice * .75
        if (employeeNumber > 100)
            unitPrice = unitPrice * .5
        var total_due = unitPrice * monthNumber * employeeNumber
        if (uid == 'WyOwuqgbGGVOe0L1ZNbLcPe3UV52' && formula == 'basic')
            total_due = 250 * monthNumber * employeeNumber
        if (uid == 'H86CNIofvtV7S0cV7pBhWVnLJU82' && formula != 'basic')
            total_due = 1000 * monthNumber * employeeNumber
        return total_due;
    }


    const goToPay = () => {
        console.log('formula', formula)
        if (employeeNumber > 0 && employeeNumber >= currentEmployeeNumber) {
            axios
                .post('https://tyndee.com:5000/payement', {
                    user_id: uid,
                    employee_number: String(employeeNumber),
                    total_due: tyndeeCost(),
                    start_date: moment().startOf('month').format('DD-MM-YYYY'),
                    end_date: moment().startOf('month').add(monthNumber, 'M').format('DD-MM-YYYY'),
                    formula: formula,
                })
                .then((response) => {
                    window.open(response.data.message.response_text, "_blank");
                }).catch((error) => {
                    console.log(error.message);
                });
        } else notify('Le nombre doit être supérieur ou égal au nombre d\'employés existants', "tr", 3);
    }

    return (
        <div className="container">
            <Helmet>
                <title>Tyndee - Configuration</title>
            </Helmet>
            <div className="card-conf">
                <div className="card-login-image">
                    <h2 className="card-login-heading">
                        Profile
                        <small>Configurer votre profile</small>
                    </h2>
                </div>
                <div className="card-login-form">
                    <div className="card-conf-heading">
                        Informations personnelles
                    </div>
                    {editConf ?
                        (<div className="input">
                            <input type="text" value={name} autoComplete="name" className="input-field" onChange={event => { setName(event.target.value) }} required />
                            <label className="input-label">Nom complet <span className="required">*</span></label>
                        </div>) :
                        (<div className="input-value">
                            <small className="color-gray">Nom complet</small><br />
                            <span> {name}</span>
                        </div>)}

                    {editConf ?
                        (<div className="input">
                            <Form.Group>
                                <label style={{ color: "#1F3E76" }}>Téléphone<span className="required">*</span></label>
                                <PhoneInput
                                    country={'sn'}
                                    autoFormat={false}
                                    inputStyle={{
                                        paddingTop: '19px',
                                        paddingBottom: '19px',
                                        width: '100%',
                                    }}
                                    value={phone}
                                    autoComplete="tel"
                                    onChange={(value) => { setPhone('+' + value) }}
                                />
                            </Form.Group>
                        </div>) :
                        (<div className="input-value">
                            <small className="color-gray">Numéro de téléphone</small><br />
                            <span> {phone}</span>
                        </div>)}

                    <div className="card-conf-heading">
                        Informations sur la société
                    </div>
                    {editConf ?
                        (<div className="input">
                            <input type="text" value={sname} autoComplete="organization" className="input-field" onChange={event => { setSName(event.target.value) }} required />
                            <label className="input-label">Nom de la société <span className="required">*</span></label>
                        </div>) :
                        (<div className="input-value">
                            <small className="color-gray">Nom de la société</small><br />
                            <span> {sname}</span>
                        </div>)}

                    {editConf ?
                        (<div className="input">
                            <input type="text" value={activity} autoComplete="organization-title" className="input-field" onChange={event => { setActivity(event.target.value) }} required />
                            <label className="input-label">Secteur d'activité <span className="required">*</span></label>
                        </div>) :
                        (<div className="input-value">
                            <small className="color-gray">Secteur d'activité</small><br />
                            <span> {activity}</span>
                        </div>)}

                    {editConf ?
                        (<div className="input">
                            <input type="text" value={saddress} autoComplete="street-address" className="input-field" onChange={event => { setSaddress(event.target.value) }} required />
                            <label className="input-label">Adresse de la société</label>
                        </div>) :
                        (<div className="input-value">
                            <small className="color-gray">Adresse de la société</small><br />
                            <span> {saddress}</span>
                        </div>)}

                    {editConf ?
                        (<div className="input">
                            <input type="number" defaultValue={numDayWeek} className="input-field" onChange={event => { setNumDayWeek(event.target.value) }} required />
                            <label className="input-label">Nombre de jours de travail par semaine</label>
                        </div>) :
                        (<div className="input-value">
                            <small className="color-gray">Nombre de jours de travail par semaine</small><br />
                            <span> {numDayWeek}</span>
                        </div>)}
                    {editConf ?
                        (<div className="input">
                            <Form.Control
                                value={userFormula}
                                disabled={createdDate ? true : false}
                                as="select"
                                onChange={event => { setUserFormula(event.target.value) }} required>
                                <option value='basic'>Basic</option>
                                <option value='premium'>Premium</option>
                            </Form.Control>
                            <label className="input-label">Formule </label>
                        </div>) :
                        (<div className="input-value">
                            <small className="color-gray">Formule</small><br />
                            <span> {userFormula}</span>
                        </div>)}

                    {/*editConf ?
                        (<div className="input">
                            <input type="number" value={numTimeDay} className="input-field" onChange={event => { setNumTimeDay(event.target.value) }} required />
                            <label className="input-label">Nombre d'heures de travail par jour</label>
                        </div>) :
                        (<div className="input-value">
                            <small className="color-gray" >Nombre d'heures de travail par jour</small><br />
                            <span> {numTimeDay}</span>
                    </div>)*/}
                    {loading &&
                        (<div className="spinner">
                            <ReactLoading color={'#1F3E76'} type={'bubbles'} height={'20%'} width={'40%'} />
                        </div>)}

                    {editConf ?
                        (<div className="action-finish">
                            <Button className="action-button-cancel" onClick={() => { setEditConf(false) }}>Annuler </Button>
                            <Button className="action-button-finish" onClick={() => { completeRegister() }}>Enregistrer </Button>
                        </div>) :
                        (<div className="action-finish" >
                            <Button className="action-button-finish" onClick={() => { setEditConf(true) }}>Modifier </Button>
                        </div>)
                    }
                    < br />
                </div>
            </div>
            {!monthIsActive
                && moment(createdDate?.toDate()).startOf('month').format('DD-MM-YYYY') != moment().startOf('month').format('DD-MM-YYYY')
                && (<div className="card-conf" id="#facture">
                    <h2 style={{ textAlign: 'left' }}> Activation <small>tyndee</small>
                        <br />
                        <Col md="12">
                            <Form.Control
                                value={monthNumber}
                                as="select"
                                onChange={event => { setMonthNumber(event.target.value) }} required>
                                <option value={1}>1 mois</option>
                                <option value={3}>3 mois</option>
                                <option value={6}>6 mois</option>
                                <option value={12}>12 mois</option>
                            </Form.Control>
                        </Col>
                        <small>Du {moment().startOf('month').format('DD-MM-YYYY')} au {moment().startOf('month').add(monthNumber, 'M').format('DD-MM-YYYY')}</small>
                    </h2>
                    <div className="card-conf-heading">
                        <small> Formule :</small>
                        <Form.Control
                            value={formula}
                            as="select"
                            onChange={event => { setFormula(event.target.value) }} required>
                            <option value='basic'>Basic</option>
                            <option value='premium'>Premium</option>
                        </Form.Control>
                    </div>
                    <div className="card-conf-heading">
                        <small> Nombre d'employés :</small>
                        <br />
                        <span className="nombre-employee">Le nombre maximun d'employés que vous pourrez créer dans la période.</span>
                    </div>
                    <div className="input">
                        <input type="number" value={employeeNumber} className="input-field" onChange={event => { setEmployeeNumber(event.target.value) }} required />
                    </div>

                    <div className="card-conf-heading">
                        <small>  Coût total  :</small>  <br />
                        {tyndeeCost()} Francs CFA
                    </div>

                    <div className="action-finish">
                        <button className="action-button-finish" onClick={() => goToPay()}>Payer</button>
                    </div>
                    <div className="card-conf-cut">
                        <h5 style={{ color: 'red' }}>Vous devez activer ce mois pour continuer à utiliser la plateforme.</h5>
                    </div>
                </div>)}

            {monthIsActive && (<div className="card-conf text-center" id="#facture">
                <h2> Activation <small style={{ color: "green" }}>Réussie avec succès</small>
                    <br />
                    <small>Période du {moment(currentActivatedMonth.start_date.toDate()).format('DD-MM-YYYY')} au {moment(currentActivatedMonth.end_date.toDate()).format('DD-MM-YYYY')}</small>
                </h2>

                <h1 style={{ color: 'green', fontSize: '4em' }}>
                    <BsCheckAll />
                </h1>
                <small>{currentActivatedMonth?.employee_number} employés </small>
            </div>)}

            <div className="action-refresh">
                <Button style={{ color: '#1f3e76', fontWeight: 'bold' }} className="action" onClick={() => refreshPage()
                }> <i className="nc-icon nc-refresh-02"></i> Rafraichir</Button>
            </div>
            <div className="card-conf-cut">
                <h5>Réduction de 35% pour plus de 50 employés </h5>
            </div>
            <hr></hr>
            <div className="stats" style={{ textAlign: 'center' }}>
                L'application mobile est disponible sur <i className="fab fa-app-store mr-1"></i>
                <a href="https://apps.apple.com/mn/app/tyndee/id1579581312"> AppStore..</a>
                et sur <i className="fab fa-google-play"></i>
                <a href="https://play.google.com/store/apps/details?id=com.tyndee&gl=SN"> PlayStore..</a>
            </div>
        </div>
    )
}

export default Configuration;