import React, { useState, useEffect } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import EmployeeService from "../components/service/employee.service";
import timetableService from "../components/service/timetable.service";
import { firebase_app } from "../components/config/firebase";
import { useNavigate, useParams } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { notify } from "../components/service/notification.service";
import configService from "../components/service/config.service";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
//import EmployeeCSV from "../components/config/employeeCSV";
import siteService from "../components/service/site.service";
import leaveService from "../components/service/leave.service";
import { Helmet } from "react-helmet";
import QRCode from 'qrcode.react';
import { BsPencilSquare } from "react-icons/bs";
import { AiFillLeftCircle } from "react-icons/ai";

function EmployeeDetail() {

  const [name, setName] = useState('');
  const [num, setNum] = useState('');
  const [code, setCode] = useState(null);
  const [matricule, setMatricule] = useState('');
  const [job, setJob] = useState('');
  const [isPointer, setIsPointer] = useState(false);
  const [isMobil, setIsMobil] = useState(false);
  const [defaultTimetable, setDefaultTimetable] = useState('');
  const [uid, setUid] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [haveData, setHaveData] = useState(false);
  const [show, setShow] = useState(false);
  const [site, setSite] = useState('');
  const [timetables, setTimetables] = useState([]);
  const [sites, setSites] = useState([]);
  const [acceptedLeaveNum, setAcceptedLeaveNum] = useState('');
  const [annualLeave, setAnnualLeave] = useState(24);
  const [level, setLevel] = useState('');
  const [notInPlanning, setNotInPlanning] = useState(false);

  const getInfos = async () => {
    setHaveData(true);
    firebase_app.auth().onAuthStateChanged(async function (user) {
      if (user) {
        const userUid = user.uid;
        await configService.checkMonthIsActive(navigate, userUid);
        const timetableList = await timetableService.getTimetable(userUid);
        timetableList ? setTimetables(timetableList) : setTimetables(null);
        const siteList = await siteService.getSite(user.uid);
        siteList ? setSites(siteList) : setSites([])
        setUid(userUid);
        if (id) {
          const currentEmployee = await EmployeeService.getEmployeeByID(id);
          setName(currentEmployee?.name);
          setNum(currentEmployee?.num);
          setMatricule(currentEmployee?.matricule);
          setJob(currentEmployee?.job);
          setIsMobil(currentEmployee?.isMobil ?? false);
          setIsPointer(currentEmployee?.isPointer ?? false);
          setCode(currentEmployee?.code ?? null);
          setSite(currentEmployee?.siteID ?? '');
          setDefaultTimetable(currentEmployee?.defaultTimetable ?? '');
          setAnnualLeave(currentEmployee?.annualLeave ?? 24);
          setNotInPlanning(currentEmployee?.notInPlanning ?? false);
          setLevel(currentEmployee?.level);
          const acceptedLeaveNumber = await leaveService.getLeaveNum(id);
          acceptedLeaveNumber ? setAcceptedLeaveNum(acceptedLeaveNumber) : setAcceptedLeaveNum(0);
        }
      }
    })
  }

  const refreshPage = () => {
    window.location.reload();
  }


  useEffect(() => {
    if (haveData == false) getInfos();
  })


  const downloadQR = () => {
    const canvas = document.getElementById("qrcode_employee");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrcode_employee" + num.replace('+', '_') + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

  };


  const createOrUpdateEmployee = async (continu = '') => {

    const newEmployee = {
      "name": name,
      "num": num,
      "createdBy": uid,
      "createDate": new Date,
      "matricule": matricule,
      "isPointer": isPointer,
      "isMobil": isMobil,
      "siteID": site,
      "defaultTimetable": defaultTimetable,
      "job": job,
      "code": code,
      "annualLeave": annualLeave,
      "notInPlanning": notInPlanning,
      "level": level
    }

    if (id) {
      EmployeeService.update(id, newEmployee).then((res) => {
        notify('Employé modifié avec succés', "tc", 2)
        if (continu != '') refreshPage();
        else navigate("/admin/employees");
      }).catch((error) => {
        console.log(error.message);
      })
    }

    else {
      const canCreate = await EmployeeService.checkCanCreate(uid, num);
      if (canCreate == false) return

      EmployeeService.create(newEmployee).then((res) => {
        notify('Employé créé avec succés', "tc", 2)
        if (continu != '') refreshPage();
        else navigate("/admin/employees");
      }).catch((error) => {
        console.log(error.message);
      })
    }
  }

  const deleteEmployee = async (id) => {
    setShow(false);
    EmployeeService.delete(id).then(() => {
      notify('Employé supprimer avec succés', "tc", 2);
      navigate("/admin/employees");
    })
  }

  const displayTimetablesOption = () => {
    return timetables?.map((timetable) => {
      return (<option key={site.id} value={timetable.id}>{timetable.data.name}</option>)
    });
  }

  const displaySiteOption = () => {
    return sites?.map((site) => {
      return (<option key={site.id} value={site.id}>{site.data.name}</option>)
    });
  }


  return (
    <>
      <Helmet>
        <title>Tyndee - Employé</title>
      </Helmet>
      <Container fluid>

        <Modal show={show}>
          <Modal.Header>
            <Modal.Title>Suppression</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Etes-vous sur de vouloir supprimer l'employé ?</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)} >Annuler</Button>
            <Button variant="danger" onClick={() => deleteEmployee(id)}>Oui, je suis sûr</Button>
          </Modal.Footer>
        </Modal>
        {//<EmployeeCSV />
        }
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h3">
                <Button className="action-button-save" variant="info" onClick={() => navigate(-1)}> <AiFillLeftCircle /> Retour</Button>
                  Employé
                  {id && (<Button className="action-button-cancel" style={{ color: '#1f3e76' }}
                    onClick={() => navigate('/admin/employees/contract/' + id)}>
                    <BsPencilSquare /> Contrat
                  </Button>)}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Nom <span className="required">*</span></label>
                        <Form.Control
                          defaultValue={name}
                          autoComplete="name"
                          alt="name"
                          placeholder="Nom complet"
                          type="text"
                          onChange={event => { setName(event.target.value) }} required
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="5">
                      <Form.Group>
                        <label htmlFor="exampleInputEmail1">
                          Telephone <span className="required">*</span>
                        </label>
                        <PhoneInput
                          country={'sn'}
                          disabled={id ? true : false}
                          autoFormat={false}
                          inputStyle={{
                            paddingTop: '19px',
                            paddingBottom: '19px',
                            width: '100%'
                          }}
                          value={num}
                          autoComplete="tel"
                          onChange={(value) => { setNum('+' + value) }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="7">
                      <Form.Group>
                        <label>Matricule</label>
                        <Form.Control
                          defaultValue={matricule}
                          placeholder="Matricule"
                          type="text"
                          autoComplete="on"
                          onChange={event => { setMatricule(event.target.value) }}>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>Code</label>
                        <Form.Control
                          defaultValue={code ?? num.slice(-4)}
                          placeholder="Code"
                          type="number"
                          autoComplete="on"
                          onChange={event => { setCode(event.target.value) }}>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>Poste</label>
                        <Form.Control
                          defaultValue={job}
                          placeholder="Nature du poste"
                          type="text"
                          onChange={event => { setJob(event.target.value) }}>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>Horaire Fixe</label>
                        <Form.Control
                          value={defaultTimetable}
                          placeholder="Horaire"
                          as="select"
                          onChange={event => { setDefaultTimetable(event.target.value) }} required>
                          <option value=''> </option>
                          {displayTimetablesOption()}
                        </Form.Control>

                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                      <Form.Group>
                        <label>Pointeur</label>
                        <Form.Control
                          checked={isPointer}
                          type="checkbox"
                          onChange={event => { setIsPointer(!isPointer) }}>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                      <Form.Group>
                        <label>Mobile</label>
                        <Form.Control
                          checked={isMobil}
                          type="checkbox"
                          onChange={event => { setIsMobil(!isMobil) }}>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="7">
                      <Form.Group>
                        <label>Site ou lieux de travail</label>
                        <Form.Control
                          value={site}
                          placeholder="Horaire"
                          as="select"
                          onChange={event => { setSite(event.target.value) }} required>
                          <option value={''}> </option>
                          {displaySiteOption()}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="5">
                      <Form.Group>
                        <label>Masquer dans le planning</label>
                        <Form.Control
                          checked={notInPlanning}
                          type="checkbox"
                          onChange={event => { setNotInPlanning(!notInPlanning) }}>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="5">
                      <Form.Group>
                        <label>Congé annuel</label>
                        <Form.Control
                          defaultValue={annualLeave}
                          placeholder="Congé"
                          type="text"
                          autoComplete="on"
                          onChange={event => { setAnnualLeave(event.target.value) }}>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>Congés pris</label>
                        <Form.Control
                          value={acceptedLeaveNum}
                          disabled={true}
                          type="number">
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                      <Form.Group>
                        <label>LEVEL</label>
                        <Form.Control
                          defaultValue={level}
                          placeholder="LEVEL"
                          type="number"
                          onChange={event => { setLevel(event.target.value) }}>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button className="action-button-save" variant="info" onClick={() => createOrUpdateEmployee()}>Enregistrer</Button>

                  {id ?
                    (<Button className="action-button-cancel" onClick={() => setShow(true)}>Supprimer </Button>) :
                    (<Button className="action-button-save" variant="info" onClick={() => createOrUpdateEmployee('continue')}> Enregistrer et continuer </Button>)}


                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <div className="card-image" >
              </div>
              <Card.Body>
                <div className="author">
                  <a href="#pablo" onClick={downloadQR} >
                    <QRCode
                      id="qrcode_employee"
                      value={uid && num ? uid.substr(uid.length - 9) + ":" + num : ''}
                      size={200}
                      level={"H"}
                      includeMargin={true}
                    />`
                    {num && (<h5 className="title">Télécharger qrcode</h5>)}
                  </a>
                </div>
                <p className="description text-center">
                  Qrcode pour le badge
                </p>
              </Card.Body>
              <hr></hr>
              <div className="button-container mr-auto ml-auto">
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-facebook-square"></i>
                </Button>
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-twitter"></i>
                </Button>
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-google-plus-square"></i>
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EmployeeDetail;