import React, { useEffect, useState } from "react";
import ReadCSV from "../components/config/employeeCSV";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Form,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Modal
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PointingCSV from "../components/config/pointingCSV";
import { firebase_app } from "../components/config/firebase";
import pointingService from "../components/service/pointing.service";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { ExportToExcel } from "../components/config/exportToExcel";
import configService from "../components/service/config.service";
import planningFunctionService from "../components/service/planning-function.service";

import { FcCheckmark, FcSearch } from "react-icons/fc";
import { FcGenericSortingDesc } from "react-icons/fc";
import Select from 'react-select'

import moment from 'moment';
import "moment/locale/fr";
import timetableService from "../components/service/timetable.service";
import { FcNumericalSorting12 } from "react-icons/fc";
import { Helmet } from "react-helmet";
import siteService from "../components/service/site.service";
import { AiOutlineBorder, AiOutlineCheckSquare } from "react-icons/ai";
import employeeService from "../components/service/employee.service";


const auth = firebase_app.auth();

function PointingList() {

    const [pointingResult, setPointingResult] = useState(null);
    const [pointingByEmployee, setPointingByEmployee] = useState(null);
    const [employees, setEmployees] = useState(null);

    const [groupValue, setGroupValue] = useState('');

    const [displayFilter, setDisplayFilter] = useState(false);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [pointingChosed, setPointingChosed] = useState(null);
    const [haveData, setHaveData] = useState(false);
    const [groupPointing, setGroupPointing] = useState(false);
    const [dateFilter, setDateFilter] = useState(moment().toDate());
    const [uid, setUid] = useState(null);
    const [searchByNameResult, setSearchByNameResult] = useState(null);
    const [employeesToDisplay, setEmployeesToDisplay] = useState(null);
    const [inASC, setInASC] = useState(true);
    const [outASC, setOutASC] = useState(true);
    const [employeeSelected, setEmployeeSelected] = useState([]);
    const [sites, setSites] = useState(null);
    const [siteSelected, setSiteSelected] = useState([]);
    const [pointingMissing, setPointingMissing] = useState(false);

    const [startDate, setStartDate] = useState(moment().startOf('day').toDate());
    const [endDate, setEndDate] = useState(moment().endOf('day').toDate());

    const LIMIT_EMP = 10;
    const LIMIT_POINT = 50;
    const LIMIT_RESULT = 20;

    const [page, setPage] = useState(0);



    const getPointings = async () => {
        setHaveData(true);
        firebase_app.auth().onAuthStateChanged(async function (user) {
            if (user) {
                await configService.checkMonthIsActive(navigate, user.uid);
                const employeeList = await employeeService.getEmployee(user.uid);
                setEmployees(employeeList);
                const siteList = await siteService.getSite(user.uid);
                siteList ? setSites(siteList) : setSites([]);
                await getEmployeePointing(user.uid, page, moment(dateFilter).startOf('day').toDate(), moment(dateFilter).endOf('day').toDate(), employeeList);
                setUid(user.uid);
            } else {
                console.log('Not connected');
                navigate('/login');
            }
        });
    }

    useEffect(() => {
        if (haveData == false) {
            getPointings();
        }
    })

    const goToDetail = (id) => {
        navigate('/admin/employees/' + id)
    }

    const filterPointing = async (startDate, endDate) => {
        getPointingPerPage(uid, null, startDate, endDate);
    }

    const searchByEmployeeName = async (employeeList, startDate, endDate) => {
        setEmployeeSelected(employeeList)
        employeeList.length > 0 ?
            await getEmployeePointing(uid, 0, startDate, endDate, employeeList, true) :
            await getEmployeePointing(uid, 0, startDate, endDate, employees);
    }

    const getEmployeePointing = async (uid, page, startDate, endDate, employees, isSearch = false) => {
        setPointingMissing(false);
        const employeePointingList = isSearch ? employees.map(emp => { return emp.value }) : employees;
        let limitNumb = Math.min((page + 1) * LIMIT_EMP * 2, employeePointingList?.length);
        let employeeIDList1 = [];
        let employeeIDList2 = [];
        let employeeList = [];

        for (let i = page * LIMIT_EMP * 2; i < limitNumb; i++) {
            if (employeeList.length < 10) {
                employeeIDList1.push(employeePointingList[i].id);
            }
            else {
                employeeIDList2.push(employeePointingList[i].id);
            }
            employeeList.push(employeePointingList[i]);
        }

        const pointingList1 = employeeIDList1.length > 0 ?
            await pointingService.getPointingByDateEmpl(uid, startDate, endDate, employeeIDList1) :
            [];
        const pointingList2 = employeeIDList2.length > 0 ?
            await pointingService.getPointingByDateEmpl(uid, startDate, endDate, employeeIDList2) :
            [];
        if (employeeList.length > 0) {
            setEmployeesToDisplay(employeeList);
            setPointingByEmployee([...pointingList1, ...pointingList2]);
            setPage(page);
        }
    }

    const prepareDataToDowload = () => {
        var dataToDownload = [];
        if (groupPointing) {
            employeesToDisplay?.map((employee) => {
                const pointing = pointingService.groupPointingByEmployee(pointingByEmployee)?.filter(emp => emp?.id == employee.id)[0];
                const data = pointing?.data
                const workDuration = pointingService.totalWorkDuration(data)
                if (data?.pointingTypeIN != 'login') {
                    dataToDownload.push({
                        'Employé': employee?.data?.name,
                        'Durée total': workDuration,
                        'Durée moyenne': pointingService.calculMean(workDuration, data?.length),
                        'Début': moment(startDate).format("DD/MM/YYYY"),
                        'Fin': moment(endDate).format("DD/MM/YYYY")
                    })
                }
            })
        } else {
            employeesToDisplay?.map((employee) => {
                const pointing = pointingByEmployee?.filter(employeePointing => employeePointing?.data.employeeID == employee.id)[0];
                const data = pointing?.data;
                const site = sites?.filter(site => site.id == employee?.data?.siteID)[0];
                dataToDownload.push({
                    'Employé': employee?.data?.name,
                    'site': site?.data?.name,
                    'Date': moment(startDate).format("DD/MM/YYYY"),
                    'Début': data?.in,
                    'Fin': data?.out,

                })
            })
        }
        return dataToDownload;
    }


    const displayGroupingList = () => {
        return employeesToDisplay?.map((employee) => {
            const pointing = pointingService.groupPointingByEmployee(pointingByEmployee)?.filter(emp => emp?.id == employee.id)[0];
            const data = pointing?.data;
            const workDuration = data ? pointingService.totalWorkDuration(data) : 0
            const workDurationMean = data ? pointingService.calculMean(workDuration, data.length) : 0

            return (
                <tr onClick={() => { }} key={employee.id}>
                    <td> {employee.data.name}</td>
                    <td>{workDuration}</td>
                    <td>{workDurationMean}</td>
                    <td>{moment(startDate).format("DD/MM/YYYY")}</td>
                    <td>{moment(endDate).format("DD/MM/YYYY")}</td>
                </tr>
            )
        })
    }

    const displayEmployeePointingList = () => {
        return employeesToDisplay?.map((employee) => {
            const pointing = pointingByEmployee?.filter(employeePointing => employeePointing?.data.employeeID == employee.id)[0];
            const data = pointing?.data;
            const site = sites?.filter(site => site.id == employee?.data?.siteID)[0];
            return (
                <tr onClick={() => { }} key={employee.id}>
                    <td>{employee.data.name}</td>
                    <td>{site?.data?.name}</td>
                    <td className='pointer color-tblue' onClick={() => { setPointingChosed(pointing); setShowModal(true) }}> {moment(dateFilter).format("DD/MM/YYYY")} </td>
                    <td><a style={{ color: '#122546' }} href={"http://maps.google.com/maps?q=loc:" + data?.locationIN?.lat + "," + data?.locationIN?.long} target="_blank">{data?.in} </a> </td>
                    <td>{data?.out != data?.in ? (<a style={{ color: '#122546' }} href={"http://maps.google.com/maps?q=loc:" + data?.locationOUT?.lat + "," + data?.locationOUT?.long} target="_blank"> {data?.out} </a>) : ''}  </td>
                </tr>
            )
        })
    }

    const getStartDay = (date = dateFilter) => {
        if (groupPointing) return startDate;
        return moment(date).startOf('day').toDate();
    }

    const getEndDay = (date = dateFilter) => {
        if (groupPointing) endDate;
        return moment(date).endOf('day').toDate();
    }

    const displaySites = () => {
        return sites?.map((site) => {
            return (
                <div className='card-title-m9 pointer' onClick={async () => {
                    let siteList = siteSelected;
                    if (siteList.includes(site.id)) {
                        siteList = siteList.filter(item => item !== site.id)
                    } else {
                        siteList.push(site.id);
                    }
                    setSiteSelected(siteList);
                    const employeeList = siteList.length > 0 ?
                        await employeeService.getEmployeeBySite(siteList) :
                        await employeeService.getEmployee(uid);
                    employeeList ? setEmployees(employeeList) : setEmployees([]);
                    getEmployeePointing(uid, 0, getStartDay(), getEndDay(), employeeList);
                }}
                    key={site.id}>
                    {siteSelected.includes(site.id) ? (<AiOutlineCheckSquare />) : (<AiOutlineBorder />)} {site.data.name}
                </div>)
        })
    }

    const pageResult = () => {
        return (
            <h4>Page {page + 1} : {employeesToDisplay?.length} éléments </h4>
        )
    }

    const displayDetailPointing = () => {
        if (pointingChosed) {
            const data = pointingChosed.data;
            const employeeName = pointingService.getEmployeeName(employees, data.employeeID);
            const employeeNum = pointingService.getEmployeeNum(employees, data.employeeID);
            var pointinIn = '';
            var pointinOut = '';
            if (data.pointingTypeIN == 'location_pointing') pointinIn = 'Géolocalisation';
            if (data.pointingTypeIN == 'qrcode_pointing') pointinIn = 'Scan QR Code';
            if (data.pointingTypeIN == 'qrcode_managing') pointinIn = 'Carte';

            if (data.pointingTypeOUT == 'location_pointing') pointinOut = 'Géolocalisation';
            if (data.pointingTypeOUT == 'qrcode_pointing') pointinOut = 'Scan QR Code';
            if (data.pointingTypeOUT == 'qrcode_managing') pointinOut = 'Carte';

            return (
                <div>
                    <h4> {employeeName} {employeeNum}</h4>
                    <h4>Date : {pointingService.formatDate(data.day)}</h4>
                    <h4>Pointage : {data.in} -  {data.out != data.in ? data.out : ''}</h4>
                    <h4> Localisation : <a href={"http://maps.google.com/maps?q=loc:" + data.locationIN.lat + "," + data.locationIN.long} target="_blank">début</a> , {data.locationOUT && data.out != data.in && (<a href={"http://maps.google.com/maps?q=loc:" + data.locationOUT.lat + "," + data.locationOUT.long} target="_blank">fin </a>)}</h4>
                    <h4> Type de pointage : {pointinIn} - {data.out != data.in ? pointinOut : ''}</h4>
                    <h4> Pointer : {data.pointerIN ?? 'Téléphone'} -  {data.out && data.out != data.in ? data.pointerOUT ?? 'Téléphone' : ''}</h4>
                </div>
            )
        }
    }

    const getPointingMissing = async (date) => {
        const start = getStartDay(date);
        const end = getEndDay(date);
        setGroupPointing(false);
        setPointingMissing(!pointingMissing);
        const employeeList = siteSelected.length > 0 ?
            await employeeService.getEmployeeBySite(siteSelected) :
            await employeeService.getEmployee(uid);
        if (!pointingMissing) {
            const dailyPointing = await pointingService.getAllPointingByDate(uid, start, end);
            var missingEmployee = [];
            employeeList.map(employee => {
                const employeePointing = dailyPointing.filter(pointing => pointing.data.employeeID == employee.id);
                if (employeePointing.length == []) missingEmployee.push(employee);
            });
            setEmployeesToDisplay(missingEmployee);
        }
        else {
            getEmployeePointing(uid, 0, start, end, employeeList);
        }
    }

    return (
        <>
            <Helmet>
                <title>Tyndee - Pointage</title>
            </Helmet>
            <Container fluid>
                <Modal
                    className="modal-primary"
                    show={showModal}
                    onHide={() => setShowModal(false)}
                >
                    <Modal.Header className="justify-content-center">
                        <div className="modal-profile">
                            <i className="nc-icon nc-tap-01"></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        {displayDetailPointing()}
                    </Modal.Body>
                    <div className="modal-footer">
                        <Button
                            className="btn-simple"
                            type="button"
                            variant="link"
                            onClick={() => setShowModal(false)}
                        >
                            Retour
                        </Button>
                    </div>
                </Modal>
                <Row>
                    <Col md="12">
                        <Card className="card-plain table-plain-bg">
                            <Card.Header>
                                <Card.Title as="h3">Pointage
                                    <ExportToExcel apiData={prepareDataToDowload()} fileName={'Liste_des_pointages_' + moment(endDate).format('LL')} />
                                </Card.Title>
                                <hr></hr>
                                <Col md={12}>
                                    <Col md={12} style={{ display: 'flex' }}>
                                        <div className="search-input">
                                            <Select
                                                placeholder="Rechercher"
                                                value={employeeSelected}
                                                onChange={(emp) => searchByEmployeeName(emp, getStartDay(), getEndDay())}
                                                options={planningFunctionService.EmployeeListOption(employees)}
                                                isMulti={true}
                                            />
                                        </div>
                                        <Col style={{ marginRight: '3px' }}>
                                            <button type="button" className="btn" style={{ border: 0 }}>
                                                <FcSearch />
                                            </button>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Button className="action-button-cancel" onClick={() => getPointings()}> <i className="nc-icon nc-refresh-02"></i> Rafraichir</Button>
                                    </Col>
                                </Col>
                                {pageResult()}
                            </Card.Header>
                            <Row>
                                <Col md="3">
                                    <Card.Body className="table-full-width table-responsive px-0 element-list-display" style={{ marginRight: 0 }}>
                                        <Row>
                                            <Col>
                                                <Card.Title as="h4" className='card-title-m9'>Filtrer</Card.Title>
                                                <Row>
                                                    <Col md={12}> <Card.Title as="h5" className='card-title-m9'>Date </Card.Title>
                                                        <DatePicker dateFormat="dd/MM/yyyy" className="dateFilter" selected={dateFilter} onChange={(date) => {
                                                            setDateFilter(date);
                                                            setGroupPointing(false);
                                                            getEmployeePointing(uid, 0, getStartDay(date), getEndDay(date), employees);
                                                        }} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Card.Title as="h5" className='card-title-m9'>Absence</Card.Title>
                                                    <div className='card-title-m9 pointer color-danger' onClick={async () => {
                                                        getPointingMissing(dateFilter);
                                                    }}>{pointingMissing ? (<AiOutlineCheckSquare />) : (<AiOutlineBorder />)} Afficher les absents</div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Card.Title as="h4" className='card-title-m9'>Grouper</Card.Title>

                                                <Row>
                                                    <Col md={12}> <Card.Title as="h5" className='card-title-m9'>du </Card.Title>
                                                        <DatePicker dateFormat="dd/MM/yyyy" className="dateFilter" selected={startDate} onChange={(date) => {
                                                            if (date < endDate) {
                                                                setGroupPointing(true);
                                                                setStartDate(date);
                                                                getEmployeePointing(uid, 0, date, endDate, employees);
                                                            }
                                                        }} />
                                                    </Col>
                                                    <Col md={12}>
                                                        <Card.Title as="h5" className='card-title-m9'> au </Card.Title>
                                                        <DatePicker dateFormat="dd/MM/yyyy" className="dateFilter" selected={endDate} onChange={(date) => {
                                                            if (date > startDate) {
                                                                setGroupPointing(true);
                                                                setEndDate(date);
                                                                getEmployeePointing(uid, 0, startDate, date, employees);
                                                            }
                                                        }} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Card.Title as="h5" className='card-title-m9'>Sites ou Lieux de travail</Card.Title>
                                                {displaySites()}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Col>
                                <Col md="9">
                                    <Card.Body className="table-full-width table-responsive px-0 element-list-display" style={{ marginLeft: 0 }}>
                                        {!groupPointing && (<Table className="table-hover table-responsive">
                                            <thead>
                                                <tr>
                                                    <th className="border-0">Employé</th>
                                                    <th className="border-0">Site</th>
                                                    <th className="border-0">Date</th>
                                                    <th className="border-0 pointer"><FcNumericalSorting12 /> Début </th>
                                                    <th className="border-0 pointer"><FcNumericalSorting12 /> Fin</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displayEmployeePointingList()}
                                            </tbody>
                                        </Table>)}
                                        {groupPointing && (<Table className="table-hover">
                                            <thead>
                                                <tr>
                                                    <th className="border-0">Employé</th>
                                                    <th className="border-0">Durée total</th>
                                                    <th className="border-0">Durée moyenne</th>
                                                    <th className="border-0">Début</th>
                                                    <th className="border-0">Fin </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displayGroupingList()}
                                            </tbody>
                                        </Table>)}
                                        <Row>
                                            <Col xs={11}>
                                                <Button style={{ marginTop: 25, marginBottom: 15, color: '#1f3e76' }} className="action-button-cancel"
                                                    onClick={() => {
                                                        getEmployeePointing(uid, page + 1, getStartDay(), getEndDay(), employees);
                                                    }}>
                                                    Suivant
                                                    &raquo;
                                                </Button>
                                                <Button style={{ marginTop: 25, marginBottom: 15, color: '#1f3e76' }} className="action-button-cancel"
                                                    onClick={() => {
                                                        if (page > 0) getEmployeePointing(uid, page - 1, getStartDay(), getEndDay(), employees);
                                                    }}>
                                                    &laquo;
                                                    Précédant
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default PointingList;
