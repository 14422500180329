import { firebase_app } from "../../components/config/firebase";
import moment from 'moment';

const db = firebase_app.firestore();

class ConfigService {

  async getUserByID(id) {
    const snapshot = await db.collection("user").doc(id).get()
    return snapshot.data();
  }

  create(user) {
    return db.collection('user').add(user)
  }

  update(id, value) {
    return db.collection('user').doc(id).update(value);
  }

  delete(id) {
    return db.collection('user').doc(id).delete();
  }

  strToDate(date) {
    return new Date(date.split('-')[2], date.split('-')[1], date.split('-')[0])
  }

  async getActivatedMonth(uid) {
    const snapshot = await db.collection("activated_period")
      .where('user_id', '==', uid)
      .where('end_date', '>=', new Date())
      .get()
    return snapshot.docs?.map(doc => { return doc.data() });
  }

  async getActivatedMonthonDate(uid, date) {
    const snapshot = await db.collection("activated_period")
      .where('user_id', '==', uid)
      .where('end_date', '>=', new Date(date))
      .get()
    return snapshot.docs?.map(doc => { return doc.data() });
  }

  async checkMonthIsActive(navigate, uid) {
    const userData = await this.getUserByID(uid);
    const firstDay = moment().startOf('month').format('DD-MM-YYYY');
    const createdDate = userData?.created_date;

    if (!createdDate) {
      navigate('/admin/configuration');
    }
    else {
      if (moment(createdDate?.toDate()).startOf('month').format('DD-MM-YYYY') != firstDay && new Date().getDate() > 5) {
        const activatedMonth = await this.getActivatedMonth(uid);
        if (!activatedMonth[0]) {
          navigate('/admin/configuration');
        }
      }
    }
  }
}

export default new ConfigService();