import React, { useEffect, useState } from "react";
// react-bootstrap components
import {
    Button,
    Card,
    Form,
    Table,
    Container,
    Modal,
    Row,
    Col,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { firebase_app } from "../components/config/firebase";
import configService from "../components/service/config.service";
import NotificationAlert from "react-notification-alert";
import { notify } from "../components/service/notification.service";
import DatePicker from "react-datepicker";
import moment from 'moment';
import Select from 'react-select'

import { AiFillPlusCircle, AiFillCheckCircle, AiFillMinusCircle } from "react-icons/ai";
import { BsFillTrashFill, BsFillPersonFill } from "react-icons/bs";
import planningFunctionService from "../components/service/planning-function.service";
import employeeService from "../components/service/employee.service";
import pointingService from "../components/service/pointing.service";
import taskService from "../components/service/task.service";
import { Helmet } from "react-helmet";


const auth = firebase_app.auth();

function Task() {

    const [tasks, setTasks] = useState(null);

    const [id, setId] = useState(null);
    const [uid, setUid] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [haveData, setHaveData] = useState(false);

    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [date, setDate] = useState(null);
    const [employeeSelected, setEmployeeSelected] = useState(null);
    const [employees, setEmployees] = useState(null);



    const navigate = useNavigate();


    const getTasks = async () => {
        setHaveData(true);
        firebase_app.auth().onAuthStateChanged(async function (user) {
            if (user) {
                setUid(user.uid);
                await configService.checkMonthIsActive(navigate, user.uid);
                const taskList = await taskService.getTask(user.uid);
                taskList ? setTasks(taskService.sortTasks(taskList)) : setTasks([]);
                const employeeList = await employeeService.getEmployee(user.uid);
                employeeList ? setEmployees(employeeList) : setEmployees([]);
            } else {
                console.log('Not connected');
                navigate('/login');
            }
        });
    }

    const updateTasks = async () => {
        const taskList = await taskService.getTask(uid);
        taskList ? setTasks(taskService.sortTasks(taskList)) : setTasks([]);
    }

    const createOrUpdateTask = async () => {
        const newTask = {
            "createdBy": uid,
            "title": title,
            "desc": desc,
            "date": date,
            "assigned": employeeSelected.value.id,
            "status": 'on_going'
        }

        if (id) {
            taskService.update(id, newTask).then((res) => {
                notify('Modifier avec succés', "tc", 2);
                updateTasks();
            }).catch((error) => {
                console.log(error.message);
            });
        }
        else {
            taskService.create(newTask).then((res) => {
                notify('Créer avec succés', "tc", 2);
                updateTasks();
            }).catch((error) => {
                console.log(error.message);
            });
        }
    }

    const selectToUpdate = (idSelected) => {
        const selectedTask = tasks?.filter(task => task.id == idSelected)[0];
        const currentEmployee = employees?.filter(employee => employee.id == selectedTask.data.assigned)[0] ?? null;
        setId(idSelected);
        setTitle(selectedTask.data.title);
        setDate(selectedTask.data.date.toDate());
        setDesc(selectedTask.data.desc);
        if (currentEmployee) {
            setEmployeeSelected({ 'value': selectedTask.data.assigned, 'label': currentEmployee?.data?.name + ' - ' + currentEmployee?.data?.job });
        }
        setShowModal(true);
    }

    const createTask = () => {
        setId(null);
        setTitle('');
        setDesc('');
        setDate(new Date());
        setEmployeeSelected(null)
        setShowModal(true);
    }

    const getBackgroundColor = (taskId) => {
        if (id == taskId) return '#FFF'
        return 'transparent'
    }

    const deleteTask = (id) => {
        taskService.delete(id).then(() => {
            notify('Supprimer avec succés', "tc", 2);
            updateTasks();
        }).catch((error) => {
            console.log(error.message);
        });
    }

    const doneTask = (id, status) => {
        const newTask = { "status": status }
        taskService.update(id, newTask).then(() => {
            notify('Modifier avec succés', "tc", 2);
            updateTasks();
        }).catch((error) => {
            console.log(error.message);
        });
    }


    useEffect(() => {
        if (haveData == false) getTasks();
    })


    const displayTaskList = () => {
        return tasks?.map((task) => {
            const data = task.data;
            const employeeName = pointingService.getEmployeeName(employees, data.assigned);
            return (
                <tr key={task.id} style={{ backgroundColor: getBackgroundColor(task.id) }}>
                    <td className='pointer color-tblue' onClick={() => selectToUpdate(task.id)}>
                        {pointingService.formatDateTime(data.date.toDate())}
                    </td>
                    <td>
                        <div key={'planning.id'} className='employee-task'>
                            <span className='task-icon'>
                                <BsFillPersonFill color="#9A2462" />
                            </span>
                            <span className='employee-task-name'>
                                {employeeName}
                            </span>

                        </div>
                    </td>
                    <td>{data.title.substring(0, 25) + '.'}</td>
                    <td>{data.desc.substring(0, 35) + '.'}</td>
                    <td>
                        <div key={'planning.id'}
                            className='state-task'>
                            <span className='employee-task-name' >
                                {data.status == 'done' ? "Terminé":  "En cours" }

                            </span>

                            <span className='task-icon pointer' onClick={() => doneTask(task.id, data.status == 'on_going' ? 'done' : 'on_going')}>
                                {data.status == 'done' ?
                                    (<AiFillCheckCircle color="#00CD00" />) :
                                    (<AiFillMinusCircle color="#F1C232" />)}
                            </span>
                        </div>
                    </td>
                    <td>
                        <BsFillTrashFill color="red" className="pointer" onClick={() => deleteTask(task.id)} />
                    </td>
                </tr>
            )
        })
    }

    return (
        <>
        <Helmet>
                <title>Tyndee - Tâche</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="card-plain table-plain-bg">
                            <Card.Header>
                                <Button className='action-button-add' onClick={createTask}>Nouvelle tâche <AiFillPlusCircle /></Button>
                                <Card.Title as="h3">Liste des tâches</Card.Title>
                            </Card.Header>
                            <Modal
                                className="modal-primary"
                                size="lg"
                                show={showModal}
                                onHide={() => setShowModal(false)}>
                                <Modal.Header className="justify-content-center">
                                    <Card.Title as="h3">Tâche</Card.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Col md="12">
                                            <Form>
                                                <Row>
                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>Titre <span className="required">*</span></label>
                                                            <Form.Control
                                                                value={title}
                                                                placeholder="Matin"
                                                                type="text"
                                                                onChange={event => { setTitle(event.target.value) }}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>Date</label>
                                                            <DatePicker dateFormat="dd/MM/yyyy" selected={date} onChange={(date) => {
                                                                setDate(date);
                                                            }} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="12">
                                                        <Form.Group>
                                                            <label style={{ display: 'block' }}>Description  </label>
                                                            <Form.Control
                                                                value={desc}
                                                                as="textarea"
                                                                style={{ height: '80px' }}
                                                                onChange={event => { setDesc(event.target.value) }}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label style={{ display: 'block' }}>Assigné à <span className="required">*</span></label>
                                                            <Select
                                                                placeholder={"Employé"}
                                                                defaultValue={employeeSelected}
                                                                onChange={setEmployeeSelected}
                                                                options={planningFunctionService.EmployeeListOption(employees)}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <div className="modal-footer">
                                    <Button
                                        className="btn-simple"
                                        type="button"
                                        variant="link"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Retour
                                    </Button>
                                    <Button
                                        className="btn-simple"
                                        type="button"
                                        style={{ color: '#ffff', backgroundColor: '#1f3e76' }}
                                        onClick={() => {
                                            if (employeeSelected && title) {
                                                createOrUpdateTask();
                                                setShowModal(false);
                                            }
                                        }}>
                                        Enregistrer
                                    </Button>
                                </div>
                            </Modal>

                            <Card.Body className="table-full-width table-responsive px-0 element-list-display" style={{ tableLayout: 'fixed'}}>
                                <Table className="table-hover" >
                                    <thead>
                                        <tr>
                                            <th className="border-0">Date</th>
                                            <th className="border-0">Assigné</th>
                                            <th className="border-0">Titre</th>
                                            <th className="border-0">Desc.</th>
                                            <th className="border-0">Status </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayTaskList()}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}


export default Task;
