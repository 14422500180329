import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { CSVReader } from 'react-papaparse';
import { CSVLink } from "react-csv";

const buttonRef = React.createRef();

export default class PointingCSV extends Component {


  /*headers = [
    { label: "Nom complet", key: "name" },
    { label: "Numero de telephone", key: "num" },
  ];

  data = [
    { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
    { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
  ];*/


  constructor(props) {
    super(props);
    this.state = { data: null };
  }

  render() {
    const pointingList = this.props.pointingList
    return (
      <>
         {pointingList && (<Button
                style={{
                  padding: 5,
                  margin: 2
                }}>
                <i style={{
                  display: 'inline-block',
                  margin: 5
                }}
                  className="nc-icon nc-cloud-download-93"></i>
                <CSVLink style={{
                  color: '#3472F7',
                }}
                  data={pointingList}>
                  Télécharger CSV
                </CSVLink>
              </Button>)}
      </>
    );
  }
}