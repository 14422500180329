import { firebase_app } from "../../components/config/firebase";
import moment from 'moment';
import "moment/locale/fr";

const db = firebase_app.firestore();

class AppointmentService {

    async getAppointment(uid) {
        if (!uid) return null;
        const snapshot = await db.collection("appointment").where("createdBy", "==", uid).get()
        return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
    }

    async getAppointmentByStatus(uid, status) {
        if (!uid) return null;
        const snapshot = await db.collection("appointment")
            .where('createdBy', '==', uid)
            .where('status', '==', status)
            .get()
        return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
    }



    async getAppointmentType() {
        const snapshot = await db.collection("appointment_type").get()
        return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
    }

    async getAcceptedAppointmentEmpl(employeeID, startDate, endDate) {
        const snapshot = await db.collection("appointment")
            .where('author', '==', employeeID)
            .where('status', '==', 'accepted')
            .where('startDate', '<=', endDate)
            .get()
        const result_query = snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
        const results = result_query.filter(result => result.data.endDate.toDate() >= startDate);
        return results;
    }

    async getAcceptedAppointment(uid, startDate, endDate) {
        const snapshot = await db.collection("appointment")
            .where('createdBy', '==', uid)
            .where('status', '==', 'accepted')
            .where('startDate', '<=', endDate)
            .get()
        const result_query = snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
        const results = result_query.filter(result => result.data.endDate.toDate() >= startDate);
        return results;
    }

    async getAppointmentByDate(uid, date) {
        let dateFilter = new Date(date);
        let start = new Date(dateFilter.setHours(0, 0, 0));
        let end = new Date(dateFilter.setHours(24, 0, 0));
        const snapshot = await db.collection("appointment")
            .where('createdBy', '==', uid)
            .where('startDate', '<', end)
            .where('startDate', '>=', start)
            .get()
        return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
    }

    create(appointment) {
        return db.collection("appointment").add(appointment);
    }

    update(id, value) {
        return db.collection("appointment").doc(id).update(value);
    }

    delete(id) {
        return db.collection("appointment").doc(id).delete();
    }

    sortAppointments(appointments) {
        return appointments.sort(function (a, b) {
            return a.data.date.toDate().getTime() - b.data.date.toDate().getTime()
        });
    }

    calcBusinessDays(startDate, endDate, dayWork) {
        const day = moment(startDate);
        let businessDays = 0;
        while (day.isSameOrBefore(endDate, 'day')) {
            if (dayWork == 6) {
                if (day.day() !== 6) {
                    businessDays++;
                }
            }
            else if (dayWork == 5) {
                if (day.day() !== 0 && day.day() !== 6) {
                    businessDays++;
                }
            }
            else businessDays++;
            day.add(1, 'd');
        }
        return businessDays;
    }

    async getAppointmentNum(id) {
        const startYear = new Date(new Date().getFullYear(), 0, 1);
        const endYear = new Date(new Date().getFullYear(), 12, 31);
        const appointments = await this.getAcceptedAppointmentEmpl(id, startYear, endYear);
        let num = 0;
        for (let appointment of appointments) {
            num += appointment.data.duration;
        }
        return num;
    }

    timeToMomentTime(time) {
        var m = moment();
        m.set({ hour: time?.split(':')[0], minute: time?.split(':')[1], second: 0, millisecond: 0 })
        return m;
    }

}

export default new AppointmentService();