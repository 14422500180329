
import Dashboard from "./views/Dashboard.js";
import EmployeeDetail from "./views/EmployeeDetail.js";
import EmployeeList from "./views/EmployeeList.js";
import TimeTable from "./views/Timetable";
import PointingList from "./views/PointingList";
import Task from "./views/Task.js";

import HourlyPlanning from "./views/HourlyPlanning.js";
import Configuration from "./views/Configuration.js";
import { AiOutlineLineChart, AiOutlineTeam, AiOutlineEnvironment, AiOutlineSetting, AiFillHome, AiFillEnvironment, AiTwotoneEnvironment, AiTwotoneSetting } from "react-icons/ai";
import { BiSun } from "react-icons/bi";
import { FaList } from "react-icons/fa";
import Leave from "./views/Leave.js";
import { BsCalendar2Check, BsCalendar2CheckFill, BsCalendar3Week, BsCalendar3WeekFill, BsFillPeopleFill, BsFillSunFill, BsListTask, BsStack } from "react-icons/bs";
import Appointment from "./views/Appointment.js";
import configService from "./components/service/config.service.js";


const configRoute = [
  {
    path: "/configuration",
    name: "CONFIGS",
    icon: <AiTwotoneSetting />,
    element: <Configuration />,
    layout: "/admin",
  }
]

const defaultRoutes = [
  {
    path: "/dashboard",
    name: "ACCUEIL",
    icon: <AiFillHome />,
    element: <Dashboard />,
    layout: "/admin",
  },
  {
    path: "/pointings",
    name: "POINTAGES",
    icon: <AiTwotoneEnvironment />,
    element: <PointingList />,
    layout: "/admin",
  },
  {
    path: "/employees",
    name: "EMPLOYES",
    icon: <BsFillPeopleFill />,
    element: <EmployeeList />,
    layout: "/admin",
  }
  /*{
    path: "/timetable",
    name: "Horaires",
    icon: "nc-icon nc-watch-time",
    element: <TimeTable,
    layout: "/admin",
  }, 
  */

];

const basicRoutes = [
  ...defaultRoutes,
  ...configRoute
]


export const premiumRoute = [
  ...defaultRoutes,
  {
    path: "/task",
    name: "TACHES",
    icon: <BsListTask />,
    element: <Task />,
    layout: "/admin",
  },
  {
    path: "/appointment",
    name: "RV",
    icon: <BsCalendar2CheckFill />,
    element: <Appointment />,
    layout: "/admin",
  },
  {
    path: "/leaves",
    name: "CONGES",
    icon: <BsFillSunFill />,
    element: <Leave />,
    layout: "/admin",
  },
  {
    path: "/planning",
    name: "PLANNING",
    icon: <BsCalendar3WeekFill />,
    element: <HourlyPlanning />,
    layout: "/admin",
  },
  ...configRoute
];


export default basicRoutes;
