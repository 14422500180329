import React, { useEffect, useState } from 'react';
import '../assets/scss/login.scss';
import { firebase_app, signInWithFacebook, signInWithGoogle } from "../components/config/firebase";
import { FaFacebook } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import ReactLoading from 'react-loading';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';


const firebaseInstance = firebase_app;

export const signInF = (navigate) => {
    signInWithFacebook().then((res) => {
        navigate('/admin/dashboard');
    }).catch((error) => {
        console.log(error);
        console.log(error.message);
    })
}


export const signInG = (navigate) => {
    signInWithGoogle().then((res) => {
        navigate('/admin/dashboard');
    }).catch((error) => {
        console.log(error);
        console.log(error.message);
    })
}

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const signin = async (event) => {
        setLoading(true);
        event.preventDefault();
        try {
            if (firebaseInstance) {
                const user = await firebaseInstance
                    .auth()
                    .signInWithEmailAndPassword(email, password);
                navigate('/admin/dashboard');
            }
        } catch (error) {
            setLoading(false);
            setError(true);
            console.log("error", error);
        }
    };


    return (
        <div className="container">
            <Helmet>
                <title>Tyndee - Login</title>
            </Helmet>
            <div className="card-login">
                <div className="card-login-image">
                    <a
                        href="https://tyndee.com"
                        className="simple-text"> <h2 className="card-login-heading">
                            Tyndee
                            <small>Connecter vous</small>
                        </h2>
                    </a>
                </div>
                <form className="card-login-form" onSubmit={signin}>
                    <div className="input">
                        <input type="text" autoComplete="email" className="input-field" onChange={event => { setEmail(event.target.value) }} required />
                        <label className="input-label">Email</label>
                    </div>
                    <div className="input">
                        <input type="password" className="input-field" onChange={event => { setPassword(event.target.value) }} required />
                        <label className="input-label">Password</label>
                    </div>
                    {loading &&
                        (<div className="spinner">
                            <ReactLoading color={'#1F3E76'} type={'bubbles'} height={'20%'} width={'40%'} />
                        </div>)}
                    <div className="action">
                        <button className="action-button">Connecter</button>
                    </div>
                    <div className="action">
                        <button className="google-button" onClick={() => { signInG(navigate); }}>
                            <FcGoogle className="facebook-icon" />
                            <span>connecter avec Gmail</span>
                        </button>
                    </div>
                    <div className="action">
                        <button className="facebook-button" onClick={() => { signInF(navigate); }}>
                            <FaFacebook className="facebook-icon" />
                            <span>connecter avec Facebook</span>
                        </button>
                    </div>
                    {error && (<div className="card-login-error">
                        <p>Email ou mot de passe incorrect.</p>
                    </div>)}

                    <div className="card-login-regorlog">
                        <p>Vous n'avez pas de compte ? <a href="register">Creer un compte</a></p>
                    </div>
                </form>
                <div className="card-login-info">
                    <p>By signing up you are agreeing to our <a href="#">Terms and Conditions</a></p>
                </div>
            </div>
        </div>
    )
}


export default Login;