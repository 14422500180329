import React, { useEffect, useState } from "react";
// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Table,
  Container,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import TimetableService from "../components/service/timetable.service";
import { useNavigate } from "react-router-dom";
import { firebase_app } from "../components/config/firebase";
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import configService from "../components/service/config.service";
import NotificationAlert from "react-notification-alert";
import { notify } from "../components/service/notification.service";

import { AiFillLeftCircle, AiFillPlusCircle } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { Helmet } from "react-helmet";
import TimePicker from "rc-time-picker";
import appointmentService from "../components/service/appointment.service";
import moment from 'moment';
import "moment/locale/fr";

const auth = firebase_app.auth();

function TimeTable() {

  const [timetables, setTimetables] = useState(null);
  const [name, setName] = useState('Matin');
  const [id, setId] = useState(null);
  const [uid, setUid] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [haveData, setHaveData] = useState(false);
  const [startTime, setStartTime] = useState(moment().format("HH:mm"));
  const [endTime, setEndTime] = useState(moment().format("HH:mm"));


  const navigate = useNavigate();


  const getTimeTables = async () => {
    setHaveData(true);
    firebase_app.auth().onAuthStateChanged(async function (user) {
      if (user) {
        setUid(user.uid);
        await configService.checkMonthIsActive(navigate, user.uid);
        const timetableList = await TimetableService.getTimetable(user.uid)
        timetableList ? setTimetables(TimetableService.sortTimetables(timetableList)) : setTimetables([])
      } else {
        console.log('Not connected');
        navigate('/login');
      }
    });
  }

  const updateTimeTables = async () => {
    const timetableList = await TimetableService.getTimetable(uid)
    timetableList ? setTimetables(TimetableService.sortTimetables(timetableList)) : setTimetables([])
  }


  const createOrUpdateTimetable = async () => {
    const newTimetable = {
      "createdBy": uid,
      "name": name,
      "startTime": startTime,
      "endTime": endTime,
    }

    if (id) {
      TimetableService.update(id, newTimetable).then((res) => {
        notify('Modifier avec succés', "tc", 2);
        updateTimeTables();
      }).catch((error) => {
        console.log(error.message);
      });
    }
    else {
      TimetableService.create(newTimetable).then((res) => {
        notify('Créer avec succés', "tc", 2);
        updateTimeTables();
      }).catch((error) => {
        console.log(error.message);
      });
    }
  }

  const selectToUpdate = (idSelected) => {
    const selectedTimetable = timetables?.filter(timetable => timetable.id == idSelected)[0];
    setId(idSelected);
    setName(selectedTimetable.data.name);
    setStartTime(selectedTimetable.data.startTime);
    setEndTime(selectedTimetable.data.endTime);
    setShowModal(true);
  }

  const createTimetable = () => {
    setId(null);
    setName('Matin');
    setShowModal(true);
  }

  const getBackgroundColor = (timetableId) => {
    if (id == timetableId) return '#FFF'
    return 'transparent'
  }

  const deleteTimetable = (id) => {
    TimetableService.delete(id)
      .then(() => {
        notify('Supprimer avec succés', "tc", 2);
        updateTimeTables();
      }).catch((error) => {
        console.log(error.message);
      });
  }

  const okTime = (panel, time) => {
    console.log(panel);
    time == 'start' ? setStartTime(panel.state.value.format("HH:mm")) : setEndTime(panel.state.value.format("HH:mm"));
  }

  useEffect(() => {
    if (haveData == false) getTimeTables();
  })




  const displayTametableist = () => {
    return timetables?.map((timetable) => {
      const data = timetable.data;
      return (
        <tr key={timetable.id} style={{ backgroundColor: getBackgroundColor(timetable.id) }}>
          <td className='pointer color-tblue' onClick={() => selectToUpdate(timetable.id)} >{data['name']}</td>
          <td>{data['startTime']}</td>
          <td>{data['endTime']}</td>
          <td onClick={() => deleteTimetable(timetable.id)}>
            <BsFillTrashFill color="red" />
          </td>
        </tr>
      )
    })
  }
  return (
    <>
      <Helmet>
        <title>Tyndee - Horaire</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h3">
                  <Button className="action-button-save" variant="info" onClick={() => navigate(-1)}> <AiFillLeftCircle /> Retour</Button>
                  Liste des horaires de travail </Card.Title>
                <Button className='action-button-add' onClick={createTimetable}>Nouvel horaire <AiFillPlusCircle /></Button>
              </Card.Header>
              <Modal
                className="modal-primary"
                show={showModal}
                onHide={() => setShowModal(false)}
              >
                <Modal.Header className="justify-content-center">
                  <h4>Horaire de travail</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col md="12">
                      <Form>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Nom <span className="required">*</span></label>
                              <Form.Control
                                value={name}
                                placeholder="Nom"
                                as="select"
                                onChange={event => { setName(event.target.value) }} required>
                                <option value="Matin">Matin</option>
                                <option value="Soir">Soir</option>
                                <option value="Nuit">Nuit</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <br />
                          <br />
                          <Col md="12">
                            <Row className="mbottom-15">
                              <Col md="6" className="mbottom-15">
                                <Form.Group>
                                  <Row>
                                    <label style={{ display: 'block' }}>Début <span className="required">*</span></label>
                                    <Col xs="3" className="g-datepicker">
                                      <TimePicker defaultValue={appointmentService.timeToMomentTime(startTime)} showSecond={false} onChange={time => setStartTime(time.format("HH:mm"))} minuteStep={15} />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                              <Col md="6">
                                <Form.Group>
                                  <Row>
                                    <label style={{ display: 'block' }}>Fin <span className="required">*</span></label>
                                    <Col xs="3" className="g-datepicker">
                                      <TimePicker defaultValue={appointmentService.timeToMomentTime(endTime)} showSecond={false} minuteStep={15} onChange={time => setEndTime(time.format("HH:mm"))} />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Modal.Body>
                <div className="modal-footer">
                  <Button
                    className="btn-simple"
                    type="button"
                    variant="link"
                    onClick={() => setShowModal(false)}
                  >
                    Retour
                  </Button>
                  <Button
                    className="action-button-save"
                    type="button"
                    variant="link"
                    onClick={() => {
                      if (startTime && endTime && name) {
                        createOrUpdateTimetable();
                        setShowModal(false);
                      }
                    }}
                  >
                    Confirmer
                  </Button>
                </div>
              </Modal>

              <Card.Body className="table-full-width table-responsive px-0 element-list-display">
                <Table className="table-hover">
                  <thead>
                    <tr>
                      <th className="border-0">Nom</th>
                      <th className="border-0">Début</th>
                      <th className="border-0">Fin </th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayTametableist()}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TimeTable;
