
import React, { Component } from "react";
import { useLocation, Route, Routes } from "react-router-dom";

import Sidebar from "../components/Sidebar/Sidebar";

import routes from "../routes.js";

import EmployeeDetail from "../views/EmployeeDetail";
import TimeTable from "../views/Timetable";
import Site from "../views/Site";
import { useNavigate } from "react-router-dom";import { firebase_app } from "../components/config/firebase";
import SiteDetail from "../views/SiteDetail";
import Team from "../views/Team";
import Blocation from "../views/Blocation";
import configService from "../components/service/config.service";
import { premiumRoute } from "../routes";
import Contract from "../views/Contract";


function Admin() {
  const [image, setImage] = React.useState();
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const [menuRoutes, setMenuRoutes] = React.useState(routes);
  const [checkFormula, setCheckFormula] = React.useState(false);
  const location = useLocation();
  const mainPanel = React.useRef(null);

  const navigate = useNavigate();


  const getRouteType = async () => {
    setCheckFormula(true);
    firebase_app.auth().onAuthStateChanged(async function (user) {
      if (user) {
        const userInfos = await configService.getUserByID(user.uid);
        if (userInfos) {
          if (userInfos.formula == 'basic')
            setMenuRoutes(routes);
          else setMenuRoutes(premiumRoute);
        }
      }
    })
  }

  if (!checkFormula) getRouteType();


  const getRoutes = (routes) => {
    return routes?.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          element={prop.element}
          key={key}
        />
      );
    })
  };

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={menuRoutes} />
        <div className="main-panel" ref={mainPanel}>
          <div className="content">
            <Routes>
              {getRoutes(menuRoutes)}
              <Route path="/planning/timetable" element={<TimeTable />} />
              <Route path="/employees/sites" element={<Site />} />
              <Route path="/employees/contract/:id" element={<Contract />} />
              <Route path="/employees/teams" element={<Team />} />
              <Route path="/locations" element={<Blocation />} />
              <Route path="/employees/new-employee" element={<EmployeeDetail />} />
              <Route path="/employees/employee/:id" element={<EmployeeDetail />} />
              <Route path="/new-site" element={<SiteDetail />} />
              <Route path="/site/:id" element={<SiteDetail />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
