import React from 'react';
import Helmet from 'react-helmet';
import { DayPicker } from 'react-day-picker';
import fr from 'date-fns/locale/fr';
import 'react-day-picker/dist/style.css';


import moment from 'moment';
import "moment/locale/fr";

function getWeekDays(weekStart) {
    const days = [weekStart];
    for (let i = 1; i < 7; i += 1) {
        days.push(
            moment(weekStart)
                .add(i, 'days')
                .toDate()
        );
    }
    return days;
}

function getWeekRange(date) {
    var day = moment(date).utcOffset(0);
    day.set({hour:0,minute:0,second:0,millisecond:0});
    return {
        from: moment(day)
            .startOf('isoWeek')
            .toDate(),
        to: moment(day)
            .endOf('isoWeek')
            .toDate(),
    };
}

export default class SelectWeek extends React.Component {
    state = {
        hoverRange: undefined,
        selectedDays: [],
    };

    

    handleDayChange = date => {
        this.setState({
            selectedDays: getWeekDays(getWeekRange(date).from),
        });
        this.props.setWeekSelected(getWeekDays(getWeekRange(date).from));
    };

    handleDayEnter = date => {
        this.setState({
            hoverRange: getWeekRange(date),
        });
    };

    handleDayLeave = () => {
        this.setState({
            hoverRange: undefined,
        });
    };

    handleWeekClick = (weekNumber, days, e) => {
        this.setState({
            selectedDays: days,
        });
    };

    render() {
        const { hoverRange, selectedDays } = this.state;

        const daysAreSelected = selectedDays.length > 0;

        const modifiers = {
            hoverRange,
            selectedRange: daysAreSelected && {
                from: selectedDays[0],
                to: selectedDays[6],
            },
            hoverRangeStart: hoverRange && hoverRange.from,
            hoverRangeEnd: hoverRange && hoverRange.to,
            selectedRangeStart: daysAreSelected && selectedDays[0],
            selectedRangeEnd: daysAreSelected && selectedDays[6],
        };


        return (
            <div className="SelectedWeekExample">
                <DayPicker
                    selected={selectedDays}
                    locale={fr}
                    showWeekNumbers
                    showOutsideDays
                    modifiers={modifiers}
                    onDayClick={this.handleDayChange}
                    onDayMouseEnter={this.handleDayEnter}
                    onDayMouseLeave={this.handleDayLeave}
                    onWeekClick={this.handleWeekClick}
                    weekStartsOn={1}
                    modifiersClassNames={{
                        selectedRange: 'selectedRange',
                        selectedRangeStart: 'selectedRangeStart',
                        selectedRangeEnd: 'selectedRangeEnd',
                        hoverRange: 'hoverRange',
                        weekNumber: 'WeekNumber',
                        today: 'today'
                      }}
                />

                    <style>{`
            .SelectedWeekExample .Month {
              border-collapse: separate;
              font-weight: 400;
            }
            .SelectedWeekExample .WeekNumber {
              outline: none;
            }
            .SelectedWeekExample .rdp {
              outline: none;
              border: 1px solid transparent;
            }
            .SelectedWeekExample .hoverRange {
              background-color: #EFEFEF !important;
            }

            .SelectedWeekExample .selectedRange {
              background-color: #4b6491 !important;
              border-radius: 0!important;
              color: white;
            }

            .SelectedWeekExample .selectedRangeStart {
              background-color: #355183 !important;
              border-left: 1px solid #355183;
              color: white;
            }

            .SelectedWeekExample .selectedRangeEnd {
              background-color: #355183 !important;
              border-right: 1px solid #355183;
              color: white;
            }

            .SelectedWeekExample .selectedRange:not(.outside).selected,
            .SelectedWeekExample .hoverRange:not(.outside).selected {
              border-radius: 0 !important;
              color: white !important;
            }
            .SelectedWeekExample .hoverRange:hover {
              border-radius: 0 !important;
            }
            .today { 
                font-weight: bold;
                font-size: 140%; 
                color: red;
            }
          `}</style>
            </div>
        );
    }
}
