import { firebase_app } from "../config/firebase";

const db = firebase_app.firestore();

class SiteService {

  async getSite(uid) {
    if (!uid) return null;
    const snapshot = await db.collection("site").where('createdBy', '==', uid).get()
    return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
  }

  async getSiteByID(id) {
    const snapshot = await db.collection("site").doc(id).get()
    return snapshot.data();
  }

  create(site) {
    return db.collection('site').add(site)
  }

  update(id, value) {
    return db.collection('site').doc(id).update(value);
  }

  delete(id) {
    return db.collection('site').doc(id).delete();
  }

}

export default new SiteService();