// MyGoogleMaps.js
import React, { Component } from 'react';

import GoogleMapReact from 'google-map-react';

import styled from 'styled-components';

import AutoComplete from './Autocomplete';
import Marker from './Marker';

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

export const GOOGLE_MAP_API_KEY = 'AIzaSyB52umWHHOfIMimY2Iy6jzi354_USD5Yck';


class MyGoogleMap extends Component {


    state = {
        mapApiLoaded: false,
        mapInstance: null,
        mapApi: null,
        geoCoder: null,
        places: [],
        center: [],
        zoom: 13,
        address: '',
        draggable: true,
        lat: null,
        lng: null,
    };

    componentDidMount({ location } = this.props) {
        if (location) {
            this.setState({
                center: [location.lat, location.lng],
                lat: location.lat,
                lng: location.lng
            });
        }
        else this.setCurrentLocation();
    }


    onMarkerInteraction = (childKey, childProps, mouse, { setLocation } = this.props) => {
        this.setState({
            draggable: false,
            lat: mouse.lat,
            lng: mouse.lng
        });
        setLocation({ 'lat': mouse.lat, 'lng': mouse.lng });
    }
    onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
        this.setState({ draggable: true });
    }

    _onChange = ({ center, zoom }) => {
        this.setState({
            center: center,
            zoom: zoom,
        });

    }

    _onClick = (value, { setLocation } = this.props) => {
        this.setState({
            lat: value.lat,
            lng: value.lng
        });
        setLocation({ 'lat': value.lat, 'lng': value.lng });
    }

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });

    };

    addPlace = (place, { setLocation } = this.props) => {
        this.setState({
            places: [place],
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        });
        setLocation({ 'lat': place.geometry.location.lat(), 'lng': place.geometry.location.lng() });
    };

    // Get Current Location Coordinates
    setCurrentLocation({ setLocation } = this.props) {
        if ('geolocation' in navigator) {
            this.setState({ center: [14.7459343, -17.4655227] });
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    center: [position.coords.latitude, position.coords.longitude],
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    zoom: 13
                });
                setLocation({ 'lat': position.coords.latitude, 'lng': position.coords.longitude });
            });
        }
    }

    render() {
        const {
            places, mapApiLoaded, mapInstance, mapApi
        } = this.state;

        const { searchAdress } = this.props;

        return (
            <>
                {mapApiLoaded && searchAdress == true && (<AutoComplete searchType={'address'} map={mapInstance} mapApi={mapApi} addplace={this.addPlace} />)}
                {mapApiLoaded && searchAdress == false && (<AutoComplete searchType={'(regions)'} map={mapInstance} mapApi={mapApi} addplace={this.addPlace} />)}
                <div style={{ height: '45vh', width: '100%', marginTop: 10 }}>
                    <GoogleMapReact
                        center={this.state.center}
                        zoom={this.state.zoom}
                        draggable={this.state.draggable}
                        onChange={this._onChange}
                        onChildMouseDown={this.onMarkerInteraction}
                        onChildMouseUp={this.onMarkerInteractionMouseUp}
                        onChildMouseMove={this.onMarkerInteraction}
                        onChildClick={() => console.log('child click')}
                        onClick={this._onClick}
                        bootstrapURLKeys={{
                            key: GOOGLE_MAP_API_KEY,
                            libraries: ['places', 'geometry', 'localContext'],
                        }}

                        onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}>
                        <Marker
                            text={this.state.address}
                            lat={this.state.lat}
                            lng={this.state.lng}
                        />
                    </GoogleMapReact>
                </div>

                {/*<div className="info-wrapper">
                    <div className="map-details">Latitude: <span>{this.state.lat}</span>, Longitude: <span>{this.state.lng}</span></div>
                    <div className="map-details">Zoom: <span>{this.state.zoom}</span></div>
                    <div className="map-details">Address: <span>{this.state.address}</span></div>
                    </div>*/}
            </>
        );
    }
}

export default MyGoogleMap;