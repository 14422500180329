
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Redirect } from "react-router-dom";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";


import "./assets/css/custom-style.css";
import QRCodeGenerator from "./views/QRcodeGenerator";
import Login from "./views/Login";
import Register from "./views/Register";
import Admin from "./layouts/Admin";
//import FirebaseMessaging from "./components/firebaseMessaging";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(


  <BrowserRouter>
    <Routes>
      <Route path="/admin/*" element={<Admin />} />
      <Route path="/qrcode" element={<QRCodeGenerator />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
    </Routes>
    <NotificationContainer />

  </BrowserRouter>,

);

/*
<FirebaseMessaging />
  */