import { firebase_app } from "../config/firebase";

const db = firebase_app.firestore();

class BlocationService {

  async getBlocation(uid) {
    if (!uid) return null;
    const snapshot = await db.collection("blocation").where('createdBy', '==', uid).get()
    return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
  }

  async getBlocationByID(id) {
    const snapshot = await db.collection("blocation").doc(id).get()
    return snapshot.docs?.map(doc => { return doc.data() });
  }

  create(blocation) {
    return db.collection('blocation').add(blocation)
  }

  update(id, value) {
    return db.collection('blocation').doc(id).update(value);
  }

  delete(id) {
    return db.collection('blocation').doc(id).delete();
  }

}

export default new BlocationService();