
import React, { useEffect, useRef, useState } from "react";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Modal,
    Form,
    Table,
    Container,
    Row,
    Dropdown,
    Col,
} from "react-bootstrap";
import planningFunctionService from "../service/planning-function.service.js";
import Select from 'react-select'
import { FcServices } from "react-icons/fc";
import { Label } from "reactstrap";


function PlanningConfigModal(props) {

    const daysOption = () => {
        return [{ value: 0, label: 'Lundi' }, { value: 1, label: 'Mardi' },
        { value: 2, label: 'Mercredi' }, { value: 3, label: 'Jeudi' }, { value: 4, label: 'Vendredi' },
        { value: 5, label: 'Samedi' }, { value: 6, label: 'Dimanche' },
        ]
    }
    return (
        <Modal
            className="modal-primary"
            show={props.showModal}
            onHide={() => props.setShowModal(false)}>
            <Modal.Body>
                <Button className="action-button-add"
                    onClick={() => props.generatePlanning()}>
                    <FcServices /> Générer
                </Button>
                <Card.Title as="h4">Définir les critères</Card.Title>
                <Col className="pr-1" md="12">
                    <div className="input check-input">
                        <input type="checkbox" defaultChecked={props.criteriaJob} className="form-check-input" onChange={() => props.setCriteriaJob(!props.criteriaJob)} />
                        <label className="form-check-label checkbox-label">Alterner les postes</label>
                    </div>

                    <div className="input-select">
                        <Label>Repos hebdomadaire <span className="required">*</span></Label>
                        <Select
                            placeholder="Repos hebdomadaire *"
                            defaultValue={props.pauseTime}
                            onChange={props.setPauseTime}
                            options={[{ value: 1, label: 'Weekend' }, { value: 2, label: 'Dimanche' }, { value: 3, label: '1 jour/semaine' }]}
                        />
                    </div>

                    <div className="input-select">
                        <Label>Plus de travailleur le matin </Label>
                        <Select
                            placeholder="Plus de travailleur le matin"
                            defaultValue={props.daysPlusWorkMorning}
                            onChange={props.setDaysPlusWorkMorning}
                            options={daysOption()}
                            isMulti={true}
                        />
                    </div>
                    <div className="input check-input">
                        <input type="checkbox" defaultChecked={props.plusBreakEndOfWeek} className="form-check-input" onChange={() => props.setPlusBreakEndOfWeek(!props.plusBreakEndOfWeek)} />
                        <label className="form-check-label checkbox-label">Avoir plus de repos en fin de semaine</label>
                    </div>


                    <div className="input-select">
                        <Label>Employés indisponibles ou en congé</Label>
                        <Select
                            placeholder="Employés indisponibles ou en congés"
                            defaultValue={props.unavailableEmployee}
                            onChange={props.setUnavailableEmployee}
                            options={planningFunctionService.EmployeeListOption(props.employees)}
                            isMulti={true}
                        />
                    </div>

                    <Card.Title as="h4">Employés qui ont des horaires fixes</Card.Title>

                    <div className="input-select">
                        <Label>{"Employés qui travaillent seulement " + props.timetables[0]?.data.name}</Label>
                        <Select
                            placeholder="Employés fixes"
                            defaultValue={props.employeeFixTimetable1}
                            onChange={props.setEmployeeFixTimetable1}
                            options={planningFunctionService.EmployeeListOption(props.employees)}
                            isMulti={true}
                        />
                    </div>

                    <div className="input-select">
                        <Label>{"Employés qui travaillent seulement " + props.timetables[1]?.data.name}</Label>
                        <Select
                            placeholder="Employés fixes"
                            defaultValue={props.employeeFixTimetable2}
                            onChange={props.setEmployeeFixTimetable2}
                            options={planningFunctionService.EmployeeListOption(props.employees)}
                            isMulti={true}
                        />
                    </div>

                    {props.timetables?.length > 2 &&
                        (<>
                            <Card.Title as="h4">Employés qui travaillent la nuit</Card.Title>

                            <div className="input-select">
                                <Label>Employés qui travaillent le jour et la nuit</Label>
                                <Select
                                    placeholder="Employés qui travaillent le jour et la nuit"
                                    defaultValue={props.employeeWorkNight}
                                    onChange={props.setEmployeeWorkNight}
                                    options={planningFunctionService.EmployeeListOption(props.employees)
                                    }
                                    isMulti={true}
                                />
                            </div>

                            <div className="input-select">
                                <Label>Employés qui travaillent seulement la nuit</Label>
                                <Select
                                    placeholder="Employés fixes"
                                    defaultValue={props.employeeFixTimetable3}
                                    onChange={props.setEmployeeFixTimetable3}
                                    options={planningFunctionService.EmployeeListOption(props.employees)}
                                    isMulti={true}
                                />
                            </div>
                        </>)}
                </Col>
            </Modal.Body>
            <div className="modal-footer">
                <Button
                    className="btn-simple"
                    type="button"
                    variant="link"
                    onClick={() => { props.setShowModal(false); }}>
                    Retour
                </Button>
                <Button
                    className="action-button-save"
                    onClick={() => { props.savePlanningParameter(); }}>
                    Enregistrer
                </Button>
            </div>
        </Modal>
    );
}

export default PlanningConfigModal;