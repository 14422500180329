import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import { useNavigate } from "react-router-dom";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

export const firebaseConfig = {
    apiKey: "AIzaSyB52umWHHOfIMimY2Iy6jzi354_USD5Yck",
    authDomain: "tyndeesn.firebaseapp.com",
    databaseURL: "https://tyndeesn-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "tyndeesn",
    storageBucket: "tyndeesn.appspot.com",
    messagingSenderId: "1071269002245",
    appId: "1:1071269002245:web:8d04efae8979fe3303706c",
    measurementId: "G-QMZ2P9T349"
};

export const firebase_app = firebase.initializeApp(firebaseConfig);
const messaging = getMessaging(firebase_app);

var auth = firebase_app.auth();
var googleProvider = new firebase.auth.GoogleAuthProvider();
var facebookProvider = new firebase.auth.FacebookAuthProvider();
const db = firebase_app.firestore();

export const signInWithGoogle = () => {
    return auth.signInWithPopup(googleProvider);
}


export const signInWithFacebook = () => {
    return auth.signInWithPopup(facebookProvider);
}


export const completeUserProfile = (uid, data) => {
    return db
        .collection("user")
        .doc(uid)
        .set(data);
}

export const fetchToken = (setTokenFound) => {
    return getToken(messaging, { vapidKey: 'BB1adU_pU_WLdwc1pkq9r4Rbph8yZJOPrDFllyHbEqqpt3-l2wpS77B6McBCExnLkqo-32-q9FnyMqXJpd974fM' }).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
    });
});