import React, { useState, useEffect } from "react";

// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Modal,
    Row,
    Col,
} from "react-bootstrap";
import timetableService from "../components/service/timetable.service";
import { firebase_app } from "../components/config/firebase";
import { useNavigate, useParams } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { notify } from "../components/service/notification.service";
import configService from "../components/service/config.service";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
//import EmployeeCSV from "../components/config/employeeCSV";
import siteService from "../components/service/site.service";
import leaveService from "../components/service/leave.service";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import contractService from "../components/service/contract.service";
import { BsCalendar3, BsCircleFill, BsDot, BsFillPeopleFill } from "react-icons/bs";
import pointingService from "../components/service/pointing.service";
import { AiFillLeftCircle, AiFillPlusCircle } from "react-icons/ai";


function Contract() {

    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [qualification, setQualification] = useState('');
    const [uid, setUid] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const [haveData, setHaveData] = useState(false);
    const [show, setShow] = useState(false);
    const [type, setType] = useState('cdd');
    const [timetables, setTimetables] = useState(null);
    const [sites, setSites] = useState(null);
    const [annualLeave, setAnnualLeave] = useState(24);
    const [startDate, setStartDate] = useState(new Date);
    const [endDate, setEndDate] = useState(null);
    const [state, setState] = useState('in_progress');
    const [contracts, setContracts] = useState([]);
    const [contractID, setContractID] = useState(null);
    const [createdDate, setCreatedDate] = useState(new Date);


    const getInfos = async () => {
        setHaveData(true);
        firebase_app.auth().onAuthStateChanged(async function (user) {
            if (user) {
                const userUid = user.uid;
                await configService.checkMonthIsActive(navigate, userUid);
                const timetableList = await timetableService.getTimetable(userUid);
                timetableList ? setTimetables(timetableList) : setTimetables(null);
                const siteList = await siteService.getSite(user.uid);
                siteList ? setSites(siteList) : setSites([])
                setUid(userUid);
                const contractList = await contractService.getContractByEmployeeID(id);
                const currentContract = contractList?.filter(contract => contract.data.state == 'in_progress')[0];
                contractList ? setContracts(contractList) : setContracts([]);
                if (currentContract) {
                    setCurrentContractInfos(currentContract.id, currentContract.data);
                }
            }
        })
    }

    const setCurrentContractInfos = (id = null, contract = null) => {
        setContractID(id ?? null)
        setName(contract?.name ?? '');
        setTitle(contract?.title ?? '');
        setQualification(contract?.qualification ?? '');
        setType(contract?.type ?? 'cdd')
        setAnnualLeave(contract?.annualLeave ?? 24);
        setStartDate(contract?.startDate.toDate() ?? new Date);
        setEndDate(contract?.endDate.toDate() ?? null);
        setState(contract?.state ?? 'new');
        setCreatedDate(contract?.createdDate ?? new Date);
    }

    const refreshPage = () => {
        window.location.reload();
    }


    useEffect(() => {
        if (haveData == false) getInfos();
    })


    const createOrUpdateContract = async (newState = state) => {

        const newContract = {
            "name": name,
            "createdBy": uid,
            "createdDate": createdDate,
            "updatedDate": new Date,
            "title": title,
            "qualification": qualification,
            "startDate": startDate,
            "endDate": endDate,
            "type": type,
            "state": newState,
            "employeeID": id,
            "annualLeave": annualLeave
        }

        if (contractID) {
            contractService.update(contractID, newContract).then((res) => {
                notify('Contrat modifié avec succés', "tc", 2)
                getInfos();
            }).catch((error) => {
                console.log(error.message);
            })
        }

        else {
            contractService.create(newContract).then((res) => {
                notify('Contrat créé avec succés', "tc", 2)
                getInfos();
            }).catch((error) => {
                console.log(error.message);
            })
        }
    }

    const deleteContract = async (id) => {
        setShow(false);
        contractService.delete(id).then(() => {
            notify('Contrat supprimer avec succés', "tc", 2);
            getInfos();
        })
    }

    const displaySate = (state) => {
        if (state == 'in_progress') return 'En cours';
        if (state == 'done') return 'Terminé';
        if (state == 'canceled') return 'Annulé';
        if (state == 'new') return 'Nouveau';
    }

    const displayContractList = () => {
        return contracts?.map((contract) => {
            return (
                <Col lg="12" sm="12" key={contract.id}>
                    <Card className="card-stats">
                        <Card.Header>
                            <Row onClick={() => setCurrentContractInfos(contract.id, contract.data)}>
                                <Col xs="12">
                                    <Card.Title as="h5" className="pointer fw-400">{contract?.data.type.toUpperCase()}</Card.Title>
                                    <span className="stats"> <BsDot color="#f8a86a" />  {displaySate(contract?.data.state)} </span>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Footer>
                            <hr></hr>
                            <span className="stats">
                                <BsCalendar3 color="#f8a86a" /> {pointingService.formatDateTime(contract?.data.startDate?.toDate())} - {pointingService.formatDateTime(contract?.data.endDate?.toDate())}
                            </span>
                        </Card.Footer>
                    </Card>
                </Col>
            )
        })
    }


    return (
        <>
            <Helmet>
                <title>Tyndee - Contrat</title>
            </Helmet>
            <Container fluid>

                <Modal show={show}>
                    <Modal.Header>
                        <Modal.Title>Suppression</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Etes-vous sur de vouloir supprimer le contrat ?</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShow(false)} >Annuler</Button>
                        <Button variant="danger" onClick={() => deleteContract(id)}>Oui, je suis sûr</Button>
                    </Modal.Footer>
                </Modal>
                <Row>
                    <Col md="8">
                        <Card>
                            <Card.Header>
                                <Card.Title as="h3">
                                    <Button className="action-button-save" variant="info" onClick={() => navigate(-1)}> <AiFillLeftCircle /> Retour</Button>
                                    Contrats
                                    <button type="button" className="action-button-cancel" style={{ color: '#56E04D' }} onClick={() => setNoPrintPlan(!noPrintPlan)}>
                                        <BsCircleFill /> {displaySate(state)}
                                    </button>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col className="pr-1" md="7">
                                            <Form.Group>
                                                <label>Nom du contract <span className="required">*</span></label>
                                                <Form.Control
                                                    value={name}
                                                    autoComplete="name"
                                                    alt="name"
                                                    placeholder="Nom"
                                                    type="text"
                                                    onChange={event => { setName(event.target.value) }} required
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-1" md="6">
                                            <Form.Group>
                                                <label>Titre</label>
                                                <Form.Control
                                                    value={title}
                                                    placeholder="Titre"
                                                    type="text"
                                                    autoComplete="on"
                                                    onChange={event => { setTitle(event.target.value) }}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col className="pr-1" md="5">
                                            <Form.Group>
                                                <label>Qualification</label>
                                                <Form.Control
                                                    value={qualification}
                                                    placeholder="qualification"
                                                    type="text"
                                                    autoComplete="on"
                                                    onChange={event => { setQualification(event.target.value) }}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-1" md="7">
                                            <Form.Group>
                                                <label>Type de contrat</label>
                                                <Form.Control
                                                    value={type}
                                                    placeholder="Horaire"
                                                    as="select"
                                                    onChange={event => { setType(event.target.value) }} required>
                                                    <option value='cdd'>CDD</option>
                                                    <option value='cdi'>CDI</option>
                                                    <option value='stage'>STAGE</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col className="pr-1" md="4">
                                            <Form.Group>
                                                <label>Congé annuel</label>
                                                <Form.Control
                                                    defaultValue={annualLeave}
                                                    placeholder="title"
                                                    type="text"
                                                    autoComplete="on"
                                                    onChange={event => { setAnnualLeave(event.target.value) }}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-1" md="4">
                                            <Form.Group>
                                                <label>Début<span className="required">*</span></label>
                                                <DatePicker dateFormat="dd/MM/yyyy" selected={startDate} onChange={(date) => {
                                                    setStartDate(date);
                                                }} />
                                            </Form.Group>
                                        </Col>
                                        <Col md="4">
                                            <Form.Group>
                                                <label>Fin</label>
                                                <DatePicker dateFormat="dd/MM/yyyy" selected={endDate} onChange={(date) => {
                                                    setEndDate(date);
                                                }} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button className="action-button-save" variant="info" onClick={() => createOrUpdateContract()}>Enregistrer</Button>

                                    <Button className="action-button-cancel" onClick={() => createOrUpdateContract('canceled')}>Annuler le contract</Button>
                                    <Button className="action-button-save" style={{ background: '#2986cc' }} variant="info" onClick={() => createOrUpdateContract('in_progress')}> Démarer le contrat</Button>

                                    <div className="clearfix"></div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-user">
                            <Card.Header>
                                <Card.Title as="h3">
                                    Listes des contrats
                                </Card.Title>
                                {displayContractList()}
                            </Card.Header>
                            <Button className='action-button-add' onClick={() => setCurrentContractInfos()}> Nouveau contrat  <AiFillPlusCircle /></Button>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Contract;