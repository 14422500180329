import React, { useEffect, useRef, useState } from "react";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Modal,
    Form,
    Table,
    Container,
    Row,
    Dropdown,
    Col,
} from "react-bootstrap";
import TimetableService from "../components/service/timetable.service";
import { useNavigate } from "react-router-dom";
import { firebase_app } from "../components/config/firebase";
import Select from 'react-select'
import groupService from "../components/service/site.service";
import employeeService, { truncateJob, truncateName } from "../components/service/employee.service";
import planningService from "../components/service/planning.service";
import ReactToPrint, { useReactToPrint } from 'react-to-print';

import { notify } from "../components/service/notification.service";
import SelectWeek from "../components/service/datepickerweek.service";
import configService from "../components/service/config.service";
import planningFunctionService from "../components/service/planning-function.service";
import { AiFillClockCircle } from "react-icons/ai";
import { FcCalendar, FcServices, FcRedo, FcDownload, FcCheckmark, FcLeave } from "react-icons/fc";

import moment from 'moment';
import "moment/locale/fr";

import leaveService from "../components/service/leave.service";
import siteService from "../components/service/site.service";
import { Helmet } from "react-helmet";
import { BsEye, BsFileEarmarkPdfFill } from "react-icons/bs";
import PlanningConfigModal from "../components/modal/planning-config.modal";
import planningParametersService from "../components/service/planning-parameters.service";
import timetableService from "../components/service/timetable.service";
import { getPublicHolidays, getPublicHolidaysByDay, getPublicHolidaysByWeek } from "../components/service/google-calendar.service";

function HourlyPlanning() {

    const [employees, setEmployees] = useState(null);
    const [timetables, setTimetables] = useState(null);
    const [plannings, setPlannings] = useState(null);
    const [weekSelected, setWeekSelected] = useState(planningService.getCurrentWeek());
    const [leaveWeek, setLeaveWeek] = useState(null);
    const [displayCalandar, setDisplayCalandar] = useState(false);
    const [uid, setUid] = useState(null);
    const [openSelect, setOpenSelect] = useState('');
    const [criteriaJob, setCriteriaJob] = useState(true);
    const [pauseTime, setPauseTime] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [haveData, setHaveData] = useState(false);
    const [elementOnDrag, setElementOnDrag] = useState('');
    const [employeeFixTimetable1, setEmployeeFixTimetable1] = useState([]);
    const [employeeFixTimetable2, setEmployeeFixTimetable2] = useState([]);
    const [employeeFixTimetable3, setEmployeeFixTimetable3] = useState([]);
    const [sites, setSites] = useState(null);
    const [siteSelected, setSiteSelected] = useState([]);
    const [unavailableEmployee, setUnavailableEmployee] = useState([]);
    const [showSite, setShowSite] = useState(false);
    const [plusBreakEndOfWeek, setPlusBreakEndOfWeek] = useState(false)
    const [daysPlusWorkMorning, setDaysPlusWorkMorning] = useState([]);
    const [noPrintPlan, setNoPrintPlan] = useState(true);
    const [employeeWorkNight, setEmployeeWorkNight] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const [weekHolidays, setWeekHolidays] = useState([]);

    const navigate = useNavigate();

    const getPlanning = async () => {
        setHaveData(true);
        firebase_app.auth().onAuthStateChanged(async function (user) {
            if (user) {
                setUid(user.uid);
                await configService.checkMonthIsActive(navigate, user.uid);
                getPublicHolidays(setHolidays);
                const leaveWeekList = await leaveService.getAcceptedLeave(user.uid, planningService.getCurrentWeek()[0], planningService.getCurrentWeek()[6]);
                leaveWeekList ? setLeaveWeek(leaveWeekList) : setLeaveWeek([]);
                const planningList = await planningService.getPlanningByWeek(user.uid, planningService.getCurrentWeek());
                planningList ? setPlannings(planningList) : setPlannings([]);
                const timetableList = await TimetableService.getDayTimetable(user.uid);
                timetableList ? setTimetables(TimetableService.sortTimetables(timetableList)) : setTimetables([]);
                const employeeList = await employeeService.getEmployee(user.uid);
                const employeePlanningList = employeeService.getEmployeePlanning(employeeList)
                employeePlanningList ? setEmployees(employeeService.sortEmployeeLevel(employeePlanningList)) : setEmployees([]);
                const siteList = await siteService.getSite(user.uid);
                siteList ? setSites(siteList) : setSites([]);
                const planningConfig = await planningParametersService.getPlanningParametersByID(user.uid);
                if (planningConfig) setPlanningParameterData(planningConfig);
            } else {
                console.log('Not connected');
                navigate('/login');
            }
        });

    }

    const setPlanningParameterData = (planningParameter) => {
        setCriteriaJob(planningParameter.criteriaJob ?? true);
        setPauseTime(planningParameter.pauseTime ?? null);
        setPlusBreakEndOfWeek(planningParameter.plusBreakEndOfWeek ?? false);
        setDaysPlusWorkMorning(planningParameter.daysPlusWorkMorning ?? []);
        setUnavailableEmployee(planningParameter.unavailableEmployee ?? []);
        setEmployeeFixTimetable1(planningParameter.employeeFixTimetable1 ?? []);
        setEmployeeFixTimetable2(planningParameter.employeeFixTimetable2 ?? []);
    }

    const savePlanningParameter = () => {
        planningParametersService.savePlanningParameter(uid, criteriaJob, pauseTime, daysPlusWorkMorning, plusBreakEndOfWeek, unavailableEmployee, employeeFixTimetable1, employeeFixTimetable2).then((res) => {
            notify('Planning enregister avec succés', "tc", 2);
        }).catch((error) => {
            console.log(error.message);
        });
    }

    const getPlanningByWeek = async (week) => {
        const planningList = await planningService.getPlanningByWeek(uid, week);
        getPublicHolidaysByWeek(holidays, week, setWeekHolidays);
        planningList ? setPlannings(planningList) : setPlannings([]);
    }

    useEffect(() => {
        if (haveData == false) getPlanning();
    })

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    const printPDF = () => {
        setNoPrintPlan(false);
        setUid(uid);
        if (!noPrintPlan)
            handlePrint();
        //setNoPrintPlan(true);
    }

    const insertOrUpdatePlanning = async (employeeID, day, timetable) => {
        var newPlanning = {
            'createdBy': uid,
            'employeeID': employeeID,
            'planningDate': day,
            'name': timetable?.data.name,
            'startTime': timetable?.data.startTime,
            'endTime': timetable?.data.endTime
        }
        const planningDays = await planningService.getPlanningDayByEmployee(uid, day, employeeID);
        const planning = planningDays[0];

        if (planningDays.length > 1) {
            for (let i = 1; i < planningDays.length; i++) {
                planningService.delete(planningDays[i].id).then((res) => {
                    getPlanningByWeek(weekSelected);
                }).catch((error) => {
                    console.log(error.message);
                })
            }
        }
        if (planning?.id) {
            if (timetable != null) {
                planningService.update(planning.id, newPlanning).then((res) => {
                    getPlanningByWeek(weekSelected);
                    setOpenSelect('');
                    setElementOnDrag('');
                }).catch((error) => {
                    console.log(error.message);
                })
            }
            else {
                planningService.delete(planning.id).then((res) => {
                    getPlanningByWeek(weekSelected);
                    setOpenSelect('');
                }).catch((error) => {
                    console.log(error.message);
                })
            }
        } else {
            if (timetable != null) {
                planningService.create(newPlanning).then((res) => {
                    getPlanningByWeek(weekSelected);
                    setOpenSelect('');
                    setElementOnDrag('');
                }).catch((error) => {
                    console.log(error.message);
                })
            } else setOpenSelect('');
        }
    }

    const deletePlanningEmployeeNotWork = (planningEmpoyeeNotWork, weekSelected) => {
        planningEmpoyeeNotWork.map(planning => {
            planning?.id ? planningService.delete(planning.id).then(() => { getPlanningByWeek(weekSelected) }) : ''
        });
    }

    const displayNotWorkEmployee = (employeeList, day) => {
        return employeeList?.map((employee) => {
            if (employee?.id) {
                const employeeLeave = planningFunctionService.checkEmployeeLeave(employee.id, leaveWeek, day);
                return (
                    <div key={employee.id}
                        onDragStart={(e) => onDragStart(e, employee.id)}
                        draggable
                        style={{ opacity: elementOnDrag == employee.id ? 0.4 : 1, backgroundColor: employeeLeave ? '#f1efec' : '#fff' }}
                        className='employee-planning'>
                        <div className='employee-planning-name'>
                            {truncateName(employee?.data.name)}
                        </div>
                        <div className='employee-planning-job'>
                            {truncateJob(employee?.data.job)}
                        </div>
                    </div>
                )
            }
        })
    }

    const onDragOver = (ev) => {
        ev.preventDefault();
    }

    const onDragEnd = (ev) => {
        setElementOnDrag('');
    }

    const onDrop = (ev, data) => {
        if (data.day > moment().subtract(1, 'days')) {
            let employeeID = ev.dataTransfer.getData("id");
            insertOrUpdatePlanning(employeeID, data.day, data.timetable);
        }
    }

    const onDragStart = (ev, id) => {
        ev.dataTransfer.setData("id", id);
        setElementOnDrag(id);
    }

    const displayEmployeeList = (planningEmployeeList, day) => {
        const employeeWorkDay = planningFunctionService.getEmployeeWork(employees, planningEmployeeList, day);
        return employeeWorkDay?.map((employee) => {
            const employeeLeave = planningFunctionService.checkEmployeeLeave(employee.id, leaveWeek, day);
            return (
                <div key={employee.id}
                    onDragStart={(e) => onDragStart(e, employee.id)}
                    draggable
                    style={{ opacity: elementOnDrag == employee.id ? 0.4 : 1, backgroundColor: employeeLeave ? '#f1efec' : '#fff' }}
                    className='employee-planning'>
                    <div className='employee-planning-name'>
                        {truncateName(employee?.data.name)}
                        <span type='button' className='employee-planning-delete' onClick={() => {
                            if (day > moment().subtract(1, 'days')) {
                                insertOrUpdatePlanning(employee.id, day, null);
                            } else setOpenSelect('');
                        }}>
                            x
                        </span>
                    </div>
                    <div className='employee-planning-job'>
                        {truncateJob(employee?.data.job)}
                    </div>


                </div>
            )
        })
    }

    const displayEmployeeNotWorkDay = (day) => {
        const planningEmployeeList = planningFunctionService.getPlanningDay(plannings, day);
        const employeeNotWorkList = planningFunctionService.getEmployeeNotWork(employees, planningEmployeeList, day);

        return (
            <td
                onDragOver={(e) => onDragOver(e)}
                onDragEnd={(e) => onDragEnd(e)}
                onDrop={(e) => { onDrop(e, { day: day, timetable: null }) }}>
                {displayNotWorkEmployee(employeeService.sortEmployeeLevel(employeeNotWorkList), day)}
            </td>
        )
    }


    const displayWeekPlannig = (day) => {
        return timetables?.map((timetable) => {
            const data = timetable.data;
            const planningEmployeeList = planningFunctionService.getPlanningEmployee(plannings, data.name, day);
            return (
                <td key={timetable.id}
                    className="planning-td"
                    onDragOver={(e) => onDragOver(e)}
                    onDragEnd={(e) => onDragEnd(e)}
                    onDrop={(e) => { onDrop(e, { day: day, timetable: timetable }) }}>
                    {displayEmployeeList(planningEmployeeList, day)}
                    {noPrintPlan && openSelect != data.name + '_' + day &&
                        (<button
                            className='add-employee-planning'
                            style={{ border: 0 }}
                            onClick={() => {
                                if (day > moment().subtract(1, 'days')) {
                                    setOpenSelect(data.name + '_' + day);

                                } else setOpenSelect('');
                            }}>
                            <span className='employee-planning-name'> Ajouter </span>
                            <span className='employee-planning-add'>
                                +
                            </span>
                        </button>)}
                    {openSelect == data.name + '_' + day && (<Select
                        placeholder="Employé"
                        onChange={
                            (value) => {
                                if (value.value != null) {
                                    insertOrUpdatePlanning(value.value.id, day, timetable);
                                } else setOpenSelect('');
                            }
                        }
                        options={planningFunctionService.EmployeeOption(plannings, employees, day)}
                    />)}
                </td>)
        })
    }


    const displayPlannig = () => {
        return weekSelected?.map((day) => {
            return (
                <tr key={day}>
                    <td align="center" className="day-name planning-td" >
                        {moment(day).format('dddd DD').toUpperCase()}
                        <br />
                        <p style={{ color: '#ec172b', fontSize: 13.2 }}>
                            {getPublicHolidaysByDay(weekHolidays, day)}
                        </p>
                    </td>
                    {displayWeekPlannig(day)}
                    {displayEmployeeNotWorkDay(day)}
                </tr>)
        })
    }

    const displayTimetable = () => {
        return timetables?.map((timetable) => {
            const data = timetable.data;
            return (<th width={250}>
                {data.name}
                <br />
                <span>{data.startTime} - {data.endTime}</span>
            </th>)
        })
    }

    const generateLastDayNightPlanning = async () => {
        const employeeAvaibleToWork = planningFunctionService.getRandomAvaibleEmployee(unavailableEmployee ?? [], employees);
        const previousPlanning = await planningService.getPlanningByWeek(uid, weekSelected);
        const employeeWillWorkNextDay = planningFunctionService.choiceEmployeeWork(pauseTime.value, employeeAvaibleToWork, 0, previousPlanning, weekSelected)?.employeeWork;
        generateNightPlanning(employeeWillWorkNextDay, 7);
    }

    const generateNightPlanning = (employeeWorks, index) => {
        const timetableNight = timetables[2];
        const day = weekSelected[index];
        const startYesterday = timetableService.getHoursNumber(timetableNight.data.startTime) > timetableService.getHoursNumber(timetableNight.data.endTime);
        const yesterday = startYesterday ? weekSelected[index - 1] : day;
        if (yesterday > moment().subtract(1, 'days')) {
            const employeeAvaibleToWork = planningFunctionService.getRandomAvaibleEmployee(unavailableEmployee ?? [], employees);
            const employeeWorkNightChosed = planningFunctionService.choiceEmployeeWorkNight(employeeAvaibleToWork, employeeWorks, employeeWorkNight);
            for (let i = 0; i < employeeWorkNightChosed?.length; i++) {
                insertOrUpdatePlanning(employeeWorkNightChosed[i].id, yesterday, timetableNight);
            }
        }
    }

    const generateDailyPlannig = (employeeWorks, index) => {
        const day = weekSelected[index];
        if (day > moment().subtract(1, 'days')) {
            const employeeWorksAnyTime = employeeWorks.length > 0 ? planningFunctionService.getEmployeeWorkAnyTime(employeeWorks, employeeFixTimetable1, employeeFixTimetable2, employeeFixTimetable3) : [];
            var numEmpFixTimetable1job1 = 0;
            var numEmpFixTimetable2job1 = 0;

            var numEmpFixTimetable1job2 = 0;
            var numEmpFixTimetable2job2 = 0;

            const planningEmployeeNotWork = planningFunctionService.getPlanningEmployeeNotWork(plannings, employees, employeeWorks, day);
            deletePlanningEmployeeNotWork(planningEmployeeNotWork, weekSelected);

            employeeFixTimetable1.map(employee => {
                if (employeeWorks?.filter(employeeWork => employeeWork.id == employee.value.id).length > 0) {

                    if (employee?.data?.job?.toLowerCase() == employees[0]?.data?.job?.toLowerCase()) numEmpFixTimetable1job1++;
                    if (employee?.data?.job?.toLowerCase() != employees[0]?.data?.job?.toLowerCase()) numEmpFixTimetable1job2++;

                    insertOrUpdatePlanning(employee.value.id, day, timetables[0]);
                }
            });

            employeeFixTimetable2.map(employee => {
                if (employeeWorks?.filter(employeeWork => employeeWork.id == employee.value.id).length > 0) {

                    if (employee?.data?.job?.toLowerCase() == employees[0]?.data?.job?.toLowerCase()) numEmpFixTimetable2job1++;
                    if (employee?.data?.job?.toLowerCase() != employees[0]?.data?.job?.toLowerCase()) numEmpFixTimetable2job2++;

                    insertOrUpdatePlanning(employee.value.id, day, timetables[1]);
                }
            });

            if (employeeWorksAnyTime?.length > 0) {
                const dayInPWM = daysPlusWorkMorning?.filter(day => day.value == index)?.length > 0;

                if (criteriaJob == true) {
                    const jobType1 = employeeWorksAnyTime?.filter(employeeWork => employeeWork?.data?.job?.toLowerCase() == employees[0]?.data?.job?.toLowerCase());
                    const jobType2 = employeeWorksAnyTime?.filter(employeeWork => employeeWork?.data?.job?.toLowerCase() != employees[0]?.data?.job?.toLowerCase());

                    var diffJob1 = numEmpFixTimetable1job1 - numEmpFixTimetable2job1;
                    var diffJob2 = numEmpFixTimetable1job2 - numEmpFixTimetable2job2;

                    for (let i = 0; i < jobType1.length; i++) {
                        var n = i - Math.abs(diffJob1);

                        if (n < 0) {
                            diffJob1 > 0 ? insertOrUpdatePlanning(jobType1[i].id, day, timetables[1])
                                : insertOrUpdatePlanning(jobType1[i].id, day, timetables[0]);
                        }
                        else {
                            n % 2 == 0 ? insertOrUpdatePlanning(jobType1[i].id, day, timetables[dayInPWM ? 0 : 1])
                                : insertOrUpdatePlanning(jobType1[i].id, day, timetables[dayInPWM ? 1 : 0]);
                        }
                    }

                    for (let i = 0; i < jobType2.length; i++) {
                        var n = i - Math.abs(diffJob2);

                        if (n < 0) {
                            diffJob2 > 0 ? insertOrUpdatePlanning(jobType2[i].id, day, timetables[1])
                                : insertOrUpdatePlanning(jobType2[i].id, day, timetables[0]);
                        }
                        else {
                            (n + jobType1.length - Math.abs(diffJob1)) % 2 == 0 ? insertOrUpdatePlanning(jobType2[i].id, day, timetables[dayInPWM ? 0 : 1])
                                : insertOrUpdatePlanning(jobType2[i].id, day, timetables[dayInPWM ? 1 : 0]);
                        }
                    }

                } else {
                    var diffWorkTimeTable = employeeFixTimetable1.length - employeeFixTimetable2.length;
                    for (let i = 0; i < employeeWorksAnyTime.length; i++) {
                        var n = i - Math.abs(diffWorkTimeTable);

                        if (n < 0) {
                            diffWorkTimeTable > 0 ? insertOrUpdatePlanning(employeeWorksAnyTime[i].id, day, timetables[1])
                                : insertOrUpdatePlanning(employeeWorksAnyTime[i].id, day, timetables[0]);
                        }
                        else {
                            n % 2 == 0 ? insertOrUpdatePlanning(employeeWorksAnyTime[i].id, day, timetables[dayInPWM ? 0 : 1])
                                : insertOrUpdatePlanning(employeeWorksAnyTime[i].id, day, timetables[dayInPWM ? 1 : 0]);
                        }
                    }
                }
            }

            if (timetables.length == 3) {
                employeeFixTimetable3.map(employee => {
                    if (employeeWorks?.filter(employeeWork => employeeWork.id == employee.value.id).length > 0) {
                        insertOrUpdatePlanning(employee.value.id, day, timetables[2]);
                    }
                });
                if (index > 0) generateNightPlanning(employeeWorksAnyTime, index);
                if (index == 6) generateLastDayNightPlanning();
            }
        }
    }

    const displayLastWeek = () => {
        return planningService.getLastWeek(weekSelected).map((week) => {
            return (
                <Dropdown.Item onClick={async () => {
                    const planningList = await planningService.getPlanningByWeek(uid, week);
                    for (let i = 0; i < 7; i++) {
                        if (weekSelected[i] > moment().subtract(1, 'days')) {
                            employees.map((employee) => {
                                const planningData = planningFunctionService.getPlanningDate(planningList, employee.id, week[i])?.data;
                                const timetable = planningData ? { data: { name: planningData.name, startTime: planningData.startTime, endTime: planningData.endTime } } : null;
                                insertOrUpdatePlanning(employee.id, weekSelected[i], timetable);
                            })
                        }
                    }
                    notify('Copié avec succés', "tc", 2);
                }}
                    key={moment(week[0]).format('LL')}>
                    {moment(week[0]).format('LL')} –{' '}
                    {moment(week[6]).format('LL')}
                </Dropdown.Item>)
        })
    }

    const displaySites = () => {
        return sites?.map((site) => {
            return (
                <Dropdown.Item onClick={async () => {
                    let siteList = siteSelected;
                    if (siteList.includes(site.id)) {
                        siteList = siteList.filter(item => item !== site.id)
                    } else {
                        siteList.push(site.id);
                    }
                    setSiteSelected(siteList);
                    const employeeList = siteList.length > 0 ?
                        await employeeService.getEmployeeBySite(siteList) : await employeeService.getEmployee(uid);
                    setEmployees(employeeList);
                    getPlanningByWeek(weekSelected);
                }}
                    key={site.id}>
                    {siteSelected.includes(site.id) ? (<FcCheckmark />) : ''} {site.data.name}
                </Dropdown.Item>)
        })
    }

    const swapSchedules = async () => {
        const planningList = await planningService.getPlanningByWeek(uid, weekSelected);
        for (let i = 0; i < 7; i++) {
            if (weekSelected[i] > moment().subtract(1, 'days')) {
                employees.map((employee) => {
                    const planningData = planningFunctionService.getPlanningDate(planningList, employee.id, weekSelected[i])?.data;
                    if (planningData) {
                        if (timetables[0].data.name == planningData.name) {
                            insertOrUpdatePlanning(employee.id, weekSelected[i], timetables[1]);
                        }
                        if (timetables[1].data.name == planningData.name) {
                            insertOrUpdatePlanning(employee.id, weekSelected[i], timetables[0]);
                        }
                    }
                })
            }
        }
        notify('Permuté avec succés', "tc", 2);
    }

    const generatePlanning = async () => {
        if (timetables.length < 2) {
            setShowModal(false);
            notify('Il faut avoir au moins 2 horaires pour pouvoir utiliser cette fonctionnalité ', "tc", 3);
        }
        else if (pauseTime?.value) {
            setShowModal(false);
            const employeeAvaibleToWork = planningFunctionService.getRandomAvaibleEmployee(unavailableEmployee ?? [], employees);
            const previousWeek = planningService.previousWeek(weekSelected);
            const previousPlanning = await planningService.getPlanningByWeek(uid, previousWeek);

            for (let i = 0; i < 7; i++) {
                const employeeWillWork = planningFunctionService.choiceEmployeeWork(pauseTime.value, employeeAvaibleToWork, i, previousPlanning, previousWeek)?.employeeWork;
                const index = plusBreakEndOfWeek || pauseTime?.value != 3 ? i : 6 - i
                generateDailyPlannig(planningService.shuffle(employeeWillWork), index);

            }
            notify('Planning générer avec succés', "tc", 2);
        }
    }

    const selectWeekForPlan = async (value) => {
        const endDay = moment(value[0]).subtract(3, 'd');
        const userData = await configService.getUserByID(uid);
        const firstDay = moment(value[0]).startOf('month').format('DD-MM-YYYY');
        var createdDate = userData?.created_date;
        const activatedMonth = await configService.getActivatedMonthonDate(uid, endDay);
        if (moment(createdDate?.toDate()).startOf('month').format('DD-MM-YYYY') != firstDay && !activatedMonth[0]) {
            notify('Vous ne pouvez pas planifier ce mois', "tc", 3);
        } else {
            const leaveWeekList = await leaveService.getAcceptedLeave(uid, weekSelected[0], weekSelected[6]);
            leaveWeekList ? setLeaveWeek(leaveWeekList) : setLeaveWeek([]);
            getPlanningByWeek(value);
            setWeekSelected(value);
        }
    }

    return (
        <>
            <Helmet>
                <title>Tyndee - Planification</title>
            </Helmet>
            <Button className='action-button-add' onClick={() => navigate('/admin/planning/timetable')}> <AiFillClockCircle /> Horaires</Button>
            {timetables?.length > 0 ?
                (<Container fluid>
                    <PlanningConfigModal showModal={showModal} setShowModal={setShowModal} criteriaJob={criteriaJob} setCriteriaJob={setCriteriaJob}
                        pauseTime={pauseTime} setPauseTime={setPauseTime} unavailableEmployee={unavailableEmployee} setUnavailableEmployee={setUnavailableEmployee}
                        employeeFixTimetable1={employeeFixTimetable1} setEmployeeFixTimetable1={setEmployeeFixTimetable1} employeeFixTimetable2={employeeFixTimetable2}
                        setEmployeeFixTimetable2={setEmployeeFixTimetable2} timetables={timetables} generatePlanning={generatePlanning} employees={employees}
                        daysPlusWorkMorning={daysPlusWorkMorning} setDaysPlusWorkMorning={setDaysPlusWorkMorning} plusBreakEndOfWeek={plusBreakEndOfWeek}
                        setPlusBreakEndOfWeek={setPlusBreakEndOfWeek} savePlanningParameter={savePlanningParameter} employeeWorkNight={employeeWorkNight}
                        setEmployeeWorkNight={setEmployeeWorkNight} employeeFixTimetable3={employeeFixTimetable3} setEmployeeFixTimetable3={setEmployeeFixTimetable3}
                    />
                    <Row>
                        <Col md="12">
                            <Card className="card-plain table-plain-bg ">
                                <Card.Header>
                                    <Card.Title as="h3">Planning horaire
                                        <button type="button" className="btn" style={{ color: '#1F3E76', border: 0, marginTop: -8 }} onClick={() => setNoPrintPlan(!noPrintPlan)}>
                                            <BsFileEarmarkPdfFill className="planning-fc-download" />
                                        </button>
                                        <button type="button" className="btn" style={{ color: '#1F3E76', border: 0, marginTop: -8 }} onClick={handlePrint}>
                                            <FcDownload className="planning-fc-download" />
                                        </button>
                                        <div>
                                            <Dropdown className="d-inline mx-2" show={displayCalandar} >
                                                <Dropdown.Toggle variant="danger" className="planning-btn" onClick={() => setDisplayCalandar(!displayCalandar)}>
                                                    <FcCalendar /> Choisir une semaine
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <SelectWeek setWeekSelected={
                                                        (value) => {
                                                            setDisplayCalandar(!displayCalandar);
                                                            selectWeekForPlan(value);
                                                        }
                                                    } />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <button type="button" className="btn planning-btn" onClick={() => { swapSchedules(); }}>
                                                <FcRedo /> Permuter
                                            </button>

                                            {/*<Dropdown className="d-inline mx-2" show={showSite}>
                                                <Dropdown.Toggle variant="warning" className="planning-btn" onClick={() => setShowSite(!showSite)}>
                                                    Sites
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {displaySites()}
                                                </Dropdown.Menu>
                                                </Dropdown>*/}

                                            <Dropdown className="d-inline mx-2" >
                                                <Dropdown.Toggle id="dropdown-basic" className="planning-btn">
                                                    Copier
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {displayLastWeek()}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <button type="button" className="btn planning-btn" onClick={() => { setShowModal(true); }}>
                                                <FcServices /> Générer
                                            </button>


                                        </div>
                                    </Card.Title>
                                </Card.Header>
                            </Card>
                        </Col>
                    </Row>
                    <div ref={componentRef}>
                        <div className="selectdPeriod"> {moment(weekSelected[0]).format('LL')} – {' '}
                            {moment(weekSelected[6]).format('LL')} </div>
                        <Table style={{ tableLayout: 'fixed', fontSize: '12px' }}>
                            <thead className="planning">
                                <tr >
                                    <th width={90}></th>
                                    {displayTimetable()}
                                    <th width={200}>Repos</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayPlannig()}
                            </tbody>
                        </Table>
                    </div>
                </Container>) : (
                    <h3 className="centered">
                        <FcLeave /> Créer les horaires pour commencer à plannifier
                    </h3>)}
        </>
    );
}

export default HourlyPlanning;
