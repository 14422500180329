import React, { useState } from "react";
import ChartistGraph from "react-chartist";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

import { ImLinkedin, ImInstagram, ImTwitter } from "react-icons/im";
import { Helmet } from "react-helmet";



import QRCode from 'qrcode.react';

function QRCodeGenerator() {
    const [qrcodeURL, setQrcodeURL] = useState('tyndee.com');


    const downloadQR = () => {
        const canvas = document.getElementById('qrcodeURL');
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = qrcodeURL + ".png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };


    return (
        <body style={{ margin: 0, padding: 0, backgroundColor: '#F8F9FA' }}>
            <Helmet>
                <title>Tyndee - Générateur de QRcode</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col style={{ marginTop: '2%', paddingTop: '2%' }} md="12">
                        <Card>
                            <Row>
                                <Col md="7">
                                    <Card.Header>
                                        <Card.Title as="h4">Générateur de QRcode</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form>
                                            <Row>
                                                <Col className="pr-1" md="11">
                                                    <Form.Group>
                                                        <label>Mettez un lien vers (site, whatsApp business, instagram, etc...)</label>
                                                        <Form.Control
                                                            defaultValue={qrcodeURL}
                                                            placeholder="LIEN"
                                                            type="text"
                                                            onChange={event => { setQrcodeURL(event.target.value) }}>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                    <Button
                                        style={{ float: 'right', margin: '5%' }}
                                        className="btn-fill pull-right"
                                        variant="info"
                                        onClick={downloadQR}
                                    >
                                        Télécharger le qrcode
                                    </Button>
                                </Col>
                                <Col md="5">
                                    <Card.Body>
                                        <div className="ct-chart ct-perfect-fourth" id="chartHours">
                                            <QRCode
                                                id='qrcodeURL'
                                                value={qrcodeURL}
                                                size={270}
                                                level={"H"}
                                                includeMargin={true}
                                            />
                                        </div>
                                    </Card.Body>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="9" style={{ marginBottom: '2%', pading: '1%' }}>
                                    <Card.Header>
                                        <Card.Title as="h5">Abonnez-vous à nos pages pour nous soutenir</Card.Title>
                                        <a style={{ margin: 15, textDecoration: 'none', color: '#0072b1' }} href="https://www.linkedin.com/company/tyndee">
                                            <span>
                                                <ImLinkedin style={{ margin: 3 }} />
                                                LinkedIn
                                            </span>
                                        </a>
                                        <a style={{ margin: 15, textDecoration: 'none', color: '#87536a' }} href="https://www.instagram.com/_tyndee_?r=nametag">
                                            <span >

                                                <ImInstagram style={{ margin: 3 }} />
                                                Instagram
                                            </span>
                                        </a>
                                        <a style={{ margin: 15, textDecoration: 'none', color: '#00acee' }} href="https://twitter.com/tyndee1">
                                            <span>
                                                <ImTwitter style={{ margin: 3 }} />
                                                Tweeter
                                            </span>
                                        </a>


                                    </Card.Header>

                                </Col>
                                <Col md="2">
                                    <Button
                                        style={{ float: 'right' }}
                                        className="btn-fill pull-right"
                                        variant="info"
                                        href="/">
                                        Sortir
                                    </Button>
                                </Col>

                            </Row>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </body>
    )

}

export default QRCodeGenerator