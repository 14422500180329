import { firebase_app } from "../config/firebase";

const db = firebase_app.firestore();

class TeamService {

  async getTeam(uid) {
    if (!uid) return null;
    const snapshot = await db.collection("team").where('createdBy', '==', uid).get()
    return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
  }

  async getTeamByID(id) {
    const snapshot = await db.collection("team").doc(id).get()
    return snapshot.docs?.map(doc => { return doc.data() });
  }

  create(team) {
    return db.collection('team').add(team)
  }

  update(id, value) {
    return db.collection('team').doc(id).update(value);
  }

  delete(id) {
    return db.collection('team').doc(id).delete();
  }

}

export default new TeamService();