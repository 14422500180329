import React, { useEffect, useState } from "react";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Modal,
  Form,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import TimetableService from "../components/service/timetable.service";
import { useNavigate } from "react-router-dom";
import { firebase_app } from "../components/config/firebase";
import Select from 'react-select'
import siteService from "../components/service/site.service";
import employeeService from "../components/service/employee.service";
import { AiFillPlusCircle, AiOutlineClose, AiFillCompass, AiOutlineTeam, AiFillLeftCircle } from "react-icons/ai";
import { BsFillTrashFill, BsFillPeopleFill } from "react-icons/bs";
import GoogleMapReact from 'google-map-react';
import configService from "../components/service/config.service";
import MyGoogleMap from "../components/MyGoogleMap";
import { Helmet } from "react-helmet";

function Site() {

  const [sites, setSites] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [name, setName] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [id, setId] = useState(null);
  const [uid, setUid] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [openSite, setOpenSite] = useState(null);

  const navigate = useNavigate();
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [siteName, setsiteName] = useState(null);
  const [openToAddEmp, setOpenToAddEmp] = useState(null);
  const [haveData, setHaveData] = useState(false);



  const getSiteList = async () => {
    setHaveData(true);
    firebase_app.auth().onAuthStateChanged(async function (user) {

      if (user) {
        setUid(user.uid);
        await configService.checkMonthIsActive(navigate, user.uid);
        const siteList = await siteService.getSite(user.uid);
        const employeeList = await employeeService.getEmployee(user.uid);
        siteList ? setSites(siteList) : setSites([])
        employeeList ? setEmployees(employeeList) : setEmployees([]);

      } else {
        console.log('Not connected');
        navigate('/login');
      }
    });
  }

  const updatesiteList = async () => {
    const siteList = await siteService.getSite(uid);
    const employeeList = await employeeService.getEmployee(uid);
    siteList ? setSites(siteList) : setSites([])
    employeeList ? setEmployees(employeeList) : setEmployees([]);
  }



  useEffect(() => {
    if (haveData == false) getSiteList();
  })

  const addEmployee = (siteID, employeeSelected) => {
    var i = 0;
    employeeSelected.map((employee) => {
      i++
      var data = employee.value.data;
      data['siteID'] = siteID;
      employeeService.update(employee.value.id, data).then((res) => {
        if (i == employeeSelected.length) {
          setSelectedEmployees(null);
          updatesiteList();
        }
      }).catch((error) => {
        console.log(error.message);
      });
    })
  }


  const removeEmployee = (employee) => {
    var data = employee.data;
    data.siteID = null;
    employeeService.update(employee.id, data).then((res) => {
      updatesiteList();
    }).catch((error) => {
      console.log(error.message);
    });
  }


  const getEmployeesAvaible = (employeeList) => {
    var employeeAvaibles = []
    employeeList?.filter(employee => !employee.data.siteID).map((employeeAvaible) => {
      employeeAvaibles.push({
        value: employeeAvaible,
        label: employeeAvaible.data.name
      })
    })
    return employeeAvaibles;
  }


  const displaySiteList = () => {
    return sites?.map((site) => {
      const employeeNumber = employees?.filter(employee => employee.data.siteID == site.id).length;
      return (
        <Col lg="3" sm="6" key={site.id}>
          <Card className="card-stats">
            <Card.Body>
              <Row onClick={() => setOpenSite(site)}>
                <Col xs="12">
                  <Card.Title as="h5" className="pointer fw-400">{site.data.name}</Card.Title>
                  <span className="stats"><BsFillPeopleFill color="#1f3e76" />  {employeeNumber} employés </span>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <hr></hr>
              <div className="stats">
                <AiFillCompass color="#f8a86a" /> {site.data.address}
              </div>
            </Card.Footer>
          </Card>
        </Col>
      )
    })
  }

  const displaySiteDetail = (site) => {
    var data = site.data;
    var id = site.id;
    return (
      <Col md="12">
        <Card.Body className="element-list-display" key={id}>
          <Button className="site-list-button"
            onClick={() => { setOpenSite(null) }}>
            <AiOutlineClose color="red" />
          </Button>
          <Button className="action-button-add"
            onClick={() => {
              setOpenToAddEmp(true);
              setShowModal(true);
            }}> Ajouter  <AiFillPlusCircle />
          </Button>
          <Card.Title as="h4" className="pointer fw-400" onClick={() => navigate('/admin/site/' + id)}>
            {data.name} </Card.Title>
          <span>{data.address} </span>
          <div>
            <Table className="table-hover">
              <thead>
                <tr>
                  <th className="border-0">Nom</th>
                  <th className="border-0">Poste</th>
                  <th className="border-0">Téléphone</th>
                </tr>
              </thead>
              <tbody>
                {displayEmployeeList(site.id)}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Col>
    )

  }

  const displayEmployeeList = (id) => {
    const employeeList = employees?.filter(employee => employee.data.siteID == id);
    return employeeList?.map((employee) => {
      const data = employee.data;
      return (
        <tr key={employee.id}>
          <td className="pointer" onClick={() => navigate('/admin/employees/' + employee.id)}>{data.name}</td>
          <td>{data.job}</td>
          <td>{data.num}</td>
          <td>
            <BsFillTrashFill className="pointer" color="red" onClick={() => removeEmployee(employee)} />
          </td>
        </tr>
      )
    })
  }

  return (
    <>
      <Helmet>
        <title>Tyndee - Sites - Lieux de travail</title>
      </Helmet>
      <Container fluid>
        <Modal
          className="modal-primary"
          show={showModal}
          onHide={() => setShowModal(false)}>
          <Modal.Header className="justify-content-center">
            <h4>Ajouter des employés dans {openSite?.data.name} </h4>
          </Modal.Header>
          <Modal.Body className="text-center">
            <Row>
              <Col className="pr-1 text-left" md="12">
                <Form.Group>
                  <Select
                    defaultValue={selectedEmployees}
                    onChange={setSelectedEmployees}
                    options={getEmployeesAvaible(employees)}
                    isMulti={true}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <div className="modal-footer">
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => setShowModal(false)}>
              Retour
            </Button>
            <Button
              className="action-button-save"
              onClick={() => {
                addEmployee(openSite.id, selectedEmployees)
                setShowModal(false);
              }}>
              Confirmer
            </Button>
          </div>
        </Modal>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Button className="action-button-add" onClick={() => { navigate('/admin/new-site') }}
                >Nouveau Site <AiFillPlusCircle /></Button>
                <Card.Title as="h3">
                  <Button className="action-button-save" variant="info" onClick={() => navigate(-1)}> <AiFillLeftCircle /> Retour</Button>

                  Liste des sites
                </Card.Title>
              </Card.Header>
              <Row>
                {openSite ? displaySiteDetail(openSite) : displaySiteList()}

              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Site;
