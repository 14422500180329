import React, { useEffect, useState } from "react";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Modal,
    Form,
    Table,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import TimetableService from "../components/service/timetable.service";
import { useNavigate } from "react-router-dom"; import { firebase_app } from "../components/config/firebase";
import Select from 'react-select'
import blocationService from "../components/service/blocation.service";
import employeeService from "../components/service/employee.service";
import configService from "../components/service/config.service";
import { AiFillPlusCircle, AiFillLeftCircle, AiOutlineClose } from "react-icons/ai";
import { BsFillTrashFill, BsFillPeopleFill, BsFillHouseFill } from "react-icons/bs";
import { Helmet } from "react-helmet";
import siteService from "../components/service/site.service";
import ConfirmDelete from "../components/ConfimDelete";

function Blocation() {

    const [blocations, setBlocations] = useState(null);
    const [id, setId] = useState(null);
    const [uid, setUid] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [sites, setSites] = useState(null);

    const navigate = useNavigate();
    const [selectedSite, setSelectedSite] = useState(null);
    const [blocationName, setBlocationName] = useState(null);
    const [haveData, setHaveData] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const getBlocationList = async () => {
        setHaveData(true);
        firebase_app.auth().onAuthStateChanged(async function (user) {

            if (user) {
                setUid(user.uid);
                await configService.checkMonthIsActive(navigate, user.uid);
                const blocationList = await blocationService.getBlocation(user.uid);
                blocationList ? setBlocations(blocationList) : setBlocations([])
                const siteList = await siteService.getSite(user.uid);
                siteList ? setSites(siteList) : setSites([]);
            } else {
                console.log('Not connected');
                navigate('/login');
            }
        });
    }

    const updateBlocationList = async () => {
        const blocationList = await blocationService.getBlocation(uid);
        const siteList = await siteService.getSite(uid);
        blocationList ? setBlocations(blocationList) : setBlocations([])
        siteList ? setSites(siteList) : setSites([]);
    }


    const selectToUpdate = (blocation) => {
        setId(blocation?.id);
        setBlocationName(blocation?.data.name);
        const locationSite = sites.filter(site => site.id == blocation?.data.siteID)[0];
        setSelectedSite({ value: locationSite, label: locationSite.data.name })
        setShowModal(true);
    }


    useEffect(() => {
        if (haveData == false) getBlocationList();
    })

    const createOrUpdateBlocation = (id, name, selectedSite) => {
        const newBlocation = { "createdBy": uid, 'name': name };
        if (selectedSite) newBlocation['siteID'] = selectedSite.value.id;
        if (id == null) {
            blocationService.create(newBlocation).then((res) => {
                updateBlocationList();
            }).catch((error) => {
                console.log(error.message);
            });
        } else {
            blocationService.update(id, newBlocation).then((res) => {
                updateBlocationList();
            }).catch((error) => {
                console.log(error.message);
            });
        }
    }

    const getSiteList = (siteList) => {
        var siteOption = []
        siteList?.map((site) => {
            siteOption.push({
                value: site,
                label: site.data.name
            })
        })
        return siteOption;
    }


    const confDeleteBlocation = (blocation) => {
        setId(blocation?.id);
        setConfirmDelete(true);
    }


    const deleteBlocation = (id) => {
        blocationService.delete(id).then(async () => {
            await updateBlocationList();
        })
        setConfirmDelete(false);
    }

    const displayBlocationList = () => {
        return blocations?.map((blocation) => {
            const site = sites?.filter(site => site.id == blocation.data.siteID)[0]
            return (
                <Col lg="3" sm="6" key={blocation.id}>
                    <Card className="card-stats">
                        <Card.Body>
                            <Row>
                                <Col xs="10" onClick={() => selectToUpdate(blocation)}>
                                    <Card.Title as="h5" className="pointer fw-400">{blocation.data.name}</Card.Title>
                                </Col>
                                <Col className="pointer" xs="1" onClick={() => confDeleteBlocation(blocation)}>
                                    <BsFillTrashFill color="#c9b2b2" />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <hr></hr>
                            <div className="stats">
                                <BsFillHouseFill color="#f8a86a" /> {site?.data.name}
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>
            )
        })
    }


    return (
        <>
            <Helmet>
                <title>Tyndee - Emplacement</title>
            </Helmet>
            <Container fluid>
                <Modal
                    className="modal-primary"
                    show={showModal}
                    onHide={() => setShowModal(false)}>
                    <Modal.Header className="justify-content-center">
                        {id ? (<h4>Modifier l'emplacement</h4>) : (<h4>Créer une nouvelle emplacement</h4>)}
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mbottom-15">
                            <Col className="pr-1" md="12">
                                <Form.Group>
                                    <Form.Control
                                        defaultValue={blocationName}
                                        placeholder="Nom de l'emplacement"
                                        type="text"
                                        onChange={event => { setBlocationName(event.target.value) }}>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mbottom-15">
                            <Col className="pr-1" md="12">
                                <Select
                                    defaultValue={selectedSite}
                                    onChange={setSelectedSite}
                                    options={getSiteList(sites)}
                                    placeholder="Site"
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <div className="modal-footer">
                        <Button
                            className="btn-simple"
                            type="button"
                            variant="link"
                            onClick={() => setShowModal(false)}
                        >
                            Retour
                        </Button>
                        <Button
                            className="btn-simple"
                            type="button"
                            variant="link"
                            onClick={() => {
                                if (!id) {
                                    createOrUpdateBlocation(null, blocationName, selectedSite);
                                } else {
                                    createOrUpdateBlocation(id, blocationName, selectedSite);
                                }
                                setShowModal(false);
                            }}
                        >
                            Enregistrer
                        </Button>
                    </div>
                </Modal>

                <ConfirmDelete show={confirmDelete} setShow={setConfirmDelete} name="cette emplacement" deleteElement={deleteBlocation} elementId={id} />
                <Button className="action-button-save" variant="info" onClick={() => navigate(-1)}> <AiFillLeftCircle /> Retour</Button>
                <Row>
                    <Col md="12">
                        <Card className="card-plain table-plain-bg">
                            <Card.Header>
                                <Button className="action-button-add" onClick={() => { setOpenToAddEmp(null); setBlocationName(null); setShowModal(true); }}
                                >Nouvelle emplacement <AiFillPlusCircle /></Button>
                                <Card.Title as="h3">Liste des emplacements
                                </Card.Title>
                            </Card.Header>
                            <Row>
                                {displayBlocationList()}
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Blocation;
