import planningService from "./planning.service";

import moment from 'moment';
import "moment/locale/fr";



class PlanningFunctionService {

    getPlanningDay(plannings, day) {
        return plannings?.filter(planning => moment(planning.data.planningDate.toDate()).format('LL') == moment(day).format('LL'));
    }

    getPlanningEmployee(planningList, timetableName, day) {
        return planningList?.filter(planning => moment(planning.data.planningDate.toDate()).format('LL') == moment(day).format('LL') && planning.data.name == timetableName);
    }

    getPlanningDate(planningList, employeeID, day) {
        return planningList?.filter(planning => moment(planning.data.planningDate.toDate()).format('LL') == moment(day).format('LL') && planning.data.employeeID == employeeID)[0];
    }

    getEmployeeNotWork(employees, planningList, day) {
        var employeeNotWork = [];
        employees?.map(employee => {
            if (!this.getPlanningDate(planningList, employee.id, day)) employeeNotWork.push(employee);
        })
        return employeeNotWork;
    }

    getEmployeeWork(employees, planningList, day) {
        var employeeWork = [];
        employees?.map(employee => {
            if (this.getPlanningDate(planningList, employee.id, day)) employeeWork.push(employee);
        })
        return employeeWork;
    }

    EmployeeListOption(employees) {
        var employeeOptions = [];
        employees?.map((employee) => {
            employeeOptions.push({
                value: employee,
                label: employee.data.name + ' - ' + employee.data.job ?? ' '
            })
        })
        return employeeOptions
    }

    getPlanningEmployeeNotWork(plannings, employees, employeeWork, day) {
        var planningEmployeeNotWork = [];
        employees.map(employee => {
            if (employeeWork?.filter(employeeW => employeeW.id == employee.id)?.length == 0) {
                const planningDay = this.getPlanningDate(plannings, employee.id, day)
                if (planningDay) planningEmployeeNotWork.push(planningDay);
            }
        })
        return planningEmployeeNotWork;
    }

    EmployeeOption(plannings, employees, day) {
        var employeeOptions = [{ value: null, label: 'ANNULER' }];
        const planningEmployeeList = this.getPlanningDay(plannings, day);
        employees?.map((employee) => {
            if (planningEmployeeList?.filter(planning => planning.data.employeeID == employee.id).length == 0) {
                employeeOptions.push({
                    value: employee,
                    label: employee.data.name + ' - ' + employee.data.job
                })
            }
        })
        return employeeOptions;
    }

    getRandomAvaibleEmployee(employeeUnavaibles, employees) {
        if (employeeUnavaibles == []) return planningService.shuffle(employees);
        var randomAvaibleEmployee = [];
        employees.map((employee) => {
            if (employeeUnavaibles?.filter(employeeUnavaible => employeeUnavaible.value.id == employee.id).length == 0) {
                randomAvaibleEmployee.push(employee);
            }
        })
        return planningService.shuffle(randomAvaibleEmployee);
    }

    choiceGoToPauseFromLastWeek(avaibleEmployee, readyGoToPause, planningLastWeek, lastweek, numberWillGoToPause) {
        var employeeGotoPause = [];
        var index = 0;
        for (let i = 0; i < 7; i++) {
            var n = i > 3 ? i - 4 : 3 + i;
            var employeeInPauses = this.getEmployeeNotWork(avaibleEmployee, planningLastWeek, lastweek[n]);
            employeeInPauses.sort((a, b) => a.data.name.localeCompare(b.data.name));

            employeeInPauses.map(employeeInPause => {
                if (index < numberWillGoToPause && readyGoToPause?.filter(readyHavePause => readyHavePause.id == employeeInPause.id).length == 0) {
                    if (employeeGotoPause?.filter(goToPause => goToPause.id == employeeInPause.id).length == 0) {
                        employeeGotoPause.push(employeeInPause);
                        index++
                    }
                }
            })
        }
        return employeeGotoPause;
    }

    choiceEmployeeWorkNight(avaibleEmployees, employeesWork, employeesAvaibleWorkNight) {
        var employeeWorkNight = [];
        avaibleEmployees.map(avaibleEmployee => {
            const workNight = employeesAvaibleWorkNight.filter(emp => avaibleEmployee.id == emp.value.id)?.length > 0
            const notWork = employeesWork.filter(emp => avaibleEmployee.id == emp.id)?.length == 0;
            if (workNight && notWork) {
                employeeWorkNight.push(avaibleEmployee);
            }
        })
        return employeeWorkNight;
    }

    choiceEmployeeWork(pauseTime, avaibleEmployee, dayIndex, planningLastWeek, lastweek) {
        if (pauseTime == 1) {
            if (dayIndex > 4) return { employeeWork: [], readyGoToPause: avaibleEmployee };
            return { employeeWork: avaibleEmployee, readyGoToPause: [] }
        }
        if (pauseTime == 2) {
            if (dayIndex > 5) return { employeeWork: [], readyGoToPause: avaibleEmployee };
            return { employeeWork: avaibleEmployee, readyGoToPause: [] }
        }
        if (pauseTime == 3) {
            var goToPause = [];
            const readyHavePauses = dayIndex == 0 ? [] : [...this.choiceEmployeeWork(pauseTime, avaibleEmployee, dayIndex - 1, planningLastWeek, lastweek)?.readyGoToPause];
            var employeeWork = [...readyHavePauses];
            const numberWillGoToPause = parseInt((avaibleEmployee.length - readyHavePauses.length) / (7 - dayIndex));
            var i = 0;

            goToPause = this.choiceGoToPauseFromLastWeek(avaibleEmployee, readyHavePauses, planningLastWeek, lastweek, numberWillGoToPause) ?? [];
            i = goToPause.length;

            avaibleEmployee.map((employee) => {
                if (readyHavePauses?.filter(readyHavePause => readyHavePause.id == employee.id).length == 0) {

                    if (goToPause?.filter(employeeGoToPause => employeeGoToPause.id == employee.id).length == 0) {
                        if (i < numberWillGoToPause) {
                            goToPause.push(employee);
                            i++;
                        } else {
                            employeeWork.push(employee);
                        }
                    }
                }
            })

            return { employeeWork: employeeWork, readyGoToPause: goToPause.concat(readyHavePauses) };
        }
    }

    checkEmployeeLeave(id, leaves, day) {
        const leave = leaves?.filter(leave => leave.data.author == id &&
            new Date(leave.data.startDate.toDate()).setHours(0, 0, 0, 0) <= day &&
            new Date(leave.data.endDate.toDate()).setHours(24, 0, 0, 0) >= day)
        if (leave?.length > 0) {
            return true;
        }
        return false;
    }

    getEmployeeWorkAnyTime(employeeWorks, employeeFixTimetable1, employeeFixTimetable2, employeeFixTimetable3) {
        var employeeWorksAnyTime = [];
        var employeeWorkFixTime = employeeFixTimetable1 ?? [];
        employeeWorkFixTime = employeeWorkFixTime.concat(employeeFixTimetable2 ?? []);
        employeeWorkFixTime = employeeWorkFixTime.concat(employeeFixTimetable3 ?? []);

        if (employeeWorkFixTime.length == 0) return employeeWorks

        employeeWorks.map(employee => {
            if (employeeWorkFixTime?.filter(employeeWorkFix => employeeWorkFix.value.id == employee.id).length == 0) {
                employeeWorksAnyTime.push(employee);
            }
        })

        return employeeWorksAnyTime;
    }
}

export default new PlanningFunctionService();
