import React, { useEffect, useState } from "react";
import EmployeeCSV from "../components/config/employeeCSV";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import EmployeeService from "../components/service/employee.service";
import TimetableService from "../components/service/timetable.service";
import { useNavigate } from "react-router-dom";
import { firebase_app } from "../components/config/firebase";
import NotificationAlert from "react-notification-alert";
import { notify } from "../components/service/notification.service";
import configService from "../components/service/config.service";

import { FcSearch } from "react-icons/fc";
import { AiFillPlusCircle, AiFillShop } from "react-icons/ai";
import { BsFillPeopleFill } from "react-icons/bs";
import { MdMap } from "react-icons/md";
import siteService from "../components/service/site.service";
import { Helmet } from "react-helmet";


function EmployeeList() {
  const [employees, setEmployees] = useState(null);
  const [haveData, setHaveData] = useState(false);
  const [sites, setSites] = useState(null);
  const [resultSearch, setResultSearch] = useState(null);

  const navigate = useNavigate();

  const getEmployees = async () => {
    setHaveData(true);
    firebase_app.auth().onAuthStateChanged(async function (user) {
      if (user) {
        const uid = user.uid;
        await configService.checkMonthIsActive(navigate, uid);
        const employeeList = await EmployeeService.getEmployee(uid);
        employeeList ? setEmployees(EmployeeService.sortEmployeeLevel(employeeList)) : setEmployees([]);
        employeeList ? setResultSearch(employeeList) : setResultSearch([]);
        const siteList = await siteService.getSite(user.uid);
        siteList ? setSites(siteList) : setSites([]);
      } else {
        console.log('Not connected');
        navigate('/login');
      }
    });

  }

  useEffect(() => {
    if (haveData == false) getEmployees();
  })

  const goToDetail = (id) => {
    navigate('/admin/employees/employee/' + id);
  }

  const displayEmployeeList = () => {
    return resultSearch?.map((employee) => {
      const data = employee.data;
      const site = sites?.filter(site => site.id == data?.siteID)[0];
      return (
        <tr onClick={() => goToDetail(employee.id)} key={employee.id}>
          <td className="pointer color-tblue" style={{ color: '#1f3e76' }}> {data['name']} </td>
          <td>{data['job']}</td>
          <td>{data['matricule']}</td>
          <td>{site?.data['name']}</td>
          <td>{data['num']}</td>
        </tr>
      )
    })
  }

  const goToCreation = () => {
    navigate('/admin/employees/new-employee');
  }

  const searchByEmployeeName = (text) => {
    var result = [];
    employees.map(employee => {
      if (employee?.data.name.toLowerCase().includes(text.toLowerCase()))
        result.push(employee);
    })
    setResultSearch(result);
  }

  return (
    <>
      <Helmet>
        <title>Tyndee - Liste des employés</title>
      </Helmet>
      <div className="rna-container">
      </div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h3">Employés</Card.Title>
                <hr></hr>
                <Button className='action-button-add' onClick={() => navigate('/admin/employees/teams')}> <BsFillPeopleFill /> Equipes </Button>
                <Button className='action-button-add' onClick={() => navigate('/admin/employees/sites')}> <MdMap /> Sites </Button>
                <Button className='action-button-add' onClick={goToCreation}> Nouvel employé  <AiFillPlusCircle /></Button>

                <Col md={6} style={{ display: 'flex' }}>
                  <Form.Control placeholder="Rechercher" type="text" onChange={event => searchByEmployeeName(event.target.value)} ></Form.Control>
                  <Col style={{ marginRight: '3px' }}>
                    <button type="button" className="btn" style={{ border: 0 }}>
                      <FcSearch />
                    </button>
                  </Col>

                </Col>
                <Card.Title as="h4"> {resultSearch?.length} employés </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0 table-list element-list-display">
                <Table className="table-hover table-responsive">
                  <thead>
                    <tr>
                      <th className="border-0">Nom complet</th>
                      <th className="border-0">Poste</th>
                      <th className="border-0">Matricule</th>
                      <th className="border-0">Site</th>
                      <th className="border-0">Téléphone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayEmployeeList()}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EmployeeList;