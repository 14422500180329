import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { useCSVReader } from 'react-papaparse';
import employeeService from '../service/employee.service';
import { firebase_app } from './firebase';
import { notify } from '../service/notification.service';

const buttonRef = React.createRef();
const auth = firebase_app.auth()

export default class EmployeeCSV extends Component {
  
  constructor(props) {
    super(props);
    this.state = { data: null };
  }


  handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    console.log(data);
  };

  handleOnFileLoad = (data) => {
    if (data[0]?.data?.includes('Nom') && data[0]?.data?.includes('Téléphone')) {
      this.setState({ data: data });
    }
    else {
      notify('Fichier incorrect ! Assurer de mettre le \'Nom\' et le \'Téléphone\' de chaque employé', "tr", 3);
      this.handleRemoveFile();
    }
  };


  saveData = () => {
    const uid = auth.currentUser?.uid;
    const nameIndex = this.state.data[0].data.indexOf('Nom');
    const numIndex = this.state.data[0].data.indexOf('Téléphone');
    const matriculeIndex = this.state.data[0].data.indexOf('Matricule');
    if (uid) {
      for (let index = 1; index < this.state.data.length; index++) {
        const element = this.state.data[index];
        employeeService.createEmployee(
          element.data[nameIndex],
          element.data[numIndex],
          element.data[matriculeIndex],
          uid,
          index,
          this.state.data.length,
        )
      }

    }
  }



  handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  render() {
    const employees = this.props.employeeList
    const { CSVReader } = useCSVReader();
    return (
      <>
        <CSVReader
          ref={buttonRef}
          onFileLoad={this.handleOnFileLoad}
          onError={this.handleOnError}
          noClick
          noDrag
          onRemoveFile={this.handleOnRemoveFile}
        >
          {({ file }) => (
            <aside
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 10,
              }}
            >
              {!file && (<Button
                type="button"
                onClick={this.handleOpenDialog}
                style={{
                  padding: 5,
                  margin: 2
                }}
                className="action-button-cancel">
                Importer via CSV
              </Button>)}

              {file && (<Button
                type="button"
                onClick={this.saveData}
                style={{
                  padding: 5,
                  margin: 2
                }}
              >Enregistrer les données
              </Button>)}
              <div
                style={{
                  margin: 5,
                }}
              >
                {file && file.name}
                {file && (
                  <i style={{
                    display: 'inline-block',
                    color: 'red'
                  }}
                    onClick={this.handleRemoveFile} className="nc-icon nc-simple-remove"></i>)}
              </div>
            </aside>
          )}
        </CSVReader>
      </>
    );
  }
}