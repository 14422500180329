import { firebase_app } from "../../components/config/firebase";


const db = firebase_app.firestore();


class TimetableService {

  async getTimetable(uid) {
    if (!uid) return null;
    const snapshot = await db.collection("timetable").where("createdBy", "==", uid).get()
    return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
  }

  async getDayTimetable(uid) {
    if (!uid) return null;
    const snapshot = await this.getTimetable(uid);
    let results = []
    for (let value of snapshot) {
      if (value?.data.isNight && value?.data.isNight == true) {
        continue;
      }
      else {
        results.push(value);
      }
    }
    return results;
  }

  async getNightTimetable(uid) {
    if (!uid) return null;
    const snapshot = await this.getTimetable(uid);
    let results = []
    for (let value of snapshot) {
      if (value?.data.isNight && value?.data.isNight == true) {
        results.push(value);
      }
    }
    return results;
  }

  create(timetable) {
    return db.collection("timetable").add(timetable);
  }

  update(id, value) {
    return db.collection("timetable").doc(id).update(value);
  }

  delete(id) {
    return db.collection("timetable").doc(id).delete();
  }

  getHoursNumber(time) {
    return Number(time.split(':')[0]);
  }

  sortTimetables(timetables) {
    return timetables?.sort(function (a, b) {
      return Number(a.data.startTime.split(':')[0]) - Number(b.data.startTime.split(':')[0]);
    });
  }


}

export default new TimetableService();