import { firebase_app } from "../../components/config/firebase";
import configService from "./config.service";
import { notify } from "./notification.service";
import moment from 'moment';


const db = firebase_app.firestore();

class EmployeeService {

  async getEmployee(uid) {
    if (!uid) return null;
    const snapshot = await db.collection("employee").where('createdBy', '==', uid).get()
    return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
  }

  async getEmployeeByID(id) {
    const snapshot = await db.collection("employee").doc(id).get();
    return snapshot.data();
  }

  async getEmployeeByNum(num) {
    const snapshot = await db.collection("employee").where('num', '==', num).get()
    return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
  }

  async getEmployeeBySite(sites) {
    const snapshot = await db.collection("employee").where('siteID', 'in', sites).get()
    return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
  }

  create(employee) {
    return db.collection('employee').add(employee)
  }

  update(id, value) {
    return db.collection('employee').doc(id).update(value);
  }

  delete(id) {
    this.deletePointing(id);
    this.deletePlanning(id);
    return db.collection('employee').doc(id).delete();
  }

  deletePointing(id) {
    db.collection('pointing').where('employeeID', '==', id).get()
      .then(async function (querySnapshot) {
        var batch = db.batch();
        querySnapshot.forEach(function (doc) {
          batch.delete(doc.ref);
        });
        await batch.commit();
      })
  }

  deletePlanning(id) {
    db.collection('planning').where('employeeID', '==', id).get()
      .then(async function (querySnapshot) {
        var batch = db.batch();
        querySnapshot.forEach(function (doc) {
          batch.delete(doc.ref);
        });
        await batch.commit();
      })
  }

  async checkCanCreate(uid, num) {

    const userData = await configService.getUserByID(uid);
    const firstDay = moment().startOf('month').format('DD-MM-YYYY');
    var createdDate = userData?.created_date;

    const numberUsed = await this.getEmployeeByNum(num);
    if (numberUsed[0]?.id) {
      notify('Ce numéro de téléphone est déja utiliser', "tr", 3);
      return false;
    }

    if (moment(createdDate?.toDate()).startOf('month').format('DD-MM-YYYY') != firstDay && new Date().getDate() > 5) {
      const employeeList = await this.getEmployee(uid);
      const activatedMonth = await configService.getActivatedMonth(uid);
      const numberEmployee = employeeList ? employeeList.length : 0;
      const numberEmployeeActivated = Number(activatedMonth[0] ? activatedMonth[0].employee_number : 0);

      if (numberEmployeeActivated <= numberEmployee) {
        notify('Vous avez atteint la limite du nombre d\'employés que vous pouvez créé durant ce mois', "tr", 3)
        return false;
      }
    }

    else true;
  }

  createEmployee = async (name, num, matricule = '', uid, index, size) => {
    const canCreate = await this.checkCanCreate(uid, num);
    if (canCreate == false) return
    this.create({
      "name": name,
      "num": num,
      "createdBy": uid,
      "createDate": new Date,
      "matricule": matricule
    }).then((res) => {
      if (index + 1 == size) {
        notify('Importation réussit avec succés', "tc", 2)
      }
    }).catch((error) => {
      console.log(error.message);
    })
  }


  sortEmployeeLevel(employees) {
    return employees.sort(function (a, b) {
      return Number(b.data.level ?? 0) - Number(a.data.level ?? 0);
    });
  }

  getEmployeePlanning(employees) {
    return employees.filter(employee => employee.data.notInPlanning != true);
  }

}

export default new EmployeeService();


export const truncateName = (name) => {
  const nameArray = name.toLowerCase().split(' ');
  if (nameArray.length > 2)
    return nameArray[0] + ' ' + nameArray[1].substring(0, 1) + '. ' + nameArray[nameArray.length - 1].toLowerCase()+' '
  return name.toLowerCase()+' ';
}

export const truncateJob = (job) => {
  const jobArray = job.toLowerCase().split(' ');
  if (jobArray.length > 1)
    return jobArray[0] +' ' + jobArray[jobArray.length - 1].substring(0, 1) + '.'
  return job.toLowerCase();
}