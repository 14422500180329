const BASE_CALENDAR_URL = "https://www.googleapis.com/calendar/v3/calendars";
const BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY = "holiday@group.v.calendar.google.com";
const API_KEY = "AIzaSyB52umWHHOfIMimY2Iy6jzi354_USD5Yck";
const CALENDAR_REGION = "sn.sn"; //




export const getPublicHolidays = (setHolidays) => {
    const url = `${BASE_CALENDAR_URL}/${CALENDAR_REGION}%23${BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY}/events?key=${API_KEY}`

    fetch(url).then(response => response.json()).then(data => {
        const holidays = data.items;
        setHolidays(holidays);
    })
}


export const getPublicHolidaysByWeek = (holidays, week, setHolidaysByWeek) => {
    const weekHolidays = holidays.filter(holiday => new Date(holiday.end.date) >= new Date(week[0]) && new Date(holiday.start.date) <= new Date(week[6]));
    setHolidaysByWeek(weekHolidays);
}

export const getPublicHolidaysByDay = (holidays, day) => {
    const holiday = holidays.filter(holiday => new Date(holiday.end.date) > new Date(day) && new Date(holiday.start.date) <= new Date(day));
    return holiday[0]?.summary?? '';
}

