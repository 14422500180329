import { firebase_app } from "../config/firebase";

const db = firebase_app.firestore();

class PlanningParameters {

  async getPlanningParameters(uid) {
    if (!uid) return null;
    const snapshot = await db.collection("planning-parameters").where('createdBy', '==', uid).get()
    return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
  }

  async getPlanningParametersByID(id) {
    const snapshot = await db.collection("planning-parameters").doc(id).get()
    return snapshot.data();
  }

  create(planningParameters) {
    return db.collection('planning-parameters').add(planningParameters)
  }

  update(id, value) {
    return db.collection('planning-parameters').doc(id).update(value);
  }

  delete(id) {
    return db.collection('planning-parameters').doc(id).delete();
  }

  save(uid, data) {
    return db.collection("planning-parameters").doc(uid).set(data);
  }

  savePlanningParameter(uid, criteriaJob, pauseTime, daysPlusWorkMorning, plusBreakEndOfWeek, unavailableEmployee, employeeFixTimetable1, employeeFixTimetable2) {
    var data = {
      "criteriaJob": criteriaJob,
      "pauseTime": pauseTime,
      "daysPlusWorkMorning": daysPlusWorkMorning,
      "plusBreakEndOfWeek": plusBreakEndOfWeek,
      "unavailableEmployee": unavailableEmployee,
      "employeeFixTimetable1": employeeFixTimetable1,
      "employeeFixTimetable2": employeeFixTimetable2
    }
    return this.save(uid, data);
  }

}

export default new PlanningParameters();