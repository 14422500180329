import React, { useEffect, useState } from 'react';
import '../assets/scss/login.scss';
import {firebase_app,  signInWithFacebook, signInWithGoogle } from "../components/config/firebase";
import { FaFacebook } from "react-icons/fa";
import ReactLoading from 'react-loading';
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { signInF, signInG } from "./Login"
import { Helmet } from 'react-helmet';

const Register = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const firebaseInstance = firebase_app;

    const signUp = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            if (firebaseInstance) {
                const user = await firebaseInstance
                    .auth()
                    .createUserWithEmailAndPassword(email, password);
                navigate('/admin/dashboard')
                setLoading(false);
            }
        } catch (error) {
            console.log("error", error);
            alert(error.message);
            setLoading(false);
        }
    };



    return (
        <div className="container">
            <Helmet>
                <title>Tyndee - S'inscrire</title>
            </Helmet>
            <div className="card-login">
                <div className="card-login-image">
                    <h2 className="card-login-heading">
                        Tyndee
                        <small>Créer votre compte</small>
                    </h2>
                </div>
                <form className="card-login-form" onSubmit={signUp}>
                    <div className="input">
                        <input type="text" autoComplete="name"className="input-field" onChange={event => { setName(event.target.value) }} required />
                        <label className="input-label">Full name</label>
                    </div>
                    <div className="input">
                        <input type="text" autoComplete="email" className="input-field" onChange={event => { setEmail(event.target.value) }} required />
                        <label className="input-label">Email</label>
                    </div>
                    <div className="input">
                        <input type="password" className="input-field" onChange={event => { setPassword(event.target.value) }} required />
                        <label className="input-label">Password</label>
                    </div>
                    {loading &&
                        (<div className="spinner">
                            <ReactLoading color={'#1F3E76'} type={'bubbles'} height={'20%'} width={'40%'} />
                    </div>)}
                    <div className="action">
                        <button className="action-button">S'inscrire</button>
                    </div>
                    <div className="action">
                        <button className="google-button" onClick={() => { signInG(navigate);}}>
                            <FcGoogle className="facebook-icon" />
                            <span>S'incrire avec Gmail</span>
                        </button>
                    </div>
                    <div className="action">
                        <button className="google-button" onClick={() => { signInF(navigate);}}>
                            <FaFacebook className="facebook-icon" />
                            <span>S'incrire avec Facebook</span>
                        </button>
                    </div>
                    {error && (<div className="card-login-error">
                        <p>Email ou mot de passe incorrect.</p>
                    </div>)}

                    <div className="card-login-regorlog">
                        <p>Vous avez deja un compte ? <a href="login">Connecter Vous.</a></p>
                    </div>
                </form>
                <div className="card-login-info">
                    <p>By signing up you are agreeing to our <a href="#">Terms and Conditions</a></p>
                </div>
            </div>
        </div>
    );
};

export default Register;
