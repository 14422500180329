import { firebase_app } from "../config/firebase";

const db = firebase_app.firestore();

class ContractService {

  async getContract(uid) {
    if (!uid) return null;
    const snapshot = await db.collection('contract').where('createdBy', '==', uid).get()
    return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
  }

  async getContractByID(id) {
    const snapshot = await db.collection('contract').doc(id).get()
    return snapshot.data();
  }

  async getContractByEmployeeID(id) {
    const snapshot = await db.collection('contract').where('employeeID', '==', id).get()
    return snapshot.docs?.map(doc => { return { 'id': doc.id, 'data': doc.data() } });
  }

  create(contract) {
    return db.collection('contract').add(contract)
  }

  update(id, value) {
    return db.collection('contract').doc(id).update(value);
  }

  delete(id) {
    return db.collection('contract').doc(id).delete();
  }

}

export default new ContractService();