import React, { useEffect, useState } from "react";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Modal,
  Form,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import TimetableService from "../components/service/timetable.service";
import { useNavigate } from "react-router-dom";
import { firebase_app } from "../components/config/firebase";
import Select from 'react-select'
import teamService from "../components/service/team.service";
import employeeService from "../components/service/employee.service";
import configService from "../components/service/config.service";
import { AiFillPlusCircle, AiFillLeftCircle, AiOutlineClose } from "react-icons/ai";
import { BsFillTrashFill, BsFillPeopleFill } from "react-icons/bs";
import { Helmet } from "react-helmet";

function Team() {

  const [teams, setTeams] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [name, setName] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [id, setId] = useState(null);
  const [uid, setUid] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [openTeam, setOpenTeam] = useState(null);

  const navigate = useNavigate();
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [teamName, setTeamName] = useState(null);
  const [openToAddEmp, setOpenToAddEmp] = useState(null);
  const [haveData, setHaveData] = useState(false);



  const getTeamList = async () => {
    setHaveData(true);
    firebase_app.auth().onAuthStateChanged(async function (user) {

      if (user) {
        setUid(user.uid);
        await configService.checkMonthIsActive(navigate, user.uid);
        const teamList = await teamService.getTeam(user.uid);
        const employeeList = await employeeService.getEmployee(user.uid);
        teamList ? setTeams(teamList) : setTeams([])
        employeeList ? setEmployees(employeeList) : setEmployees([]);

      } else {
        console.log('Not connected');
        navigate('/login');
      }
    });
  }

  const updateTeamList = async () => {
    const teamList = await teamService.getTeam(uid);
    const employeeList = await employeeService.getEmployee(uid);
    teamList ? setTeams(teamList) : setTeams([])
    employeeList ? setEmployees(employeeList) : setEmployees([]);
  }


  const selectToUpdate = (idSelected) => {
    if (id == idSelected) {
      setId(null);
      setName(null);
      setStartTime(null);
      setEndTime(null);
    } else {
      const selectedTimetable = timetables?.filter(timetable => timetable.id == idSelected)[0];
      setId(idSelected);
      setName(selectedTimetable.data.name);
      setStartTime(selectedTimetable.data.startTime);
      setEndTime(selectedTimetable.data.endTime);
    }
  }


  useEffect(() => {
    if (haveData == false) getTeamList();
  })

  const createOrUpdateTeamName = (id, name) => {
    const newTeam = { "createdBy": uid, 'name': name };
    if (id == null) {
      teamService.create(newTeam).then((res) => {
        updateTeamList();
      }).catch((error) => {
        console.log(error.message);
      });
    } else {
      teamService.update(id, newTeam).then((res) => {
        updateTeamList();
      }).catch((error) => {
        console.log(error.message);
      });
    }
  }

  const addEmployee = (teamID, employeeSelected) => {
    var i = 0;
    employeeSelected.map((employee) => {
      i++
      var data = employee.value.data;
      data['teamID'] = teamID;
      employeeService.update(employee.value.id, data).then((res) => {
        if (i == employeeSelected.length) {
          setSelectedEmployees(null);
          updateTeamList();
        }
      }).catch((error) => {
        console.log(error.message);
      });
    })
  }


  const removeEmployee = (employee) => {
    var data = employee.data;
    data.teamID = null;
    employeeService.update(employee.id, data).then((res) => {
      updateTeamList();
    }).catch((error) => {
      console.log(error.message);
    });
  }


  const getEmployeesAvaible = (employeeList) => {
    var employeeAvaibles = []
    employeeList?.filter(employee => !employee.data.teamID).map((employeeAvaible) => {
      employeeAvaibles.push({
        value: employeeAvaible,
        label: employeeAvaible.data.name
      })
    })
    return employeeAvaibles;
  }

  const displayTeamList = () => {
    return teams?.map((team) => {
      const employeeNumber = employees?.filter(employee => employee.data.teamID == team.id).length;
      return (
        <Col lg="3" sm="6" key={team.id}>
          <Card className="card-stats">
            <Card.Body>
              <Row onClick={() => setOpenTeam(team)}>
                <Col xs="12">
                  <Card.Title as="h5" className="pointer fw-400">{team.data.name}</Card.Title>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <hr></hr>
              <div className="stats">
                <BsFillPeopleFill color="#f8a86a" /> {employeeNumber} employés
              </div>
            </Card.Footer>
          </Card>
        </Col>
      )
    })
  }

  const displayTeamDetail = (team) => {
    const data = team.data;
    return (
      <Col md="12">
        <Card.Body className="element-list-display" key={team.id}>
          <Button className="site-list-button"
            onClick={() => { setOpenTeam(null) }}>
            <AiOutlineClose color="red" />
          </Button>
          <Button className="action-button-add"
            onClick={() => {
              setOpenTeam(team);
              setOpenToAddEmp(true);
              setShowModal(true);
            }}> Ajouter  <AiFillPlusCircle />
          </Button>
          <Card.Title as="h4" className="pointer fw-400" onClick={() => {
            setOpenTeam(team);
            setTeamName(data.name);
            setOpenToAddEmp(null);
            setShowModal(true);
          }}> {data.name}  </Card.Title>
          <Table className="table-hover">
            <thead>
              <tr>
                <th className="border-0">Nom</th>
                <th className="border-0">Téléphone</th>
              </tr>
            </thead>
            <tbody>
              {displayEmployeeList(team.id)}
            </tbody>
          </Table>
        </Card.Body>
      </Col>
    )
  }

  const displayEmployeeList = (id) => {
    const employeeList = employees?.filter(employee => employee.data.teamID == id);
    return employeeList?.map((employee) => {
      const data = employee.data;
      return (
        <tr key={employee.id}>
          <td>{data.name}</td>
          <td>{data.num}</td>
          <td onClick={() => removeEmployee(employee)}> <BsFillTrashFill className="pointer" color="red" /></td>
        </tr>
      )
    })
  }


  return (
    <>
      <Helmet>
        <title>Tyndee - Équipe</title>
      </Helmet>
      <Container fluid>
        <Modal
          className="modal-primary"
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header className="justify-content-center">
            {!openTeam && (<h4>Créer une nouvelle équipe</h4>)}
            {!openToAddEmp && openTeam && (<h4>Modifier l'équipe</h4>)}
            {openToAddEmp && openTeam && (<h4>Ajouter des employés dans {openTeam.data.name} </h4>)}
          </Modal.Header>
          <Modal.Body className="text-center">
            <Row>
              <Col className="pr-1" md="11">
                {!openToAddEmp && (<Form.Group>
                  <Form.Control
                    defaultValue={teamName}
                    placeholder="Nom de l'équipe"
                    type="text"
                    onChange={event => { setTeamName(event.target.value) }}>
                  </Form.Control>
                </Form.Group>)}
                {openToAddEmp && (<Form.Group>
                  <Select
                    defaultValue={selectedEmployees}
                    onChange={setSelectedEmployees}
                    options={getEmployeesAvaible(employees)}
                    isMulti={true}
                  />
                </Form.Group>)}
              </Col>
            </Row>
          </Modal.Body>
          <div className="modal-footer">
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => setShowModal(false)}
            >
              Retour
            </Button>
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => {
                if (!openTeam) {
                  createOrUpdateTeamName(null, teamName);
                } else if (!openToAddEmp) {
                  createOrUpdateTeamName(openTeam.id, teamName);
                }
                else if (openToAddEmp && openTeam) {
                  addEmployee(openTeam.id, selectedEmployees)
                  console.log(selectedEmployees);
                }
                setShowModal(false);
              }}
            >
              Confirmer
            </Button>
          </div>
        </Modal>
        <Button className="action-button-save" variant="info" onClick={() => navigate(-1)}> <AiFillLeftCircle /> Retour</Button>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Button className="action-button-add" onClick={() => { setOpenToAddEmp(null); setTeamName(null); setShowModal(true); }}
                >Nouvelle équipe <AiFillPlusCircle /></Button>
                <Card.Title as="h3">Liste des équipes
                </Card.Title>
              </Card.Header>
              <Row>
                {openTeam ? displayTeamDetail(openTeam) : displayTeamList()}
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Team;
